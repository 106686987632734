import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notifications } from "../../_redux/actions";
import {
  Table,
  Input,
  DatePicker,
  Popconfirm,
  Form,
  Typography,
  Button,
  Divider,
  Row,
  Col,
  Tag,
  Modal,
  Radio,
  Select,
} from "antd";
//import moment from "moment";
import moment from "moment-timezone";
import { periodService, planningService } from "../../_helpers/services/";
import { NOTIFICATION, TIMEZONE } from "../../_helpers/constants";

const { Option } = Select;

const data = [];
const EditableContext = React.createContext();

const disabledDate = (year) => {
  let current = moment().tz(TIMEZONE);
  return current && current < moment().tz(TIMEZONE).startOf("day");
};

//exclude today
const disabledDate2 = (current = moment().tz(TIMEZONE)) => {
  return current && current < moment().tz(TIMEZONE).endOf("day");
};

const View = (props) => {
  const [periodList, setPeriodList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [planningList, setPlanningList] = useState([]);

  const { Title } = Typography;
  const EditableFormTable = Form.create()(EditableTable);

  const updateLoading = () => {
    setLoading(!loading);
  };

  async function savePeriod(values) {
    let message;
    let error = false;
    let res = await periodService.insert(values);

    if (res.status === 201) {
      message = {
        title: "Nuevo periodo  de evaluación creado",
        body: `El periodo  de evaluación ${values.name} ha sido agregado exitosamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      let msg_error = "";

      try {
        msg_error = JSON.parse(res.request.response);
        msg_error = msg_error.error || msg_error;
      } catch (error) {
        msg_error = res.message;
      }

      message = {
        title: "ERROR AL CREAR EL PERIODO",
        body: msg_error,
        type: NOTIFICATION.TYPES.ERROR,
      };
      error = true;
    }

    props.showNotification(message);

    if (!error) {
      //clear table data source
      data.splice(0, data.length);
      loadPeriods();
      updateLoading();
    }
    return error;
  }

  async function updateStatusPeriod(item) {
    let message;
    item.active = !item.status || false;

    let res = await periodService.update(item.id, item);

    if (res.status === 200) {
      message = {
        title: "Actualización exitosa ",
        body: `La periodo  de evaluación ${item.name} se ha ${
          item.status ? "Desactivado" : "Activado"
        } correctamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "ERROR",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    //clear table data source
    data.splice(0, data.length);
    loadPeriods();
    updateLoading();
  }

  async function updatePeriods(item, row) {
    let message;
    let data = item;
    data.name = row.name;
    data.start_date = moment(row.start_date).tz(TIMEZONE).format();
    data.end_date = moment(row.end_date).tz(TIMEZONE).format();
    //data.planning = row.planning;
    let res = await periodService.update(data.id, data);

    if (res.status === 200) {
      message = {
        title: `Actualización exitosa`,
        body: `La información de la periodo de evaluación ${data.name} se actualizó correctamente`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      let msg_error = "";

      try {
        msg_error = JSON.parse(res.request.response);
        msg_error = msg_error.error || msg_error;
      } catch (error) {
        msg_error = res.message;
      }

      message = {
        title: "ERROR AL ACTUALIZAR EL PERIODO",
        body: msg_error,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    loadPeriods();
    updateLoading();
  }

  async function loadPeriods() {
    let message;
    let res = await periodService.fetch();
    if (res.status === 200) {
      // Process reciveid data
      setPeriodList(res.data);

      data.splice(0, data.length);
      res.data.forEach((period, idx) => {
        data.push({
          key: idx + 1,
          id: period.id,
          name: period.name,
          start_date: period.start_date,
          end_date: period.end_date,
          status: period.active,
          planning: period.planning,
        });
      });

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} periodos de evaluación obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    setLoading(false);
  }

  async function loadPlannings() {
    let message;
    let res = await planningService.fetch();
    if (res.status === 200) {
      // Process reciveid data
      setPlanningList(res.data);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} planes obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  useEffect(() => {
    if (periodList.length === 0 && firstLoad && props.isAuthenticated) {
      loadPeriods();
      loadPlannings();
      setFirstLoad(false);
    }
  }, [periodList]);

  return (
    <>
      <Row type="flex" justify="center">
        <Col span={16}>
          <Title type="secondary">Listado de periodos de evaluación</Title>
        </Col>
        <Col span={8}>
          <PeriodCreateModal save={savePeriod} planningList={planningList} />
        </Col>
      </Row>
      <EditableFormTable
        loading={loading}
        update={updatePeriods}
        updateStatus={updateStatusPeriod}
        updateLoading={updateLoading}
        planningList={planningList}
      />
    </>
  );
};

// ************************ COMPONENT TO SHOW MODAL WITH FORM ************************************
class PeriodCreateModal extends React.Component {
  state = {
    visible: false,
    loading: false,
    selectedPlan: true,
    currentPlan: {},
    datePickerStatus: "validating",
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({
      ...this.state,
      visible: false,
      selectedPlan: true,
      datePickerStatus: "validating",
    });
  };

  handleChangePlan = (plan_id) => {
    let currentPlan = this.props.planningList.filter((x) => x.id === plan_id);
    this.setState({ selectedPlan: false, currentPlan: currentPlan[0] });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields(async (err, values) => {
      if (values.start_date.isSameOrAfter(values.end_date)) {
        this.setState({ ...this.state, datePickerStatus: "error" });
        return;
      } else {
        this.setState({ ...this.state, datePickerStatus: "success" });
      }
      if (err) {
        return;
      }

      this.setState({ ...this.state, loading: true });
      let error = await this.props.save(values);
      if (!error) {
        //saved successfully
        form.resetFields();
        this.setState({
          loading: false,
          visible: false,
          datePickerStatus: "validating",
        });
      } else {
        this.setState({
          ...this.state,
          loading: false,
          datePickerStatus: "validating",
        });
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div>
        <Button
          type="primary"
          shape="round"
          size="large"
          icon="plus-circle"
          style={{ float: "right" }}
          onClick={this.showModal}
        >
          Agregar Periodo de Evaluación
        </Button>
        <PeriodCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          loading={this.state.loading}
          planningList={this.props.planningList}
          handleChangePlan={this.handleChangePlan}
          selectedPlan={this.state.selectedPlan}
          currentPlan={this.state.currentPlan}
          datePickerStatus={this.state.datePickerStatus}
        />
      </div>
    );
  }
}

const PeriodCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        loading,
        planningList,
        handleChangePlan,
        selectedPlan,
        currentPlan,
        datePickerStatus,
      } = this.props;
      const { getFieldDecorator } = form;
      let planningOptions = [
        <Option key={0} value={0}>{`Selecciona un plan`}</Option>,
      ];

      if (planningList.length > 0) {
        planningOptions = planningList.map((planning) => {
          return (
            <Option key={planning.id} value={planning.id}>
              {planning.name}
            </Option>
          );
        });
      }

      return (
        <Modal
          visible={visible}
          confirmLoading={loading}
          title="Agregar una nueva periodo  de evaluación"
          okText="Guardar"
          cancelText="Cancelar"
          onCancel={onCancel}
          onOk={onCreate}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Form.Item label="Nombre de la periodo  de evaluación">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message:
                      "Debe ingresar el nombre de la periodo  de evaluación",
                    whitespace: true,
                  },
                ],
              })(<Input placeholder={"Ingrese el nombre del periodo"} />)}
            </Form.Item>
            <Form.Item label="Seleccione un plan operativo">
              {getFieldDecorator("planning", {
                rules: [
                  {
                    required: true,
                    message: `Por favor seleccione un plan operativo`,
                  },
                ],
              })(
                <Select
                  placeholder={"Seleccione un plan operativo"}
                  style={{ width: "100%" }}
                  onChange={handleChangePlan}
                >
                  {planningOptions}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              validateStatus={datePickerStatus}
              help={
                datePickerStatus === "error"
                  ? "Fecha de inicio no puede ser mayor o igual que fecha de fin"
                  : ""
              }
              label="Fecha de inicio del periodo"
            >
              {getFieldDecorator("start_date", {
                rules: [
                  {
                    type: "object",
                    required: true,
                    message: `Debe ingresar la fecha de inicio del periodo`,
                    whitespace: true,
                  },
                ],
                initialValue: moment().tz(TIMEZONE),
              })(
                <DatePicker
                  disabled={selectedPlan}
                  disabledDate={(currentDate = moment().tz(TIMEZONE)) => {
                    return currentDate.year() !== currentPlan.year;
                  }}
                  format="DD/MM/YYYY"
                  placeholder="Seleccione una fecha"
                />
              )}
            </Form.Item>
            <Form.Item
              validateStatus={datePickerStatus}
              help={
                datePickerStatus === "error"
                  ? "Fecha de fin no puede ser menor o igual que fecha de inicio"
                  : ""
              }
              label="Fecha de finalización del periodo"
            >
              {getFieldDecorator("end_date", {
                rules: [
                  {
                    type: "object",
                    required: true,
                    message: `Debe ingresar la fecha de finalización del periodo`,
                    whitespace: true,
                  },
                ],
                initialValue: moment().tz(TIMEZONE).add(1, "days"),
              })(
                <DatePicker
                  disabled={selectedPlan}
                  format="DD/MM/YYYY"
                  placeholder="Seleccione una fecha"
                  disabledDate={(currentDate = moment().tz(TIMEZONE)) => {
                    return currentDate.year() !== currentPlan.year;
                  }}
                />
              )}
            </Form.Item>
            <Form.Item className="collection-create-form_last-form-item">
              {getFieldDecorator("active", {
                initialValue: true,
              })(
                <Radio.Group>
                  <Radio value={true}>Activo</Radio>
                  <Radio value={false}>Inactivo</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

// ************************ COMPONENT TO RENDER TABLE ********************************************
class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data, editingKey: "" };
    this.columns = [
      {
        title: "#",
        dataIndex: "key",
        width: "5%",
        align: "center",
        editable: false,
        sorter: (a, b) => a.key - b.key,
      },
      {
        title: "Nombre del periodo",
        dataIndex: "name",
        editable: true,
        sorter: (a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          // names must be equal
          return 0;
        },
      },
      {
        title: "Plan Operativo",
        dataIndex: "planning",
        editable: false,
        render: (planning) => {
          return <Tag color="blue">{planning.name}</Tag>;
        },
      },
      {
        title: "Fecha de inicio",
        dataIndex: "start_date",
        editable: true,
        render: (start_date) => {
          let date = moment(start_date).tz(TIMEZONE);

          return date.format("DD/MM/YYYY");
        },
        sorter: (a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          // names must be equal
          return 0;
        },
      },
      {
        title: "Fecha de cierre",
        dataIndex: "end_date",
        editable: true,
        render: (end_date) => {
          let date = moment(end_date).tz(TIMEZONE);

          return date.format("DD/MM/YYYY");
        },
        sorter: (a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          // names must be equal
          return 0;
        },
      },
      {
        title: "Estado",
        dataIndex: "status",
        editable: false,
        align: "center",
        render: (status) => (
          <Tag color={status ? "blue" : "magenta"}>
            {status ? "Activo" : "Inactivo"}
          </Tag>
        ),
        sorter: (a, b) => a.status - b.status,
      },
      {
        title: "Acciones",
        dataIndex: "operation",
        width: "300px",
        align: "center",

        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    type="primary"
                    icon="save"
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    Guardar
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="¿Seguro que deseas cancelar?"
                onConfirm={() => this.cancel(record.key)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  ghost
                  type="danger"
                  icon="close-circle"
                  style={{ marginRight: 8 }}
                >
                  Cancelar
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <>
              <Button
                type="primary"
                icon="edit"
                ghost
                onClick={() => this.edit(record.key)}
                disabled={editingKey !== ""}
              >
                Editar
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title={` ¿Seguro que deseas ${
                  record.status ? "desactivar" : "activar"
                } esta periodo  de evaluación?`}
                onConfirm={() => this.updateStatus(record.key)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  type={record.status ? "danger" : "primary"}
                  icon={record.status ? "close" : "check"}
                  ghost
                  disabled={editingKey !== ""}
                >
                  {record.status ? "Desactivar" : "Activar"}
                </Button>
              </Popconfirm>
            </>
          );
        },
      },
    ];
  }

  isEditing = (record) => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  updateStatus = (key) => {
    const newData = [...this.state.data];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
      });
      this.setState({ editingKey: "" });

      //activate/deactivate register
      this.props.updateStatus(item);
    }
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: "" });

        //update register
        if (row.start_date < row.end_date) {
          this.props.update(item, row);
        } else {
          console.log("fechas no coinciden");
        }
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType:
            col.dataIndex === "start_date"
              ? "start_date"
              : col.dataIndex === "end_date"
              ? "end_date"
              : col.dataIndex === "planning"
              ? "planning"
              : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          options: {
            planning: this.props.planningList || [],
          },
        }),
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          bordered
          dataSource={this.state.data}
          loading={this.props.loading}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            onChange: this.cancel,
            showSizeChanger: true,
            locale: { items_per_page: "/ página" },
            pageSizeOptions: ["10", "25", "50", "100"],
          }}
        />
      </EditableContext.Provider>
    );
  }
}

// ************************ COMPONENT TO MAKE EDITABLE CELLS *************************************
class EditableCell extends React.Component {
  state = {
    currentPlan: {},
  };

  handleChangePlan = (plan_id) => {
    this.setState({
      currentPlan: this.props.record.planning,
    });
  };

  getInput = (planning = null) => {
    let planningOptions = [
      <Option key={0} value={0}>{`Selecciona un plan`}</Option>,
    ];
    if (this.props.options.planning.length > 0) {
      planningOptions = this.props.options.planning.map((planning) => {
        return (
          <Option key={planning.id} value={planning.id}>
            {planning.name}
          </Option>
        );
      });
    }
    if (this.props.inputType === "start_date") {
      //Return input type
      return (
        <DatePicker
          format="DD/MM/YYYY"
          placeholder="Seleccione una fecha"
          disabledDate={(currentDate = moment().tz(TIMEZONE)) => {
            return currentDate.year() !== planning.year;
          }}
        />
      );
    } else if (this.props.inputType === "end_date") {
      return (
        <DatePicker
          format="DD/MM/YYYY"
          placeholder="Seleccione una fecha"
          disabledDate={(currentDate = moment().tz(TIMEZONE)) => {
            return currentDate.year() !== planning.year;
          }}
        />
      );
    } else if (this.props.inputType === "planning") {
      return (
        <Select onChange={this.handleChangePlan} style={{ width: "100%" }}>
          {planningOptions}
        </Select>
      );
    } else return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {/* Campos de texto */}
            {inputType === "text" &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                    whitespace: true,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}

            {/* Campos de fecha */}
            {dataIndex.indexOf("date") !== -1 &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    type: "object",
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                    whitespace: true,
                  },
                ],
                initialValue: moment(record[dataIndex]).tz(TIMEZONE),
              })(this.getInput(record.planning))}

            {/* Campos de selección  */}
            {inputType === "planning" &&
              getFieldDecorator("planning", {
                rules: [
                  {
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                  },
                ],
                initialValue: record[dataIndex].id,
              })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
