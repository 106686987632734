import React from "react";
import { Route, Redirect } from "react-router-dom";

//rest are the props inherit from parent component
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        //Protect pages from no authorized users
        let permissions_list = rest.permissions.map(
          (permission) => permission.route
        );
        permissions_list.push("/");
        permissions_list.push("/update-password");

        if (permissions_list.includes("/planning")) {
          permissions_list.push(rest.location.pathname);
        }

        if (
          !rest.isAdmin &&
          rest.isAuthenticated &&
          !permissions_list.includes(rest.location.pathname)
        ) {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }

        let redirect;
        if (
          rest.isAuthenticated &&
          rest.updatePassword &&
          rest.path === "/update-password"
        ) {
          redirect = redirect = <Component {...props} />;
        } else if (rest.isAuthenticated && rest.updatePassword) {
          redirect = (
            <Redirect
              to={{
                pathname: "/update-password",
                state: { from: props.location },
              }}
            />
          );
        } else if (!rest.isAuthenticated) {
          redirect = (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        } else if (
          rest.isAuthenticated &&
          !rest.updatePassword &&
          rest.path === "/update-password"
        ) {
          redirect = (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        } else {
          redirect = <Component {...props} />;
        }

        return redirect;
      }}
    />
  );
};

export default PrivateRoute;
