import rest from "../rest";
import http from "../axios";
import {
  TAG_ENDPOINT,
  TAG_REMOVE_ENDPOINT,
  TAG_ADD_ENDPOINT,
} from "../constants";

export default {
  ...rest(TAG_ENDPOINT), //to get other endpoints
  remove: async (data) => {
    try {
      const res = await http.post(TAG_REMOVE_ENDPOINT, data);
      return res;
    } catch (e) {
      return e;
    }
  },
  add: async (data) => {
    try {
      const res = await http.post(TAG_ADD_ENDPOINT, data);
      return res;
    } catch (e) {
      return e;
    }
  },
};
