import React, { useState, useEffect } from "react";
import { Card, Row, Col, notification } from "antd";

import Lottie from "lottie-react-web";
import { connect } from "react-redux";

import animation from "../../_animations/lock_animation";
import logo from "../../_images/ese-plus-logo.png";
import useForm from "../../components/useForm/useForm";
import validate from "../../components/useForm/LoginValidationRules";
import history from "../../_helpers/history";
import { auth, notifications } from "../../_redux/actions";

import { FormLogin } from "../../components/Forms/";
import { authenticationService } from "../../_helpers/services/";
import { NOTIFICATION } from "../../_helpers/constants";

const Login = (props) => {
  const [isLoading, setIsLoading] = useState({ loading: false });

  useEffect(() => {
    if (props.messages.length > 0) {
      props.messages.forEach((message) => {
        if (message.active) {
          notification[message.type]({
            message: message.title,
            description: message.body,
            duration: 3,
            onClose: () => {
              props.hideNotification(message.id);
            },
            style: {
              width: 400,
              marginLeft: 335 - 400,
            },
          });
          props.updateNotification(message);
        }
      });
    }
  });

  const enterLoading = () => {
    setIsLoading({ ...isLoading, loading: true });
  };

  const outLoading = () => {
    setIsLoading({ ...isLoading, loading: false });
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    login,
    validate
  );

  async function login() {
    enterLoading();
    let message;
    let data = {
      username: values.username,
      password: values.password,
      application: "main",
    };

    let res = await authenticationService.login(data);
    outLoading();
    if (res.status === 200) {
      props.logIn(res.data);
      message = {
        title: "Éxito",
        body: "Sesión iniciada con éxito.",
        type: NOTIFICATION.TYPES.SUCCESS,
      };
      props.showNotification(message);
      history.push("/");
    } else {
      message = {
        title: "ERROR AL INICIAR SESION",
        body: <span>Error con: {res.request.response}</span>,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function passwordResetRequest(values) {
    let message;
    let error = false;
    let res = await authenticationService.password_reset_request(values);

    if (res.status === 200) {
      message = {
        title: "Éxito",
        body: `Solicitud de restablecimiento enviada con éxito para el usuario: ${values.email}`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "ERROR AL GUARDAR",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      error = true;
    }

    props.showNotification(message);

    if (!error) {
      //clear table data source
      // updateLoading();
    }
    return error;
  }

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="h-100 background"
    >
      <Col xs={20} sm={18} md={16} lg={12} xl={10} xxl={8}>
        <Card style={{ width: "100%" }} className="t-50 layout-login-card">
          <Row type="flex" justify="center" align="middle">
            <Col span={22}>
              <div
                style={{
                  width: "50%",
                  height: "50%",
                  margin: "0 auto",
                  marginTop: "-50px",
                  marginBottom: "-50px",
                }}
              >
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                />
              </div>
            </Col>
            <Col span={22}>
              <FormLogin
                errors={errors}
                values={values}
                onSubmitHandler={handleSubmit}
                onChangeHandler={handleChange}
                isLoading={isLoading.loading}
                passwordResetRequest={passwordResetRequest}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <a target="_blank" rel="noopener noreferrer" href="https://ese.plus">
        <img className="floating-brand" src={logo} alt="logo" />
      </a>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.notification.messages,
  };
};
const mapDispatchToProps = {
  logIn: auth.logIn,
  showNotification: notifications.addNotification,
  updateNotification: notifications.updateNotification,
  hideNotification: notifications.removeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
