import React from "react";
import { Row, Col, Input, Alert, Icon, Button } from "antd";
const FormUpdatePassword = props => {
  return (
    <Row type="flex" justify="center" align="middle">
      <form className="" autoComplete="off">
        <Col span={24} className="mb-20">
          <Input.Password
            className={`${props.errors.password && "alert-validate"}`}
            data-validate="Password is required"
            placeholder="Ingrese su contraseña"
            name="password"
            value={props.values.password || ""}
            onChange={props.onChangeHandler}
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          />
          {props.errors.password && (
            <Alert
              message={`${props.errors.password}`}
              type="error"
              showIcon
              className="red-alert"
            />
          )}
        </Col>
        <Col span={24} className="mb-10 center">
          <Button
            size="large"
            type="secondary"
            icon="close"
            style={{ float: "left" }}
            loading={props.isLoading}
            onClick={e => {
              props.closeSession();
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="primary"
            icon="right"
            style={{ float: "right" }}
            loading={props.isLoading}
            htmlType="submit"
            onClick={e => {
              props.onSubmitHandler(e);
            }}
          >
            Actualizar contraseña
          </Button>
        </Col>
      </form>
    </Row>
  );
};

export default FormUpdatePassword;
