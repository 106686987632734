import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notifications } from "../../_redux/actions";
import {
  Row,
  Col,
  Typography,
  Select,
  Tag,
  Button,
  Empty,
  Table,
  Spin,
  Form,
  Modal,
  Alert,
  Divider,
  Progress,
  Tooltip,
  InputNumber,
  List,
  Icon,
  Input,
  message,
  Badge,
  Upload,
  Anchor,
  DatePicker,
  Popover,
  Checkbox, Switch
} from "antd";
import {
  periodService,
  reportService,
  departmentService,
  userProfileService,
  instrumentService,
  objectiveService,
  supervisionService,
  periodFollowUpService,
} from "../../_helpers/services/";
import {
  NOTIFICATION,
  TIMEZONE,
  PERIOD_FOLLOW_UP_STATUS,
  IMAGES_FILETYPE,
  EVALUATION_PERIODICITY,
} from "../../_helpers/constants";
import Viewer from "react-viewer";
import ReactToPrint from "react-to-print";
import moment from "moment-timezone";

const { Title, Text } = Typography;
const { Option } = Select;
const ButtonGroup = Button.Group;
const { Link } = Anchor;
const { TextArea } = Input;

const IncompletedTask = (props) => {
  const [periodList, setPeriodList] = useState([]);
  const [users, setUsers] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [periodSelected, setPeriodSelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState(null);
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  //*********************************************************************
  //*********************** data handlers *******************************
  //*********************************************************************
  const handleChangePeriod = (value) => {
    if (value === undefined) {
      setPeriodSelected(null);
      setResults([]);
    } else {
      setPeriodSelected(value);
    }
  };

  const handleChangeDepartment = (value) => {
    if (value === undefined) {
      setDepartmentSelected(null);
    } else {
      setDepartmentSelected(value);
    }
  };

  const handleGenerateReport = async (background = false) => {
    if (periodSelected === null) {
      let message = {
        title: "COMPLETAR FILTRO",
        body: "Debe seleccionar el periodo",
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    } else {
      if (!background) {
        setLoading(true);
      }
      let data = {};
      if (departmentSelected !== null) {
        data = {
          period: periodSelected,
          department: departmentSelected,
        };
      } else {
        data = {
          period: periodSelected,
        };
      }

      let res = await reportService.incompleted_task(data);
      setResults(res.data);
      if (!background) {
        setLoading(false);
      }
    }
  };

  const handleChangeShowIncompleteOnly = (checked) =>{
    setShowIncomplete(checked)    
  }

  //*********************************************************************
  //*********************** Initial data loaders ************************
  //*********************************************************************
  async function loadUsers() {
    let message;
    let filters = [
      {
        criteria: "is_active",
        value: true,
      },
      {
        criteria: "is_superuser",
        value: false,
      },
    ];
    let res = await userProfileService.search(filters);
    if (res.status === 200) {
      // Process reciveid data
      setUsers(res.data);
    } else {
      message = {
        title: "Error al cargar el listado de usuarios",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadInstruments() {
    let message;
    let filters = [
      {
        criteria: "active",
        value: true,
      },
    ];
    let res = await instrumentService.search(filters);
    if (res.status === 200) {
      // Process reciveid data
      setInstruments(res.data);
    } else {
      message = {
        title: "Error al cargar el listado de instrumentos de evaluación",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadPeriods() {
    let message;
    let res = await periodService.get_list();
    if (res.status === 200) {
      // Process reciveid data
      setPeriodList(res.data);
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadDeparments() {
    let message;
    let res = await departmentService.fetch();
    if (res.status === 200) {
      // Process reciveid data
      setDepartmentList(res.data);
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  //*********************************************************************
  //*********************** Handle Update Objective  ********************
  //*********************************************************************
  const updateObjective = async (
    objective,
    value,
    reload = true,
    attachments = null
  ) => {
    let error = true;
    let message = "";
    let attr = Object.keys(value)[0];
    let data = {
      id: objective,
      [attr]: value[attr],
    };
    const formData = new FormData();
    if (attachments) {
      formData.append("id", objective);
      formData.append([attr], value[attr]);

      attachments.forEach((file) => {
        formData.append("attachments[]", file);
      });
    }

    let res = await objectiveService.update_per_field(
      attachments ? formData : data
    );
    if (res.status !== 200) {
      message = {
        title: `Error al actualizar el ${attr}`,
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    } else {
      error = false;
    }
    if (reload) await handleGenerateReport(true);
    return error;
  };

  const saveInstrumentForm = async (data) => {
    let error = true;
    let message = "";

    let res = await supervisionService.insert(data);
    if (res.status !== 201) {
      message = {
        title: `Error al guardar la supervisión`,
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    } else {
      error = false;
    }
    await handleGenerateReport(true);
    return error;
  };

  const updatePeriodFollowUp = async (id, data, reload = true) => {
    let error = true;
    let message = "";
    console.log(data);
    let res = await periodFollowUpService.update(id, data);
    if (res.status !== 200) {
      message = {
        title: `Error al guardar el estado del seguimiento`,
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    } else {
      error = false;
    }
    if (reload) await handleGenerateReport(true);
    return error;
  };

  useEffect(() => {
    if (periodList.length === 0 && firstLoad && props.isAuthenticated) {
      loadPeriods();
      loadDeparments();
      loadInstruments();
      loadUsers();
      setFirstLoad(false);
    }
  }, [periodList]);

  return (
    <>
      <Row type="flex" justify="center">
        <Col span={24}>
          <Title type="secondary">
            Reporte de actividades individuales
          </Title>
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={10}>
          <Select
            showSearch
            style={{ width: "100%", marginBottom: "20px" }}
            placeholder="Seleccione un periodo"
            optionFilterProp="children"
            optionLabelProp="title"
            onChange={handleChangePeriod}
            allowClear={true}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {periodList.map((period) => {
              return (
                <Option key={period.id} value={period.id} title={period.name}>
                  <Tag color="blue">
                    {moment(period.start_date)
                      .tz(TIMEZONE)
                      .format("DD/MM/YYYY")}
                  </Tag>{" "}
                  -{" "}
                  <Tag color="blue">
                    {moment(period.end_date).tz(TIMEZONE).format("DD/MM/YYYY")}
                  </Tag>
                  <span>{period.name}</span>
                </Option>
              );
            })}
          </Select>
        </Col>
        {props.isReportManager && (
          <Col span={10}>
            <Select
              showSearch
              style={{
                width: "100%",
                marginBottom: "20px",
                paddingLeft: "10px",
              }}
              placeholder="Seleccione un departamento"
              optionFilterProp="children"
              optionLabelProp="title"
              onChange={handleChangeDepartment}
              allowClear={true}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {departmentList.map((department) => {
                return (
                  <Option
                    key={department.id}
                    value={department.id}
                    title={department.name}
                  >
                    {department.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        )}
        <Col span={4}>
          <Button
            type="primary"
            shape="round"
            size="large"
            icon="plus-circle"
            style={{ marginBottom: "20px", marginLeft: "10px", width: "100%" }}
            onClick={() => {
              handleGenerateReport();
            }}
          >
            Generar reporte
          </Button>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col span={24} style={{marginBottom: '20px'}}>
        <Switch defaultChecked={false}  disabled={results.length === 0 } onChange={handleChangeShowIncompleteOnly}/> <Tag color='purple'>Mostrar solo incompletas</Tag>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <NestedTable
            results={results}
            loading={loading}
            userId={props.userId}
            usersOpts={users}
            instrumentList={instruments}
            isProccessOwner={props.isProccessOwner}
            updateObjective={updateObjective}
            saveInstrumentForm={saveInstrumentForm}
            updatePeriodFollowUp={updatePeriodFollowUp}
            periodSelected={periodSelected}
            showIncomplete={showIncomplete}
          />
        </Col>
      </Row>
    </>
  );
};

// Component for nested table render
const NestedTable = (props) => {
  const {
    results,
    loading,
    userId,
    isProccessOwner,
    usersOpts,
    instrumentList,
    updateObjective,
    saveInstrumentForm,
    updatePeriodFollowUp,
    periodSelected,
    showIncomplete
  } = props;
  const expandedRowRender = (record, index, indent, expanded) => {
    const data = [];

    const rows = results.filter((row) => {
      return row.user === record.user;
    });

    rows.forEach((row) => {
      if (row.objectives.length > 0) {
        row.objectives.forEach((item) => {
          if(showIncomplete){
            if(item.followup.percentage < 100){
              data.push({
                key: `${item.objective.id}-${item.followup.supervision_date}`,
                id: item.objective.id,
                objective: {
                  payload: item.objective,
                  followupId: item.followup.id,
                },
                status: PERIOD_FOLLOW_UP_STATUS.filter(
                  (x) => x.id === item.followup.status
                )[0].name,
                percentage: item.followup.percentage,
                supervision_date: moment(item.followup.supervision_date)
                  .tz(TIMEZONE)
                  .format("DD/MM/YYYY"),
                updated_at: moment(item.followup.updated_at)
                  .tz(TIMEZONE)
                  .format("DD/MM/YYYY"),
              });
            }
          }else{
            data.push({
              key: `${item.objective.id}-${item.followup.supervision_date}`,
              id: item.objective.id,
              objective: {
                payload: item.objective,
                followupId: item.followup.id,
              },
              status: PERIOD_FOLLOW_UP_STATUS.filter(
                (x) => x.id === item.followup.status
              )[0].name,
              percentage: item.followup.percentage,
              supervision_date: moment(item.followup.supervision_date)
                .tz(TIMEZONE)
                .format("DD/MM/YYYY"),
              updated_at: moment(item.followup.updated_at)
                .tz(TIMEZONE)
                .format("DD/MM/YYYY"),
            });
          }
          
        });
      }
    });
    const columns = [
      {
        title: "Objetivos del periodo",
        dataIndex: "objective",
        render: (objective) => {
          return (
            <ObjectiveShowDetailModal
              userId={userId}
              item={objective.payload}
              updateObjective={updateObjective}
              usersOpts={usersOpts}
              instrumentList={instrumentList}
              saveInstrumentForm={saveInstrumentForm}
              updatePeriodFollowUp={updatePeriodFollowUp}
              isProccessOwner={isProccessOwner}
              periodSelected={periodSelected}
              followupSelected={objective.followupId}
            />
          );
        },
      },
      {
        title: "Fecha máxima del seguimiento",
        dataIndex: "supervision_date",
        align: "center",
      },
      {
        title: "Estado del seguimiento",
        dataIndex: "status",
        render: (status) => <Tag>{status}</Tag>,
      },
      {
        title: "% del seguimiento",
        dataIndex: "percentage",
        align: "center",
        render: (total) => {
          return <Progress percent={parseFloat(total)} size="small" />;
        },
      },
      {
        title: "Fecha última actualización",
        dataIndex: "updated_at",
        align: "center",
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        // rowKey={}
      />
    );
  };

  // For main table
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      width: "50px",
      align: "center",
      editable: false,
      key: "key",
      sorter: (a, b) => a.key - b.key,
    },
    { title: "Usuario", dataIndex: "user", key: "user" },
    {
      title: "Porcentaje de logro del período",
      dataIndex: "total_achievement",
      key: "total_achievement",
      align: "center",
      render: (total) => {
        return (
          <Progress type="circle" percent={parseFloat(total)} width={80} />
        );
      },
    },
    {
      title: "Departamento",
      dataIndex: "department",
      key: "department",
    },
  ];

  return (
    <>
      {loading && (
        <Row type="flex" justify="center">
          <Col span={2}>
            <Spin
              tip="Generando reporte ..."
              size="large"
              style={{ textAlign: "center" }}
            ></Spin>
          </Col>
        </Row>
      )}
      {results.length === 0 && !loading && (
        <Empty
          image={Empty.PRESENTED_IMAGE_DEFAULT}
          description={
            "No hay resultados para mostrar, intente ajustando los filtros."
          }
        />
      )}

      {results.length > 0 && !loading && (
        <Table
          rowKey="user_id"
          className="components-table-demo-nested"
          columns={columns}
          expandedRowRender={expandedRowRender}
          dataSource={results}
        />
      )}
    </>
  );
};

// ***********************************************************************************************/
// ***************** COMPONENT TO SHOW OBJECTIVE DETAIL IN A MODAL *******************************/
// ***********************************************************************************************/
const ObjectiveShowDetailModal = Form.create({ name: "objective_modal" })(
  class extends React.Component {
    state = {
      visible: false,
      loading: false,
      showAddFollowUp: false,
      showAddDeal: false,
      showPeriods: !(this.props.item.start_date && this.props.item.end_date),
      fileList: [],
      files: [],
      followups: [],
      periodFollowUp: undefined,
      currentPeriod: undefined,
      showLightBox: false,
      lightBoxURL: "",
      visiblePDF: false,
    };
    showModal = () => {
      this.setState({ ...this.state, visible: !this.state.visible });
    };
    closeModal = async () => {
      this.setState({ ...this.state, loading: true });
      this.setState({
        ...this.state,
        visible: !this.state.visible,
        loading: false,
      });
    };

    handleSubmitCompleteObjective = async () => {
      this.setState({ ...this.state, loading: true });
      let value = {
        achievement: 100,
      };
      let error = await this.props.updateObjective(
        this.props.item.id,
        value,
        true
      );
      this.setState({
        ...this.state,
        loading: false,
      });
    };

    showAddFollowUpInput = () => {
      this.setState({
        ...this.state,
        showAddFollowUp: !this.state.showAddFollowUp,
      });
    };

    handleSubmitFollowup = () => {
      const { form } = this.props;
      form.validateFields(async (err, value) => {
        if (err) {
          return;
        }
        this.setState({ ...this.state, loading: true });
        let data = {
          followup: value.followup,
        };
        let error = await this.props.updateObjective(
          this.props.item.id,
          data,
          true, //reload
          this.state.fileList
        );
        this.setState({
          ...this.state,
          loading: false,
          showAddFollowUp: false,
          fileList: [],
        });
      });
    };

    handleViewFile = (file) => {
      let extension = file.filename.split(".").pop().toLowerCase();

      //enable image view
      if (IMAGES_FILETYPE.includes(extension)) {
        this.setState({
          ...this.state,
          showLightBox: true,
          lightBoxURL: file.url,
        });
      }

      // if (extension === "pdf") {
      //   this.showModalPreviewPDF();
      // }
    };

    closeLightBox = () => {
      this.setState({ ...this.state, showLightBox: false });
    };

    showAddDealInput = () => {
      this.setState({
        ...this.state,
        showAddDeal: !this.state.showAddDeal,
      });
    };

    disabledTime = () => {
      const current = new moment();
      return {
        disabledHours: () => [], //range(0, current.hour()),
        disabledMinutes: () => [], //range(0, current.minute() + 1),
      };
    };

    disabledDate = (current = moment().tz(TIMEZONE)) => {
      return current && current < moment().tz(TIMEZONE).startOf("day");
    };

    handleSubmitDeal = () => {
      const { form } = this.props;
      form.validateFields(async (err, value) => {
        if (err) {
          return;
        }
        this.setState({ ...this.state, loading: true });
        value = {
          deal: {
            detail: value.deal_detail,
            date: value.deal_date,
            responsible: value.responsible,
          },
        };
        let error = await this.props.updateObjective(this.props.item.id, value);
        this.setState({
          ...this.state,
          loading: false,
          showAddDeal: false,
        });
      });
    };

    handleSubmitAchievement = async (e) => {
      let achievement = parseInt(e.target.value.replace("%", ""));
      if (!Object.is(achievement, NaN)) {
        if (achievement > 0 && achievement <= 100) {
          this.setState({ ...this.state, loading: true });
          let value = {
            achievement: achievement,
          };
          let error = await this.props.updateObjective(
            this.props.item.id,
            value,
            true
          );
          this.setState({ ...this.state, loading: false });
        }
      }
    };

    handleSubmitPeriodFollowUp = async (e) => {
      let periodFollowUpPercentage = parseInt(e.target.value.replace("%", ""));
      if (!Object.is(periodFollowUpPercentage, NaN)) {
        if (periodFollowUpPercentage > 0 && periodFollowUpPercentage <= 99) {
          this.setState({ ...this.state, loading: true });
          let value = {
            periodFollowUp: {
              periodFollowUpPercentage: periodFollowUpPercentage,
              periodFollowUpId: this.state.periodFollowUp.id,
            },
          };

          let error = await this.props.updateObjective(
            this.props.item.id,
            value,
            true
          );

          if (!error) {
            //Update local status and percentage
            let periodFollowUp = this.state.periodFollowUp;
            periodFollowUp.percentage = periodFollowUpPercentage;
            this.setState({ ...this.state, periodFollowUp: periodFollowUp });
          }
          this.setState({ ...this.state, loading: false });
        }
      }
    };

    handleChangePeriod = (period_id) => {
      let current_period = this.props.item.periods.filter(
        (period) => period.id === period_id
      );
      this.setState({
        ...this.state,
        currentPeriod: current_period[0],
        followups: current_period[0].followup,
        periodFollowUp: undefined,
      });
    };

    handleChangePeriodFollowUp = (period_follow_up) => {
      let followup = this.state.currentPeriod.followup.filter(
        (x) => x.id === period_follow_up
      );
      this.setState({
        ...this.state,
        periodFollowUp: followup[0],
      });
    };

    handleChangePeriodFollowUpStatus = async (
      periodFollowUp,
      periodFollowUpStatus
    ) => {
      this.setState({ ...this.state, loading: true });
      //update status
      periodFollowUp.status = periodFollowUpStatus;

      //update percentage
      switch (periodFollowUpStatus) {
        case 1:
          periodFollowUp.percentage = 100;
          break;

        case 2:
          periodFollowUp.percentage = 50;
          break;

        case 3:
          periodFollowUp.percentage = 0;
          break;

        default:
          periodFollowUp.percentage = 0;
          break;
      }

      let error = await this.props.updatePeriodFollowUp(
        periodFollowUp.id,
        periodFollowUp,
        true
      );
      this.setState({ ...this.state, loading: false });
    };

    onRemove = (file) => {
      this.setState((state) => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
        };
      });
    };

    beforeUpload = (file) => {
      const isLt10M = file.size / 1024 / 1024 < 10;
      const isMaxFiles = this.state.fileList.length > 4;
      if (isMaxFiles) {
        message.error("¡ATENCIÓN: Máximo 5 archivos!");
        return !isMaxFiles;
      }

      if (isLt10M) {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
      } else {
        message.error("¡ATENCIÓN: El peso del archivo debe ser menor a 10MB!");
        return isLt10M;
      }
    };

    customRequest = (xhr) => {};
    render() {
      const {
        item,
        usersOpts,
        instrumentList,
        form,
        saveInstrumentForm,
        periodSelected,
        followupSelected,
      } = this.props;
      const { getFieldDecorator } = form;
      const props = {
        name: "attachment",
        listType: "text",
        multiple: false,
        onRemove: this.onRemove,
        beforeUpload: this.beforeUpload,
        customRequest: this.customRequest,
        fileList: this.state.fileList,
        showUploadList: {
          showPreviewIcon: false,
          showDownloadIcon: false,
          showRemoveIcon: true,
        },
      };
      return (
        <>
          <span className="objective-name" onClick={this.showModal}>
            {item.name}
          </span>
          <Modal
            visible={this.state.visible}
            //confirmLoading={loading}
            title={
              <Row>
                <Col span={23}>
                  <p style={{ marginBottom: "0", fontSize: "12px" }}>
                    <Text style={{}} type="secondary">
                      {item.parent_name}
                    </Text>
                  </p>
                  Objetivo:<strong> {item.name}</strong>
                </Col>
                {this.state.loading && (
                  <Col style={{ textAlign: "center" }} span={1}>
                    <Spin size="small" />
                  </Col>
                )}
              </Row>
            }
            okText="Cerrar"
            onOk={this.closeModal}
            maskClosable={false}
            keyboard={false}
            className="objective-modal"
            closable={false}
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <Text style={{ fontSize: 16 }} strong>
              Información de la actividad
            </Text>
            <Row type="flex" align="middle" gutter={16}>
              <Col span={12}>
                <Text type="secondary">Supervisor: </Text>
                <br />
                <Tag color="blue">
                  {item.supervisor &&
                    `${item.supervisor.first_name} ${item.supervisor.last_name}`}
                  {!item.supervisor && `No asignado`}
                </Tag>
              </Col>
              <Col span={12}>
                <Text type="secondary">Responsable: </Text>
                <br />
                <Tag color="blue">
                  {item.responsible &&
                    `${item.responsible.first_name} ${item.responsible.last_name}`}
                  {!item.responsible && `No asignado`}
                </Tag>
              </Col>
            </Row>

            <Row type="flex" align="middle" gutter={16}>
              <Col span={6}>
                <Text type="secondary">Porcentaje de avance global: </Text>
                <br />
                <Tag color="blue">{item.achievement}</Tag>
              </Col>

              <Col span={6}>
                <Text type="secondary">Periodicidad: </Text>
                <br />
                <Tag color="blue">
                  {
                    EVALUATION_PERIODICITY.filter(
                      (x) => x.id === item.periodicity
                    )[0].name
                  }
                </Tag>
              </Col>
              <Col span={6}>
                <>
                  <Text type="secondary">Porcentaje: </Text>
                  <br />
                  <Tag color="blue">{item.percentage}</Tag>
                </>
              </Col>
              <Col span={6}>
                <>
                  <Text type="secondary">Fecha Meta: </Text>
                  <br />
                  <Tag color="blue">
                    {item.due_date &&
                      moment(item.due_date).tz(TIMEZONE).format("DD/MM/YYYY")}
                    {!item.due_date && "No Asiganada"}
                  </Tag>
                </>
              </Col>
            </Row>
            {(this.props.userId === item.supervisor?.id ||
              this.props.isProccessOwner) && (
              <>
                <Divider />
                <Text style={{ fontSize: 16 }} strong>
                  Seguimiento de la actividad
                </Text>

                <Row
                  type="flex"
                  align="middle"
                  gutter={16}
                  style={{ marginTop: "15px" }}
                >
                  <Col span={8}>
                    {item.periods.length > 0 && (
                      <>
                        <Text type="secondary">Periodos asignados </Text>
                        <br />

                        <Select
                          placeholder="Seleccione un periodo"
                          optionLabelProp="title"
                          style={{ width: "100%" }}
                          onChange={this.handleChangePeriod}
                        >
                          {item.periods.map((period) => {
                            if (period.id === periodSelected) {
                              return (
                                <Option
                                  key={period.id}
                                  value={period.id}
                                  title={period.name}
                                >
                                  <Tag color="blue">
                                    {moment(period.start_date)
                                      .tz(TIMEZONE)
                                      .format("DD/MM/YYYY")}
                                  </Tag>{" "}
                                  -{" "}
                                  <Tag color="blue">
                                    {moment(period.end_date)
                                      .tz(TIMEZONE)
                                      .format("DD/MM/YYYY")}
                                  </Tag>
                                  <span>{period.name}</span>
                                </Option>
                              );
                            }
                          })}
                        </Select>
                      </>
                    )}

                    {item.periods.length === 0 && (
                      <>
                        <Text type="secondary">Periodos Asignados </Text>
                        <br />
                        <Alert
                          message={
                            <Text type="secondary">
                              No hay periodos asignados
                            </Text>
                          }
                          type="warning"
                        />
                      </>
                    )}
                  </Col>
                  <Col span={8}>
                    {item.periods.length > 0 && (
                      <>
                        <Text type="secondary">Seguimientos programados</Text>
                        <br />

                        <Select
                          placeholder="Seleccione un seguimiento"
                          value={
                            this.state.periodFollowUp !== undefined
                              ? this.state.periodFollowUp.id
                              : undefined
                          }
                          disabled={this.state.currentPeriod === undefined}
                          optionLabelProp="title"
                          style={{ width: "100%" }}
                          onChange={this.handleChangePeriodFollowUp}
                        >
                          {this.state.followups.map((followup) => {
                            if (followup.id === followupSelected) {
                              return (
                                <Option
                                  key={followup.id}
                                  value={followup.id}
                                  title={moment(followup.supervision_date)
                                    .tz(TIMEZONE)
                                    .format("DD/MM/YYYY")}
                                >
                                  <Tag color="blue">
                                    {moment(followup.supervision_date)
                                      .tz(TIMEZONE)
                                      .format("DD/MM/YYYY")}
                                  </Tag>{" "}
                                  -{" "}
                                  {
                                    PERIOD_FOLLOW_UP_STATUS.filter(
                                      (x) => x.id === followup.status
                                    )[0].name
                                  }{" "}
                                  - {followup.percentage}%
                                </Option>
                              );
                            }
                          })}
                        </Select>
                      </>
                    )}

                    {item.periods.length === 0 && (
                      <>
                        <Text type="secondary">Seguimientos programados</Text>
                        <br />
                        <Alert
                          message={
                            <Text type="secondary">
                              No hay seguimientos programados
                            </Text>
                          }
                          type="warning"
                        />
                      </>
                    )}
                  </Col>
                  <Col span={8}>
                    {/*0 pendiente, 1 completado, 2 parcial, 3 no completado*/}
                    {this.state.periodFollowUp !== undefined && (
                      <>
                        <Tag
                          color={
                            this.state.periodFollowUp.status === 0
                              ? "gray"
                              : this.state.periodFollowUp.status === 1
                              ? "green"
                              : this.state.periodFollowUp.status === 2
                              ? "geekblue"
                              : "red"
                          }
                        >
                          {
                            PERIOD_FOLLOW_UP_STATUS.filter(
                              (x) => x.id === this.state.periodFollowUp.status
                            )[0].name
                          }
                        </Tag>

                        <ButtonGroup size={"small"}>
                          <Button
                            onClick={() =>
                              this.handleChangePeriodFollowUpStatus(
                                this.state.periodFollowUp,
                                1 //completada
                              )
                            }
                            style={{
                              background:
                                this.state.periodFollowUp.status === 1
                                  ? "#52C41A"
                                  : "",
                              color:
                                this.state.periodFollowUp.status === 1
                                  ? "white"
                                  : "",
                            }}
                          >
                            COMP
                          </Button>
                          <Button
                            onClick={() =>
                              this.handleChangePeriodFollowUpStatus(
                                this.state.periodFollowUp,
                                2 //parcialmente completada
                              )
                            }
                            style={{
                              background:
                                this.state.periodFollowUp.status === 2
                                  ? "#1890ff"
                                  : "",
                              color:
                                this.state.periodFollowUp.status === 2
                                  ? "white"
                                  : "",
                            }}
                          >
                            P.COMP
                          </Button>
                          <Button
                            onClick={() =>
                              this.handleChangePeriodFollowUpStatus(
                                this.state.periodFollowUp,
                                3 //no completada
                              )
                            }
                            style={{
                              background:
                                this.state.periodFollowUp.status === 3
                                  ? "#F5222D"
                                  : "",
                              color:
                                this.state.periodFollowUp.status === 3
                                  ? "white"
                                  : "",
                            }}
                          >
                            N.COMP
                          </Button>
                        </ButtonGroup>

                        <Row>
                          <Col span={18}>
                            <Tooltip
                              title="Porcentaje de avance del seguimiento"
                              placement="bottom"
                            >
                              <Progress
                                percent={parseFloat(
                                  this.state.periodFollowUp.percentage
                                )}
                                size="small"
                                style={{
                                  marginTop:
                                    this.state.periodFollowUp.status === 2
                                      ? "10px"
                                      : "0",
                                }}
                              />
                            </Tooltip>
                          </Col>
                          {this.state.periodFollowUp.status === 2 && (
                            <Col span={6}>
                              <Form layout="horizontal">
                                <Form.Item className="modal-objective-detail-form-item">
                                  {getFieldDecorator("periodFollowUp", {
                                    initialValue: this.state.periodFollowUp
                                      .percentage,
                                  })(
                                    <InputNumber
                                      min={1}
                                      max={99}
                                      formatter={(value) => `${value}%`}
                                      parser={(value) => value.replace("%", "")}
                                      style={{ width: "95%", marginLeft: "5%" }}
                                      step={1}
                                      onPressEnter={
                                        this.handleSubmitPeriodFollowUp
                                      }
                                    />
                                  )}
                                </Form.Item>
                              </Form>
                            </Col>
                          )}
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
            <Divider />
            <Row type="flex" align="middle" gutter={16}>
              <Col span={12}>
                <Form>
                  <Form.Item className="modal-objective-detail-form-item">
                    <div>
                      <Text style={{ fontSize: 16 }} strong>
                        Bitácora de evidencias
                      </Text>
                      {(this.props.userId === item.supervisor?.id ||
                        this.props.isProccessOwner ||
                        this.props.userId === item.responsible?.id) && (
                        <Button
                          type={
                            this.state.showAddFollowUp ? "default" : "primary"
                          }
                          shape="round"
                          size="small"
                          disabled={this.state.loading}
                          icon={
                            this.state.showAddFollowUp
                              ? "close-circle"
                              : "plus-circle"
                          }
                          style={{ float: "right", marginTop: "5px" }}
                          onClick={this.showAddFollowUpInput}
                        >
                          {this.state.showAddFollowUp
                            ? "Cancelar"
                            : "Agregar evidencias"}
                        </Button>
                      )}
                      {this.state.showAddFollowUp && (
                        <Button
                          type="primary"
                          shape="round"
                          size="small"
                          icon="save"
                          disabled={this.state.loading}
                          style={{
                            float: "right",
                            marginTop: "5px",
                            marginRight: "20px",
                          }}
                          onClick={this.handleSubmitFollowup}
                        >
                          {"Guardar"}
                        </Button>
                      )}
                    </div>
                    {item.followups.length === 0 &&
                      !this.state.showAddFollowUp && (
                        <Empty
                          description={
                            <Text type="secondary">
                              No hay bitácora para este objetivo
                            </Text>
                          }
                        />
                      )}

                    {item.followups.length > 0 && !this.state.showAddFollowUp && (
                      <List
                        size="large"
                        bordered
                        pagination={{
                          hideOnSinglePage: true,
                          pageSize: 3,
                          locale: { items_per_page: "/ página" },
                        }}
                        dataSource={item.followups}
                        renderItem={(item) => (
                          <List.Item>
                            <>
                              <Viewer
                                visible={this.state.showLightBox}
                                onClose={this.closeLightBox}
                                images={[
                                  {
                                    src: this.state.lightBoxURL,
                                    alt: "",
                                  },
                                ]}
                              />
                              {item.attachments.length > 0 && (
                                <Popover
                                  placement="topLeft"
                                  title={"Archivos adjuntos"}
                                  overlayStyle={{ minWidth: "350px" }}
                                  content={
                                    <Anchor affix={false}>
                                      {item.attachments.map((file) => {
                                        let extension = file.filename
                                          .split(".")
                                          .pop()
                                          .toLowerCase();
                                        return (
                                          <React.Fragment key={file.url}>
                                            <p
                                              style={{
                                                marginLeft: "15px",
                                                marginTop: "5px",
                                                marginBottom: "0px",
                                              }}
                                            >
                                              {file.filename}
                                            </p>
                                            <Row>
                                              <Col span={12}>
                                                <Link
                                                  key={file.filename}
                                                  href={file.url}
                                                  target="_blank"
                                                  title={
                                                    <Button
                                                      type="default"
                                                      icon="download"
                                                    >
                                                      Descargar
                                                    </Button>
                                                  }
                                                />
                                              </Col>
                                              {IMAGES_FILETYPE.includes(
                                                extension
                                              ) && (
                                                <Col span={12}>
                                                  <Button
                                                    style={{ marginTop: "7px" }}
                                                    type="primary"
                                                    icon="eye"
                                                    onClick={() =>
                                                      this.handleViewFile(file)
                                                    }
                                                  >
                                                    Ver
                                                  </Button>
                                                </Col>
                                              )}
                                            </Row>
                                          </React.Fragment>
                                        );
                                      })}
                                    </Anchor>
                                  }
                                  trigger="hover"
                                >
                                  <Badge
                                    count={
                                      <>
                                        <Icon
                                          type="paper-clip"
                                          style={{
                                            color: "#2f54eb",
                                            marginRight: "5px",
                                          }}
                                        />
                                      </>
                                    }
                                  />
                                </Popover>
                              )}
                              <Tag color="geekblue">
                                {moment(item.date)
                                  .tz(TIMEZONE)
                                  .format("DD/MM/YYYY  HH:mm")}
                              </Tag>
                              {item.name}
                            </>
                          </List.Item>
                        )}
                      />
                    )}

                    {this.state.showAddFollowUp &&
                      getFieldDecorator("followup", {
                        rules: [
                          {
                            required: true,
                            message:
                              "Debe ingresar la descripción de la evidencia",
                            whitespace: true,
                          },
                          {
                            max: 255,
                            message: "Descripción demasiado larga",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Descripción de la evidencia"
                          onPressEnter={this.handleSubmitFollowup}
                        />
                      )}

                    {this.state.showAddFollowUp &&
                      getFieldDecorator(
                        "attachments",
                        {}
                      )(
                        <Upload {...props}>
                          <Button disabled={this.state.loading}>
                            <Icon
                              type={this.state.loading ? "loading" : "upload"}
                            />
                            {this.state.loading
                              ? "Cargando archivos"
                              : "Adjuntar archivos"}
                          </Button>
                        </Upload>
                      )}
                  </Form.Item>
                </Form>
              </Col>
              <Col span={12}>
                <Form>
                  <Form.Item className="modal-objective-detail-form-item">
                    <div>
                      <Text style={{ fontSize: 16 }} strong>
                        Acuerdos
                      </Text>
                      {(this.props.userId === item.supervisor?.id ||
                        this.props.isProccessOwner ||
                        this.props.userId === item.responsible?.id) && (
                        <Button
                          type={this.state.showAddDeal ? "default" : "primary"}
                          shape="round"
                          size="small"
                          icon={
                            this.state.showAddDeal
                              ? "close-circle"
                              : "plus-circle"
                          }
                          style={{ float: "right", marginTop: "5px" }}
                          onClick={this.showAddDealInput}
                        >
                          {this.state.showAddDeal
                            ? "Cancelar"
                            : "Agregar acuerdo"}
                        </Button>
                      )}
                    </div>
                    {item.deals.length === 0 && !this.state.showAddDeal && (
                      <Empty
                        description={
                          <Text type="secondary">
                            No hay acuerdos para este objetivo
                          </Text>
                        }
                      />
                    )}
                    {item.deals.length > 0 && !this.state.showAddDeal && (
                      <List
                        size="large"
                        bordered
                        pagination={{
                          hideOnSinglePage: true,
                          pageSize: 3,
                          locale: { items_per_page: "/ página" },
                        }}
                        dataSource={item.deals}
                        renderItem={(item) => (
                          <List.Item>
                            <>
                              <Popover
                                placement="topLeft"
                                title={"Responsable"}
                                content={`${item.responsible.first_name} ${item.responsible.last_name}`}
                                trigger="hover"
                              >
                                <Badge
                                  count={
                                    <>
                                      <Icon
                                        type="user"
                                        style={{
                                          color: "#2f54eb",
                                          marginRight: "5px",
                                        }}
                                      />
                                    </>
                                  }
                                />
                              </Popover>

                              <Tag color="geekblue">
                                {moment(item.date_deal)
                                  .tz(TIMEZONE)
                                  .format("DD/MM/YYYY  HH:mm")}
                              </Tag>
                              {item.name}
                            </>
                          </List.Item>
                        )}
                      />
                    )}
                    {this.state.showAddDeal &&
                      getFieldDecorator("deal_detail", {
                        rules: [
                          {
                            required: true,
                            message: "Debe ingresar la descripción del acuerdo",
                            whitespace: true,
                          },
                          {
                            max: 512,
                            message: "Descripción demasiado larga",
                          },
                        ],
                      })(<Input placeholder="Descripción del acuerdo" />)}
                  </Form.Item>

                  {this.state.showAddDeal && (
                    <Form.Item className="modal-objective-detail-form-item">
                      {getFieldDecorator("responsible", {
                        rules: [
                          {
                            required: true,
                            message: "Debe ingresar un responsable del acuerdo",
                          },
                        ],
                      })(
                        <Select
                          placeholder="Seleccione un colaborador"
                          allowClear={true}
                          optionLabelProp="title"
                          style={{ width: "100%" }}
                        >
                          {usersOpts.map((user) => (
                            <Option
                              key={user.id}
                              value={user.id}
                              title={`${user.first_name} ${user.last_name}`}
                            >
                              <span>{`${user.first_name} ${user.last_name}`}</span>
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  )}

                  {this.state.showAddDeal && (
                    <Form.Item>
                      {getFieldDecorator("deal_date", {
                        rules: [
                          {
                            type: "object",
                            required: true,
                            message: "Debe ingresar la fecha del acuerdo",
                            whitespace: true,
                          },
                        ],
                      })(
                        <DatePicker
                          format="DD/MM/YYYY HH:mm"
                          showTime
                          placeholder="Fecha del acuerdo"
                          //disabledTime={this.disabledTime}
                          disabledDate={this.disabledDate}
                        />
                      )}
                      <Button
                        type="primary"
                        size="small"
                        icon="save"
                        style={{ float: "right", marginTop: "10px" }}
                        onClick={this.handleSubmitDeal}
                      >
                        Guardar
                      </Button>
                    </Form.Item>
                  )}
                </Form>
              </Col>
            </Row>

            <Divider />
            <Row type="flex" align="middle" gutter={16}>
              <Col span={24}>
                <SupervisionFormModal
                  instrumentList={instrumentList}
                  item={item}
                  usersList={usersOpts}
                  saveInstrumentForm={saveInstrumentForm}
                />
              </Col>
              <Col span={24}>
                <Form layout="horizontal">
                  <Form.Item className="modal-objective-detail-form-item">
                    {item.supervisions_detail.length === 0 && (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                          "Este objetivo no tiene supervisiones realizadas"
                        }
                      />
                    )}
                    {item.supervisions_detail.length > 0 && (
                      <List
                        size="large"
                        bordered
                        style={{ marginTop: "15px" }}
                        pagination={{
                          hideOnSinglePage: true,
                          pageSize: 3,
                          locale: { items_per_page: "/ página" },
                        }}
                        dataSource={item.supervisions_detail}
                        renderItem={(item) => (
                          // Mostrar listado de supervisiones (documentos)
                          <List.Item>
                            <Row
                              type="flex"
                              align="middle"
                              style={{ width: "100%" }}
                            >
                              <Col span={3}>
                                <Tag color="geekblue">
                                  {moment(item.date)
                                    .tz(TIMEZONE)
                                    .format("DD/MM/YYYY HH:mm")}
                                </Tag>
                              </Col>
                              <Col span={19}>
                                <ShowSupervisionFormModal item={item} />
                              </Col>
                              <Col span={1}>
                                {/* Supervisor - jefe de proceso */}
                                <Popover
                                  placement="topRight"
                                  title={`Supervisor de la actividad`}
                                  content={`${item.supervisor_detail.first_name} ${item.supervisor_detail.last_name}`}
                                  trigger="hover"
                                >
                                  <div style={{ float: "right" }}>
                                    <Icon
                                      type="user"
                                      style={{
                                        color: "#2f54eb",
                                      }}
                                    />
                                  </div>
                                </Popover>
                              </Col>
                              <Col span={1}>
                                {/* Responsable - persona a la que se le encomienda la actividad */}
                                <Popover
                                  placement="topRight"
                                  title={`Responsable de la actividad`}
                                  content={`${item.responsible_detail.first_name} ${item.responsible_detail.last_name}`}
                                  trigger="hover"
                                >
                                  <div style={{ float: "right" }}>
                                    <Icon type="user" />
                                  </div>
                                </Popover>
                              </Col>
                            </Row>
                            <></>
                          </List.Item>
                        )}
                      />
                    )}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>
        </>
      );
    }
  }
);

// ***********************************************************************************************/
// *********************** COMPONENT TO SHOW SUPERVISION FORM  ***********************************/
// ***********************************************************************************************/
const SupervisionFormModal = Form.create({ name: "objective_modal" })(
  class extends React.Component {
    state = {
      visible: false,
      loading: false,
      showAddFollowUp: false,
      showAddDeal: false,
      //showPeriods: this.props.item.start_date && this.props.item.end_date ? false : true,
      fileList: [],
      files: [],
    };
    showModal = () => {
      this.setState({ ...this.state, visible: !this.state.visible });
    };
    closeModal = async () => {
      this.setState({ ...this.state, loading: true });
      this.setState({
        ...this.state,
        visible: !this.state.visible,
        loading: false,
      });
    };
    render() {
      const {
        item,
        instrumentList,
        usersList,
        form,
        saveInstrumentForm,
      } = this.props;
      const { getFieldDecorator } = form;
      let current_instrument = instrumentList.filter(
        (x) => x.id === item.instrument
      );

      current_instrument = current_instrument.pop();
      return (
        <div>
          <Text>Listado de instrumentos de evaluación del objetivo</Text>
          {/* Validar si el objetivo tiene asignado un instrumento */}
          {item.instrument && (
            <>
              <Button
                type="primary"
                shape="round"
                size="small"
                icon="plus-circle"
                style={{ float: "right", marginTop: "5px" }}
                onClick={this.showModal}
              >
                Agregar <strong>{` ${current_instrument.name}`}</strong>
              </Button>

              <Modal
                visible={this.state.visible}
                //confirmLoading={loading}
                title={
                  <Row>
                    <Col span={23}>
                      Agregar nuevo{" "}
                      <strong>{` ${current_instrument.name}`}</strong>
                    </Col>
                    {this.state.loading && (
                      <Col style={{ textAlign: "center" }} span={1}>
                        <Spin size="small" />
                      </Col>
                    )}
                  </Row>
                }
                okText="Cerrar"
                onOk={this.closeModal}
                maskClosable={false}
                keyboard={false}
                // className={
                //   current_instrument.instrument_type.code !== "LA"
                //     ? "objective-modal"
                //     : ""
                // }
                closable={false}
                cancelButtonProps={{ style: { display: "none" } }}
              >
                <Row type="flex" align="middle">
                  <Col span={24}>
                    {/* instrumento de evaluación tipo - Listado de asistencia */}
                    {current_instrument.instrument_type.code === "LA" && (
                      <AttendanceInstrument
                        usersList={usersList}
                        current_instrument={current_instrument}
                        saveInstrumentForm={saveInstrumentForm}
                        item={item}
                        closeModal={this.closeModal}
                      />
                    )}

                    {/* instrumento de evaluación tipo - OTROS INSTRUMENTOS */}
                    {current_instrument.instrument_type.code !== "LA" && (
                      <GenericInstrument
                        current_instrument={current_instrument}
                        saveInstrumentForm={saveInstrumentForm}
                        item={item}
                        closeModal={this.closeModal}
                      />
                    )}
                  </Col>
                </Row>
              </Modal>
            </>
          )}
        </div>
      );
    }
  }
);

// ***********************************************************************************************/
// *********************** COMPONENT TO FILL A SUPERVISION FORM  *********************************/
// ***********************************************************************************************/
class AttendanceInstrument extends React.Component {
  state = {
    loading: false,
    validated: false,
    selectedItems: [],
    observation: "",
  };

  handleChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  changeObservation = (e) => {
    this.setState({ ...this.state, observation: e.target.value });
  };

  saveInstrumentForm = async () => {
    let usersListSelected = this.props.usersList.filter((o) =>
      this.state.selectedItems.includes(String(o.id))
    );

    if (this.state.selectedItems.length === 0) {
      this.setState({ ...this.state, validated: true });
    }

    if (this.state.selectedItems.length > 0) {
      this.setState({ ...this.state, loading: true });
      let error;
      let data = {
        objective: this.props.item.id,
        instrument: this.props.current_instrument.id,
        observations: this.state.observation,
        attendees: this.state.selectedItems,
        responsible: this.props.item.responsible
          ? this.props.item.responsible.id
          : null,
      };
      error = await this.props.saveInstrumentForm(data);
      this.setState({ ...this.state, loading: false });
      if (!error) {
        this.props.closeModal();
      }
    }
  };

  render() {
    const { usersList, current_instrument } = this.props;

    return (
      <>
        <Row type="flex" align="middle">
          <Col span={24}>
            {this.state.selectedItems.length === 0 && this.state.validated && (
              <Alert
                style={{ marginBottom: "10px" }}
                message="Por favor seleccione al menos un asistente"
                type={this.state.validated ? "error" : "info"}
              />
            )}

            <Select
              mode="tags"
              placeholder="Seleccione una persona"
              //value={this.state.selectedItems}
              onChange={this.handleChange}
              style={{ width: "100%" }}
              optionLabelProp="title"
            >
              {usersList.map((item) => (
                <Select.Option
                  key={item.id}
                  value={String(item.id)}
                  title={`${item.first_name} ${item.last_name}`}
                >
                  {`${item.first_name} ${item.last_name}`}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={24}>
            <TextArea
              placeholder="Agregar observaciones"
              rows={4}
              style={{ marginTop: "20px" }}
              value={this.state.observation}
              onChange={this.changeObservation}
            />
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              shape="round"
              size="small"
              icon="plus-circle"
              style={{ float: "right", marginTop: "5px" }}
              onClick={this.saveInstrumentForm}
              loading={this.state.loading}
            >
              Guardar Asistencia
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

const GenericInstrument = Form.create({ name: "generic_instrument_modal" })(
  class extends React.Component {
    state = {
      loading: false,
    };

    saveInstrument = async () => {
      const { form } = this.props;
      form.validateFields(async (err, value) => {
        if (err) {
          return;
        }
        let fields = [];
        for (let i in value) {
          fields.push({
            name: i,
            value: value[i],
          });
        }
        console.log(fields);
        this.setState({ ...this.state, loading: true });
        let data = {
          objective: this.props.item.id,
          instrument: this.props.current_instrument.id,
          responsible: this.props.item.responsible
            ? this.props.item.responsible.id
            : null,
          values: fields,
        };
        let error = await this.props.saveInstrumentForm(data);

        this.setState({
          ...this.state,
          loading: false,
        });
        if (!error) {
          this.props.closeModal();
        }
      });
    };

    render() {
      const { item, current_instrument, form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <>
          <Row type="flex" align="middle">
            <Col span={24}>
              {/* Validación si el instrumento no tiene campos */}
              {current_instrument.fields.length === 0 && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"Este instrumento no tiene campos asignados"}
                />
              )}

              {/* Renderizar el formulario */}
              {current_instrument.fields.length > 0 && (
                <Form layout="horizontal">
                  {current_instrument.fields.map((field) => {
                    let input;

                    if (field.name.indexOf("chkbx") !== -1) {
                      input = (
                        <Form.Item
                          key={field.id}
                          className="modal-objective-detail-form-item"
                        >
                          {getFieldDecorator(field.value, {
                            initialValue: true,
                            valuePropName: "checked",
                          })(<Checkbox>{field.value}</Checkbox>)}
                        </Form.Item>
                      );
                    }
                    if (field.name.indexOf("text") !== -1) {
                      input = (
                        <Form.Item
                          key={field.id}
                          className="modal-objective-detail-form-item"
                          label={field.value}
                        >
                          {getFieldDecorator(field.value, {
                            rules: [
                              {
                                required: true,
                                message: "Debe agregar su evaluación",
                                whitespace: true,
                              },
                            ],
                          })(<Input placeholder="Agrege su evaluación" />)}
                        </Form.Item>
                      );
                    }
                    return input;
                  })}
                </Form>
              )}
            </Col>

            <Col span={24}>
              <Button
                type="primary"
                shape="round"
                size="small"
                icon="plus-circle"
                style={{ float: "right", marginTop: "5px" }}
                onClick={this.saveInstrument}
                loading={this.state.loading}
                disabled={current_instrument.fields.length === 0}
              >
                Guardar instrumento
              </Button>
            </Col>
          </Row>
        </>
      );
    }
  }
);
// ***********************************************************************************************/
// *********************** COMPONENT TO SHOW SUPERVISION FORM  ***********************************/
// ***********************************************************************************************/
class ShowSupervisionFormModal extends React.Component {
  state = {
    visible: false,
    loading: false,
  };
  showModal = () => {
    this.setState({ ...this.state, visible: !this.state.visible });
  };
  closeModal = async () => {
    this.setState({ ...this.state, loading: true });
    this.setState({
      ...this.state,
      visible: !this.state.visible,
      loading: false,
    });
  };
  render() {
    const { item } = this.props;
    return (
      <>
        <span className="objective-name" onClick={this.showModal}>
          {item.instrument_detail.name}
        </span>

        <Modal
          visible={this.state.visible}
          //confirmLoading={loading}
          title={
            <Row>
              <Col span={22}>
                <strong>{`${item.instrument_detail.name}`}</strong>
                <br />
                <Tag color="geekblue">
                  {moment(item.date).tz(TIMEZONE).format("DD/MM/YYYY HH:mm")}
                </Tag>
              </Col>
              <Col span={2}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      type={"primary"}
                      shape="round"
                      size="small"
                      icon={"printer"}
                      style={{ float: "right" }}
                    />
                  )}
                  content={() => this.componentRef}
                />

                <div style={{ display: "none" }}>
                  <FormToPrint
                    ref={(el) => (this.componentRef = el)}
                    item={item}
                  />
                </div>
              </Col>
            </Row>
          }
          okText="Cerrar"
          onOk={this.closeModal}
          maskClosable={false}
          keyboard={false}
          className={"objective-modal"}
          closable={false}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <Row type="flex" align="middle">
            <Col span={24}>
              {/* ******************************************************** 
                  Mostrar el listado de asistencia
              ******************************************************** */}
              {item.instrument_detail.instrument_type.code === "LA" && (
                <>
                  <Title level={3}>Listado de asistentes al seguimiento</Title>
                  <List
                    size="large"
                    bordered
                    dataSource={item.details}
                    renderItem={(supervision_detail) => {
                      let item = <></>;
                      if (supervision_detail.name === "observations") {
                        item = (
                          <List.Item>
                            <strong>Observaciones: </strong>
                            {supervision_detail.value}
                          </List.Item>
                        );
                      } else {
                        item = (
                          <List.Item>{supervision_detail.value}</List.Item>
                        );
                      }

                      return item;
                    }}
                  />
                </>
              )}

              {/* ******************************************************** 
                  Mostrar los otros instrumentos de evaluación
              ******************************************************** */}
              {item.instrument_detail.instrument_type.code !== "LA" && (
                <>
                  <Title level={3}>Instrumento de evaluación</Title>
                  <List
                    size="large"
                    bordered
                    dataSource={item.details}
                    renderItem={(supervision_detail) => {
                      let item = <></>;
                      if (
                        supervision_detail.value === "True" ||
                        supervision_detail.value === "False"
                      ) {
                        item = (
                          <List.Item>
                            <Checkbox
                              checked={supervision_detail.value === "True"}
                            >
                              {supervision_detail.name}
                            </Checkbox>
                          </List.Item>
                        );
                      } else {
                        item = (
                          <List.Item>
                            <strong>{supervision_detail.name}: </strong>
                            {supervision_detail.value}
                          </List.Item>
                        );
                      }

                      return item;
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

// ***********************************************************************************************/
// *************************** PRINT ATTENDANCE LIST *********************************************/
// ***********************************************************************************************/
class FormToPrint extends React.Component {
  render() {
    const { item } = this.props;

    return (
      <div style={{ margin: "50px" }}>
        <h1>{item.instrument_detail.name}</h1>
        <Row>
          <Col span={24}>
            <p>
              Fecha:{" "}
              <strong>
                {moment(item.date).tz(TIMEZONE).format("DD/MM/YYYY HH:mm")}
              </strong>
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>
              Para el objetivo: <strong>{item.objective_name}</strong>
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p>
              Responsable:{" "}
              <strong>{`${item.responsible_detail.first_name} ${item.responsible_detail.last_name}`}</strong>
            </p>
          </Col>
          <Col span={12}>
            <p>
              Supervisor:{" "}
              <strong>{`${item.supervisor_detail.first_name} ${item.supervisor_detail.last_name}`}</strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {/* ******************************************************** 
                  Mostrar el listado de asistencia
              ******************************************************** */}
            {item.instrument_detail.instrument_type.code === "LA" && (
              <List
                size="large"
                bordered
                dataSource={item.details}
                renderItem={(supervision_detail) => {
                  let item = <></>;
                  if (supervision_detail.name === "observations") {
                    item = (
                      <List.Item>
                        <strong>Observaciones: </strong>
                        {supervision_detail.value}
                      </List.Item>
                    );
                  } else {
                    item = <List.Item>{supervision_detail.value}</List.Item>;
                  }

                  return item;
                }}
              />
            )}

            {/* ******************************************************** 
                  Mostrar los otros instrumentos de evaluación
              ******************************************************** */}
            {item.instrument_detail.instrument_type.code !== "LA" && (
              <List
                size="large"
                bordered
                dataSource={item.details}
                renderItem={(supervision_detail) => {
                  let item = <></>;
                  if (
                    supervision_detail.value === "True" ||
                    supervision_detail.value === "False"
                  ) {
                    item = (
                      <List.Item>
                        <Checkbox checked={supervision_detail.value === "True"}>
                          {supervision_detail.name}
                        </Checkbox>
                      </List.Item>
                    );
                  } else {
                    item = (
                      <List.Item>
                        <strong>{supervision_detail.name}: </strong>
                        {supervision_detail.value}
                      </List.Item>
                    );
                  }

                  return item;
                }}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isReportManager: state.auth.payload.is_report_manager,
    userId: state.auth.payload.id,
    isProccessOwner: state.auth.payload.is_process_owner,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(IncompletedTask);
