import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Breadcrumb, notification } from "antd";

import { auth, notifications, navigation } from "../../_redux/actions";
import history from "../../_helpers/history";
import { AvatarMenu, SideMenu, CustomHeader } from "../../components";
import PrivatedRouter from "../../_routes/PrivatedRouter";
import { NOTIFICATION } from "../../_helpers/constants";
import "./Main.css";

const { Content, Footer, Sider } = Layout;
const currentDate = new Date();
const Main = (props) => {
  // ********************* VARIABLES DEFINITION ****************************
  const [collapsed, setCollapsed] = useState(false);
  const [initalRoute, setInitialRoute] = useState(true);
  // ********************* VARIABLES DEFINITION ****************************
  // ---------------------
  // ********************* EVENT HANDLERS **********************************
  const closeSession = () => {
    props.logOut();
    props.clearRoute();
  };
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
  const restorePreviosPage = (location) => {
    history.push(location);
  };
  // ********************* EVENT HANDLERS **********************************
  // ---------------------
  // ********************* COMPONENTS VARIABLES ****************************
  const avatarOptions = <AvatarMenu logout={closeSession} />;
  // ********************* COMPONENTS VARIABLES ****************************

  useEffect(() => {
    if (initalRoute) {
      restorePreviosPage(props.route);
      setInitialRoute(false);
    }

    if (props.isAuthenticated) {
      let message;
      let currentDate = new Date();
      let experiresDate = new Date(props.expiresDate);
      if (currentDate >= experiresDate) {
        message = {
          title: "CIERRE DE SESIÓN",
          body: "Sesión cerrada por vencimiento del token",
          type: NOTIFICATION.TYPES.ERROR,
        };
        props.showNotification(message);
        closeSession();
      }
    }

    if (props.messages.length > 0) {
      props.messages.forEach((message) => {
        if (message.active) {
          notification[message.type]({
            message: message.title,
            description: message.body,
            duration: 3,
            placement: "bottomRight",
            onClose: () => {
              props.hideNotification(message.id);
            },
            style: {
              width: 400,
              marginLeft: 335 - 400,
            },
          });
          props.updateNotification(message);
        }
      });
    }
  });

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        width={275}
      >
        <SideMenu collapsed={collapsed} />
      </Sider>
      <Layout>
        <CustomHeader avatarOptions={avatarOptions} />
        {/* ********************* CONTENT **************************** */}
        <Content style={{ margin: "0 16px", marginTop: 64 }}>
          {/* <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb> */}
          <div className="workspace">
            <PrivatedRouter />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          ©{currentDate.getFullYear()} Created by ese+
        </Footer>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
    token: state.auth.access_token,
    messages: state.notification.messages,
    isAuthenticated: state.auth.isAuthenticated,
    expiresDate: state.auth.payload.expires_in,
    route: state.navigation.route,
  };
};

const mapDispatchToProps = {
  logOut: auth.logOut,
  hideNotification: notifications.removeNotification,
  updateNotification: notifications.updateNotification,
  showNotification: notifications.addNotification,
  clearNotifications: notifications.clearNotifications,
  clearRoute: navigation.logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
