import React from "react";
import { Result } from "antd";
const Dashboard = (props) => {
  return (
    <Result
      status="success"
      title="Sistema de gestión de seguimiento a Planes Operativos"
      subTitle=""
    />
  );
};

export default Dashboard;
