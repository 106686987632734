import React, { useState, useEffect } from "react";
import { Card, Row, Col, Typography, Alert, notification } from "antd";

import { connect } from "react-redux";
import useForm from "../../components/useForm/useForm";
import validate from "../../components/useForm/UpdatePasswordValidationRules";
import history from "../../_helpers/history";
import { auth, notifications } from "../../_redux/actions";

import { FormUpdatePassword } from "../../components/Forms/";
import { authenticationService } from "../../_helpers/services/";
import { NOTIFICATION } from "../../_helpers/constants";

const { Title } = Typography;

const UpdatePassword = props => {
  const [isLoading, setIsLoading] = useState({ loading: false });

  useEffect(() => {
    if (props.isAuthenticated) {
      let message;
      let currentDate = new Date();
      let experiresDate = new Date(props.expiresDate);
      if (currentDate >= experiresDate) {
        message = {
          title: "CIERRE DE SESIÓN",
          body: "Sesión cerrada por vencimiento del token",
          type: NOTIFICATION.TYPES.ERROR
        };
        props.showNotification(message);
        closeSession();
      }
    }

    if (props.messages.length > 0) {
      props.messages.forEach(message => {
        if (message.active) {
          notification[message.type]({
            message: message.title,
            description: message.body,
            duration: 3,
            onClose: () => {
              props.hideNotification(message.id);
            },
            style: {
              width: 400,
              marginLeft: 335 - 400
            }
          });
          props.updateNotification(message);
        }
      });
    }
  }, [props.messages]);

  const closeSession = () => {
    props.logOut();
  };

  const enterLoading = () => {
    setIsLoading({ ...isLoading, loading: true });
  };

  const outLoading = () => {
    setIsLoading({ ...isLoading, loading: false });
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    login,
    validate
  );

  async function login() {
    enterLoading();
    let message;
    let data = {
      password: values.password
    };

    let res = await authenticationService.update_password(data);
    outLoading();
    if (res.status === 200) {
      props.passwordUpdated();
      message = {
        title: "Éxito",
        body: "Contraseña actualizada con éxito.",
        type: NOTIFICATION.TYPES.SUCCESS
      };
      props.showNotification(message);
      history.push("/");
    } else {
      message = {
        title: "ERROR AL ACTUALIZAR LA CONTRASEÑA",
        body:
          res.request.status === 403
            ? "Contraseña temporal expirada, solicite una nueva contraseña al administrador"
            : res.message,
        type: NOTIFICATION.TYPES.ERROR
      };
      props.showNotification(message);
    }
  }

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="h-100 background"
    >
      <Col xs={20} sm={18} md={16} lg={12} xl={10} xxl={8}>
        <Card style={{ width: "100%" }} className="t-50 layout-login-card">
          <Row type="flex" justify="center" align="middle">
            <Col span={22}>
              <Title type="secondary" level={2}>
                Actualizar contraseña
              </Title>
              <Alert message="Ingrese su nueva contraseña" type="info" />
              <br />
              <FormUpdatePassword
                errors={errors}
                values={values}
                closeSession={closeSession}
                onSubmitHandler={handleSubmit}
                onChangeHandler={handleChange}
                isLoading={isLoading.loading}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  return {
    messages: state.notification.messages,
    isAuthenticated: state.auth.isAuthenticated,
    expiresDate: state.auth.payload.expires_in
  };
};

const mapDispatchToProps = {
  passwordUpdated: auth.passwordUpdated,
  hideNotification: notifications.removeNotification,
  updateNotification: notifications.updateNotification,
  showNotification: notifications.addNotification,
  logOut: auth.logOut
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
