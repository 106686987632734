import { navigation } from "../actions/";

let initialState = {
  keyPath: [],
  route: "",
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case navigation.types.UPDATE_MENU_PATH:
      return {
        ...state,
        keyPath: action.payload,
      };
    case navigation.types.UPDATE_MENU_ROUTE:
      return {
        ...state,
        route: action.payload,
      };
    case navigation.types.LOGOUT:
      return {
        ...state,
        route: "/",
        keyPath: ["m0"],
      };
    default:
      return state;
  }
};
export default navigationReducer;
