import React, { useEffect, useState } from "react";
import { Alert, Calendar, Select, Typography, Col, Row } from "antd";
import moment from "moment";
import "moment/locale/es";
import { calendarService } from "../../_helpers/services/";
import { NOTIFICATION } from "../../_helpers/constants";
import { connect } from "react-redux";
import { notifications } from "../../_redux/actions";
moment.locale("es");

const { Title } = Typography;

const View = (props) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true)

  async function loadEvents() {
    let message;
    let res = await calendarService.fetch();
    if (res.status === 200) {
      // Process reciveid data
      setEvents(res.data);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado eventos obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    //props.showNotification(message);
    setLoading(false);
    setFirstLoad(false)
  }

  useEffect(() => {
    if (events.length === 0 && props.isAuthenticated && firstLoad) {
      loadEvents();
    }
  }, [events]);

  const getListData = (date) => {
    let listData;
    if (events.length > 0) {
      listData = events[date.month()].filter((event) => {
        return (
          moment(event.due_date).format(moment.HTML5_FMT.DATE) ===
          date.format(moment.HTML5_FMT.DATE)
        );
      });
    }
    return listData || [];
  };

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      //<ul className="events">
      <>
        {listData.map((item) => {
          return (
            <Alert
              style={{ marginBottom: "2px", fontSize: "11px" }}
              key={item.name}
              message={item.name}
              type="info"
            />
          );
        })}
      </>
      //</ul>
    );
  }

  const customHeader = ({ value, type, onChange, onTypeChange }) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    const current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.months(current));
    }

    for (let index = start; index < end; index++) {
      monthOptions.push(
        <Select.Option className="month-item" key={`${index}`}>
          {months[index]}
        </Select.Option>
      );
    }
    const month = value.month();
    return (
      <div style={{ padding: 10 }}>
        <Row type="flex" justify="space-between">
          <Col>
            <Title style={{ marginBottom: "10px" }} type="secondary">
              Actividades con fecha de realización
            </Title>
          </Col>
          <Col>
            <Select
              size="large"
              dropdownMatchSelectWidth={false}
              value={String(month)}
              onChange={(selectedMonth) => {
                const newValue = value.clone();
                newValue.month(parseInt(selectedMonth, 10));
                onChange(newValue);
              }}
            >
              {monthOptions}
            </Select>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Calendar dateCellRender={dateCellRender} headerRender={customHeader} />
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
