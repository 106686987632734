import uuid from "uuid/v4";
import { notifications } from "../actions";
import { notification } from "antd";

let initialState = {
  messages: []
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notifications.types.ADD_NOTIFICATION:
      return Object.assign({}, state, {
        messages: [
          ...state.messages,
          {
            id: uuid(),
            title: action.payload.title,
            body: action.payload.body,
            type: action.payload.type,
            active: true
          }
        ]
      });
    case notifications.types.UPDATE_NOTIFICATION:
      return {
        messages: state.messages.map((message, index) => {
          if (message.id === action.payload.id) {
            return {
              id: action.payload.id,
              title: action.payload.title,
              body: action.payload.body,
              type: action.payload.type,
              active: false
            };
          }
          return message;
        })
      };
    case notifications.types.REMOVE_NOTIFICATION:
      return {
        messages: state.messages.filter(n => n.id !== action.payload)
      };
    case notifications.types.CLEAR_NOTIFICATIONS:
      return {
        messages: []
      };
    default:
      return state;
  }
};
export default notificationReducer;
