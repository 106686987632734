import React from "react";
import { Login, Main, UpdatePassword } from "../pages/";
import { Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LoginRoute from "./LoginRoute";
import { connect } from "react-redux";

const MainRouter = (props) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Main}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <LoginRoute
        exact
        path="/login"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Login}
      />
      <PrivateRoute
        exact
        path="/update-password"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={UpdatePassword}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      {/* Default routing*/}
      <PrivateRoute
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Main}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    updatePassword: state.auth.payload.update_password,
    isStaff: state.auth.payload.is_staff,
    isAdmin: state.auth.payload.is_admin,
    permissions: state.auth.payload.access_role
      ? state.auth.payload.access_role.permissions
      : [],
  };
};
export default connect(mapStateToProps)(MainRouter);
