import React from "react";
import { Layout, Badge, Avatar, Row, Col, Dropdown } from "antd";

const { Header } = Layout;

const CustomHeader = (props) => {
  return (
    <Header
      style={{
        background: "#fff",
        padding: 0,
        position: "fixed",
        zIndex: 1,
        width: "100%",
      }}
    >
      <Row type="flex" justify="end" align="middle">
        <Col span={24}>
          <div
            className="mr-20 avatar-button"
            style={{ position: "fixed", right: "10px" }}
          >
            <Dropdown overlay={props.avatarOptions} trigger={["click"]}>
              <Badge dot className="ant-dropdown-link">
                <Avatar icon="user" />
              </Badge>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Header>
  );
};

export default CustomHeader;
