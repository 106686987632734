import rest from "../rest";
import http from "../axios";
import {
  PLANNING_ENDPOINT_FILTERED_LIST,
  SUPERVISION_ENDPOINT,
} from "../constants";

export default {
  ...rest(SUPERVISION_ENDPOINT), //to get other endpoints
  get_planning_filtered: async (page = false) => {
    try {
      const pagination = page !== false ? `?page=${page}` : "/";
      const res = await http.get(
        `${PLANNING_ENDPOINT_FILTERED_LIST}${pagination}`
      );
      return res;
    } catch (e) {
      return e;
    }
  },
};
