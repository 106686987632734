import React from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import { navigation } from "../../_redux/actions/";

const { SubMenu } = Menu;
const SideMenu = (props) => {
  const onChangeSelectedOptionHandler = ({ item, key, keyPath, domEvent }) => {
    props.updateMenuPath(keyPath);
    props.updateMenuRoute(item.props.children.props.to);
  };
  let displayTitle = props.collapsed ? "none" : "inherit";
  const menuOptions = [
    {
      key: "m0",
      route: "/",
      className: "menu-item",
      icon: "pie-chart",
      title: "Dashboard",
      subitems: [],
    },
    {
      key: "m1",
      route: "/planning",
      className: "menu-item",
      icon: "appstore",
      title: "Planes operativos",
      subitems: [],
    },
    {
      key: "m2",
      route: "/supervision",
      className: "menu-item",
      icon: "bulb",
      title: "Seguimientos",
      subitems: [],
    },
    {
      key: "m2-a",
      route: "/calendar",
      className: "menu-item",
      icon: "calendar",
      title: "Calendario",
      subitems: [],
    },
    {
      key: "m3",
      route: "",
      className: "menu-item",
      icon: "setting",
      title: "Catálogos",
      subitems: [
        {
          key: "m3-0",
          route: "/user",
          className: "menu-item",
          icon: "user",
          title: "Usuarios",
        },
        {
          key: "m3-1",
          route: "/department",
          className: "menu-item",
          icon: "layout",
          title: "Departamentos",
        },
        {
          key: "m3-2",
          route: "/position",
          className: "menu-item",
          icon: "apartment",
          title: "Cargos",
        },
        {
          key: "m3-3",
          route: "/rol",
          className: "menu-item",
          icon: "block",
          title: "Rol administrativo",
        },
        {
          key: "m3-4",
          route: "/instrument-type",
          className: "menu-item",
          icon: "file-search",
          title: "Tipos de instrumento",
        },
        {
          key: "m3-5",
          route: "/instrument",
          className: "menu-item",
          icon: "file-done",
          title: "Instrumentos",
        },
        {
          key: "m3-6",
          route: "/tag",
          className: "menu-item",
          icon: "tags",
          title: "Etiquetas",
        },
        {
          key: "m3-7",
          route: "/period",
          className: "menu-item",
          icon: "calendar",
          title: "Periodos de evaluación",
        },
        {
          key: "m3-8",
          route: "/permission",
          className: "menu-item",
          icon: "warning",
          title: "Permisos de acceso",
        },
        {
          key: "m3-9",
          route: "/accessrole",
          className: "menu-item",
          icon: "lock",
          title: "Perfiles de acceso",
        },
      ],
    },
    {
      key: "m3-a",
      route: "",
      className: "menu-item",
      icon: "snippets",
      title: "Reportes",
      subitems: [
        {
          key: "m3-a1",
          route: "/report-achievement-user",
          className: "menu-item",
          icon: "file-text",
          title: "Logro de objetivos",
        },
        {
          key: "m3-a2",
          route: "/report-incompleted-task",
          className: "menu-item",
          icon: "file-excel",
          title: "Actividades individuales",
        },
        // {
        //   key: "m3-a3",
        //   route: "/report-global-planning",
        //   className: "menu-item",
        //   icon: "file-text",
        //   title: "Reporte General por periodo",
        // },
      ],
    },
  ];

  return (
    <>
      <div className="logo">
        <Icon type="ant-design" />
        <span
          style={{
            fontSize: "16px",
            marginLeft: "5px",
            margiBottom: "10px",
            display: displayTitle,
          }}
        >
          Sistema de Gestión de POA
        </span>
      </div>

      <MenuItems
        options={menuOptions}
        onChangeSelectedOptionHandler={onChangeSelectedOptionHandler}
        keyPath={props.keyPath}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
    </>
  );
};

const MenuItems = (props) => {
  const {
    options,
    onChangeSelectedOptionHandler,
    keyPath,
    isAdmin,
    permissions,
  } = props;
  let renderOptions = isAdmin
    ? renderOptionsForAdmin(options)
    : renderOptionsForNonAdminUsers(options, permissions);
  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={keyPath}
      defaultOpenKeys={keyPath}
      mode="inline"
      onClick={onChangeSelectedOptionHandler}
    >
      {renderOptions}
    </Menu>
  );
};

const renderOptionsForAdmin = (options) => {
  return options.map((option) => {
    if (option.subitems.length === 0) {
      return (
        <Menu.Item key={option.key}>
          <Link to={option.route} className={option.className}>
            <Icon type={option.icon} />
            <span>{option.title}</span>
          </Link>
        </Menu.Item>
      );
    } else {
      return (
        <SubMenu
          key={option.key}
          title={
            <span>
              <Icon type={option.icon} />
              <span>{option.title}</span>
            </span>
          }
        >
          {option.subitems.map((item) => {
            return (
              <Menu.Item key={item.key}>
                <Link to={item.route} className={item.className}>
                  <Icon type={item.icon} />
                  <span>{item.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
  });
};
const renderOptionsForNonAdminUsers = (options, permissions) => {
  let permission_list = permissions.map((permission) => permission.route);
  return options.map((option) => {
    if (option.subitems.length === 0) {
      if (permission_list.includes(option.route)) {
        return (
          <Menu.Item key={option.key}>
            <Link to={option.route} className={option.className}>
              <Icon type={option.icon} />
              <span>{option.title}</span>
            </Link>
          </Menu.Item>
        );
      } else {
        return;
      }
    } else {
      return (
        <SubMenu
          key={option.key}
          title={
            <span>
              <Icon type={option.icon} />
              <span>{option.title}</span>
            </span>
          }
        >
          {option.subitems.map((item) => {
            if (permission_list.includes(item.route)) {
              return (
                <Menu.Item key={item.key}>
                  <Link to={item.route} className={item.className}>
                    <Icon type={item.icon} />
                    <span>{item.title}</span>
                  </Link>
                </Menu.Item>
              );
            } else {
              return;
            }
          })}
        </SubMenu>
      );
    }
  });
};

const mapStateToProps = (state) => {
  return {
    keyPath: state.navigation.keyPath,
    isAdmin: state.auth.payload.is_admin,
    permissions: state.auth.payload.access_role.permissions,
  };
};

const mapDispatchToProps = {
  updateMenuPath: navigation.updateMenuPath,
  updateMenuRoute: navigation.updateMenuRoute,
};
export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
