import rest from "../rest";
import http from "../axios";
import {
  USER_ENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
  USER_UPDATE_STATUS_ENDPOINT,
} from "../constants";

export default {
  ...rest(USER_ENDPOINT), //to get other endpoints
  update_password: async (id, data) => {
    try {
      return await http.post(
        `${USER_ENDPOINT}/${UPDATE_PASSWORD_ENDPOINT}/${id}/`,
        data
      );
    } catch (e) {
      return e;
    }
  },
  update_status: async (data) => {
    try {
      return await http.post(`${USER_UPDATE_STATUS_ENDPOINT}/`, data);
    } catch (e) {
      return e;
    }
  },
};
