import rest from "../rest";
import http from "../axios";
import {
  OBJECTIVE_ENDPOINT,
  OBJECTIVE_UPDATE_PERCENTAGE_ENDPOINT,
  OBJECTIVE_UPDATE_PER_FIELD,
  OBJECTIVES_BY_PERIOD,
  UPLOAD_OBJECTIVE_FILE,
  OBJECTIVE_GET_ALL,
  OBJECTIVE_GET_LIST,
} from "../constants";

export default {
  ...rest(OBJECTIVE_ENDPOINT), //to get other endpoints
  get_by_filter: async (data) => {
    try {
      const res = await http.post(OBJECTIVES_BY_PERIOD, data);
      return res;
    } catch (e) {
      return e;
    }
  },
  update_percentage: async (data) => {
    try {
      const res = await http.post(OBJECTIVE_UPDATE_PERCENTAGE_ENDPOINT, data);
      return res;
    } catch (e) {
      return e;
    }
  },
  update_per_field: async (data) => {
    try {
      const res = await http.post(OBJECTIVE_UPDATE_PER_FIELD, data);
      return res;
    } catch (e) {
      return e;
    }
  },
  upload_objective_file: async (data) => {
    try {
      const res = await http.post(UPLOAD_OBJECTIVE_FILE, data);
      return res;
    } catch (e) {
      return e;
    }
  },
  get_all: async (data) => {
    try {
      const res = await http.post(OBJECTIVE_GET_ALL, data);
      return res;
    } catch (e) {
      return e;
    }
  },
  get_list: async (data) => {
    try {
      const res = await http.post(OBJECTIVE_GET_LIST, data);
      return res;
    } catch (e) {
      return e;
    }
  },
};
