import { auth } from "../actions/";

let initialState = {
  payload: {},
  isAuthenticated: false
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case auth.types.LOGGED_IN:
      return {
        ...state,
        payload: action.payload,
        isAuthenticated: true
      };
    case auth.types.LOGGED_OUT:
      return {
        ...state,
        payload: {},
        isAuthenticated: false
      };
    case auth.types.PASSWORD_UPDATED:
      return {
        ...state,
        payload: {
          ...state.payload,
          update_password: false
        }
      };
    default:
      return state;
  }
};
export default authenticationReducer;
