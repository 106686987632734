import React from "react";
import {
  Department,
  Dashboard,
  Position,
  Rol,
  InstrumentType,
  Instrument,
  User,
  Planning,
  Tag,
  Period,
  Permission,
  AccessRole,
  Supervision,
  Calendar,
  Report,
} from "../pages/";
import { Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { connect } from "react-redux";

const PrivatedRouter = (props) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/report-global-planning"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Report.GlobalTask}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/report-incompleted-task"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Report.IncompletedTask}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/report-achievement-user"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Report.PersonalAchievement}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/calendar"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Calendar.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/supervision"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Supervision.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/accessrole"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={AccessRole.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/permission"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Permission.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/period"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Period.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/tag"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Tag.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/planning/:id"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Planning.Detail}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/planning"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Planning.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/user"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={User.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/instrument"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Instrument.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/instrument-type"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={InstrumentType.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/department"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Department.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/position"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Position.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/rol"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Rol.View}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
      <PrivateRoute
        exact
        path="/"
        isAuthenticated={props.isAuthenticated}
        updatePassword={props.updatePassword}
        component={Dashboard}
        isAdmin={props.isAdmin}
        permissions={props.permissions}
      />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    updatePassword: state.auth.payload.update_password,
    isStaff: state.auth.payload.is_staff,
    isAdmin: state.auth.payload.is_admin,
    permissions: state.auth.payload.access_role.permissions,
  };
};
export default connect(mapStateToProps)(PrivatedRouter);
