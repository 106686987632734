import rest from "../rest";
import http from "../axios";
import {
  ACCESS_ROLE_ENDPOINT,
  ACCESS_ROLE_UPDATE_PERMISSION_ENDPOINT,
} from "../constants";

export default {
  ...rest(ACCESS_ROLE_ENDPOINT), //to get other endpoints
  update_permission: async (data) => {
    try {
      const res = await http.post(ACCESS_ROLE_UPDATE_PERMISSION_ENDPOINT, data);
      return res;
    } catch (e) {
      return e;
    }
  },
};
