export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
export const NAMESPACE_URL = "api/v1/";
export const TIMEZONE = "America/El_Salvador";
export const VALIDATE_CODE = "api/v1/validate-code/";
export const LOGIN_ENDPOINT = "token/";
export const UPDATE_PASSWORD_ENDPOINT = "update_password";
export const PASSWORD_RESET_REQUEST_ENDPOINT = "password_reset_request/";
export const DEPARTMENT_ENDPOINT = "department";
export const POSITION_ENDPOINT = "position";
export const ROL_ENDPOINT = "rol";
export const INSTRUMENTTYPE_ENDPOINT = "instrument_type";
export const INSTRUMENT_ENDPOINT = "instrument";
export const PLANNING_ENDPOINT = "planning";
export const PLANNING_ENDPOINT_FILTERED_LIST = "planning/filtered_list";
export const OBJECTIVE_ENDPOINT = "objective";
export const OBJECTIVE_UPDATE_PERCENTAGE_ENDPOINT =
  "objective/update_percentage";
export const OBJECTIVE_UPDATE_PER_FIELD = "objective/update_per_field";
export const OBJECTIVE_GET_ALL = "objective/get_all";
export const OBJECTIVE_GET_LIST = "objective/get_list";
export const TAG_ENDPOINT = "tag";
export const TAG_REMOVE_ENDPOINT = "tag/remove/";
export const TAG_ADD_ENDPOINT = "tag/add/";
export const PERIOD_ENDPOINT = "period";
export const PERIOD_FULL_LIST_ENDPOINT = "period/full_list";
export const PERIOD_FOLLOWUP_ENDPOINT = "period/followup";
export const PERMISSION_ENDPOINT = "permission";
export const ACCESS_ROLE_ENDPOINT = "accessrole";
export const ACCESS_ROLE_UPDATE_PERMISSION_ENDPOINT =
  "accessrole/update_permission";
export const USER_ENDPOINT = "user";
export const USER_PROFILE_ENDPOINT = "user/profile";
export const USER_PROCESS_OWNER_ENDPOINT = "user/supervisor";
export const USER_STATUS_ENDPOINT = "userstatus";
export const USER_LOG_REASON_ENDPOINT = "userlogreason";
export const USER_UPDATE_STATUS_ENDPOINT = "user/update_status";
export const SUPERVISION_ENDPOINT = "supervision";
export const UPLOAD_OBJECTIVE_FILE = "upload_objective_file/";
export const OBJECTIVES_BY_PERIOD = "objectives_by_period/";
export const REPORT_PERSONAL_ACHIEVEMENT = "report_personal_achievement/";
export const REPORT_INCOMPLETED_TASK = "report_incompleted_task/";
export const REPORT_GLOBAL_TASK = "report_global_task/";
export const CALENDAR_ENDPOINT = "calendar_events";
export const IMAGES_FILETYPE = ["gif", "png", "jpg", "jpeg"];
export const DOCS_FILETYPE = ["doc", "docx", "pdf"];
export const NOTIFICATION = {
  TYPES: {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    ERROR: "error",
  },
};
export const EVALUATION_PERIODICITY = [
  {
    id: 1,
    name: "Diaria",
  },
  {
    id: 2,
    name: "Semanal",
  },
  {
    id: 3,
    name: "Quincenal",
  },
  {
    id: 4,
    name: "Mensual",
  },
  {
    id: 5,
    name: "Única por periodo",
  },
];

export const PERIOD_FOLLOW_UP_STATUS = [
  {
    id: 0,
    name: "Pendiente",
  },
  {
    id: 1,
    name: "Completado",
  },
  {
    id: 2,
    name: "Parcialmente completado",
  },
  {
    id: 3,
    name: "No completado",
  },
];

export const OBJECTIVE_LEVELS = [
  {
    id: 1,
    name: "ENFOQUE",
  },
  {
    id: 2,
    name: "OBJETIVO ESTRATEGICO",
  },
  {
    id: 3,
    name: "ACTVIIDAD MACRO",
  },
  {
    id: 4,
    name: "ACTIVIDAD CLAVE",
  },
  {
    id: 5,
    name: "SUBACTIVDAD 1",
  },
  {
    id: 6,
    name: "SUBACTIVIDAD 2",
  },
  {
    id: 7,
    name: "SUBACTIVIDAD 3",
  },
];
//export const COLORS = ['#fafafa', '#abc4ff', '#b6ccfe', '#c1d3fe', '#ccdbfd', '#d7e3fc', '#e2eafc', '#edf2fb']
//export const COLORS = ['#fafafa', '#cddafd', '#dfe7fd', '#f0efeb', '#bee1e6', '#e2ece9', '#fad2e1', '#fde2e4','#fff1e6','#eae4e9']
//export const COLORS = ['#fafafa', '#deeae8', '#cddcdf', '#f3e7e3', '#f8eeea', '#f5d3c5', '#f1e2d7', '#f9f9f7','#f8d5c2', '#e2eafc']
export const COLORS = ['#ed7a23', '#2bb8ed', '#598eff', '#8058ff', '#194180', '#296fa4', '#28a9d9', '#25b8c4','#3a8d53', '#30ac55']