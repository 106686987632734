import React from "react";
import {
  Row,
  Col,
  Input,
  Alert,
  Icon,
  Button,
  Form,
  Select,
  Modal,
  Radio
} from "antd";
const FormLogin = props => {
  return (
    <Row type="flex" justify="center" align="middle">
      <form className="" autoComplete="off">
        <Col span={24} className="mb-20">
          <Input
            placeholder="Ingrese su usuario"
            name="username"
            value={props.values.username || ""}
            onChange={props.onChangeHandler}
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
          />
          {props.errors.username && (
            <Alert
              message={`${props.errors.username}`}
              type="error"
              showIcon
              className="red-alert"
            />
          )}
        </Col>
        <Col span={24} className="mb-20">
          <Input.Password
            className={`${props.errors.password && "alert-validate"}`}
            data-validate="Password is required"
            placeholder="Ingrese su contraseña"
            name="password"
            value={props.values.password || ""}
            onChange={props.onChangeHandler}
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          />
          {props.errors.password && (
            <Alert
              message={`${props.errors.password}`}
              type="error"
              showIcon
              className="red-alert"
            />
          )}
        </Col>
        <Col span={24} className="mb-10 center">
          <Button
            size="large"
            type="primary"
            icon="right"
            loading={props.isLoading}
            htmlType="submit"
            onClick={e => {
              props.onSubmitHandler(e);
            }}
          >
            Iniciar sesión
          </Button>
        </Col>
        <Col span={24} className="mb-10 right">
          <RestorePasswordRequestModal
            passwordResetRequest={props.passwordResetRequest}
          />
        </Col>
      </form>
    </Row>
  );
};

// ************************ COMPONENT TO SHOW MODAL WITH FORM ************************************
class RestorePasswordRequestModal extends React.Component {
  state = {
    visible: false,
    loading: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({ ...this.state, loading: true });
      let error = await this.props.passwordResetRequest(values);
      if (!error) {
        form.resetFields();
        this.setState({ loading: false, visible: false });
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div>
        <Button type="link" onClick={this.showModal}>
          ¿Recuperar contraseña?
        </Button>
        <RestorePasswordRequestForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

const RestorePasswordRequestForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form, loading } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          confirmLoading={loading}
          title="Restablecimiento de contraseña"
          okText="Solicitar"
          cancelText="Cancelar"
          onCancel={onCancel}
          onOk={onCreate}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Form.Item label="Usuario para restablecer contraseña">
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    pattern: /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/,
                    message: "Debe ingresar un usuario válido",
                    whitespace: true
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

export default FormLogin;
