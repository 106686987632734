import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { notifications } from "../../_redux/actions";
import {
  Row,
  Col,
  Typography,
  Select,
  Tag,
  Button,
  Empty,
  Table,
  Spin,
  Progress,
} from "antd";
import {
  periodService,
  reportService,
  departmentService,
} from "../../_helpers/services/";
import {
  NOTIFICATION,
  TIMEZONE,
  OBJECTIVE_LEVELS,
} from "../../_helpers/constants";
import moment from "moment-timezone";
import ReactPDF, {
  Page,
  Text as TextPDF,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../_images/logo_cgf.png";

const { Title } = Typography;
const { Option } = Select;

const GlobalTask = (props) => {
  const [periodList, setPeriodList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [periodSelected, setPeriodSelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  async function loadPeriods() {
    let message;
    let res = await periodService.fetch();
    if (res.status === 200) {
      // Process reciveid data
      setPeriodList(res.data);
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadDeparments() {
    let message;
    let res = await departmentService.fetch();
    if (res.status === 200) {
      // Process reciveid data
      setDepartmentList(res.data);
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  useEffect(() => {
    if (periodList.length === 0 && firstLoad && props.isAuthenticated) {
      loadPeriods();
      loadDeparments();
      setFirstLoad(false);
    }
  }, [periodList]);

  const handleChangePeriod = (value) => {
    if (value === undefined) {
      setPeriodSelected(null);
      setResults([]);
    } else {
      setPeriodSelected(value);
    }
  };

  const handleChangeDepartment = (value) => {
    if (value === undefined) {
      setDepartmentSelected(null);
    } else {
      setDepartmentSelected(value);
    }
  };

  const handleGenerateReport = async () => {
    if (periodSelected === null) {
      let message = {
        title: "COMPLETAR FILTRO",
        body: "Debe seleccionar el periodo",
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    } else {
      setLoading(true);
      let data = {};
      if (departmentSelected !== null) {
        data = {
          period: periodSelected,
          department: departmentSelected,
        };
      } else {
        data = {
          period: periodSelected,
        };
      }

      let res = await reportService.global_task(data);
      setResults(res.data);
      setLoading(false);
    }
  };

  return (
    <>
      <Row type="flex" justify="center">
        <Col span={24}>
          <Title type="secondary">Reporte General de Actividades</Title>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "right", marginBottom: "5px" }}>
          <PDFDownloadLink
            document={<ListItemToPrint dataSource={results} />}
            fileName={`reporte general ${moment().format(
              "YYYY-MM-DD HH:mm:ss"
            )}.pdf`}
          >
            {({ blob, url, loading, error }) => {
              return (
                <Button
                  type={"primary"}
                  shape="round"
                  size="large"
                  icon={"printer"}
                  style={{ float: "right" }}
                >
                  Descargar reporte
                </Button>
              );
            }}
          </PDFDownloadLink>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={8}>
          <Select
            showSearch
            style={{ width: "100%", marginBottom: "20px" }}
            placeholder="Seleccione un periodo"
            optionFilterProp="children"
            optionLabelProp="title"
            onChange={handleChangePeriod}
            allowClear={true}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {periodList.map((period) => {
              return (
                <Option key={period.id} value={period.id} title={period.name}>
                  <Tag color="blue">
                    {moment(period.start_date)
                      .tz(TIMEZONE)
                      .format("DD/MM/YYYY")}
                  </Tag>{" "}
                  -{" "}
                  <Tag color="blue">
                    {moment(period.end_date).tz(TIMEZONE).format("DD/MM/YYYY")}
                  </Tag>
                  <span>{period.name}</span>
                </Option>
              );
            })}
          </Select>
        </Col>
        {props.isReportManager && (
          <Col span={8}>
            <Select
              showSearch
              style={{
                width: "100%",
                marginBottom: "20px",
                paddingLeft: "10px",
              }}
              placeholder="Seleccione un departamento"
              optionFilterProp="children"
              optionLabelProp="title"
              onChange={handleChangeDepartment}
              allowClear={true}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {departmentList.map((department) => {
                return (
                  <Option
                    key={department.id}
                    value={department.id}
                    title={department.name}
                  >
                    {department.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        )}
      </Row>
      <Row type="flex">
        <Col span={6}>
          <Button
            type="primary"
            shape="round"
            size="large"
            icon="plus-circle"
            style={{ marginBottom: "20px" }}
            onClick={handleGenerateReport}
          >
            Generar reporte
          </Button>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <NestedTable results={results} loading={loading} />
        </Col>
      </Row>
    </>
  );
};

// Component for nested table render
const NestedTable = (props) => {
  const { results, loading } = props;
  const expandedRowRender = (record, index, indent, expanded) => {
    const data = [];
    const rows = results.filter((row) => {
      return row.user === record.user;
    });

    rows.forEach((row) => {
      if (row.objectives.length > 0) {
        row.objectives.forEach((objective) => {
          data.push({
            id: objective.id,
            name: objective.name,
            achievement: objective.achievement,
          });
        });
      }
    });
    const columns = [
      { title: "Objetivos del periodo", dataIndex: "name" },
      {
        title: "% del objetivo",
        dataIndex: "achievement",
        align: "center",
        render: (total) => {
          return (
            <Progress
              percent={total}
              style={{ paddingLeft: 10, paddingRight: 10 }}
              size="small"
            />
          );
        },
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
      />
    );
  };

  // For main table
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      width: "50px",
      align: "center",
      editable: false,
      key: "key",
      sorter: (a, b) => a.key - b.key,
    },
    { title: "Usuario", dataIndex: "user", key: "user" },
    {
      title: "Porcentaje de logro del período",
      dataIndex: "total_achievement",
      key: "total_achievement",
      align: "center",
      render: (total) => {
        return <Progress type="circle" percent={total} width={80} />;
      },
    },
    {
      title: "Departamento",
      dataIndex: "department",
      key: "department",
    },
  ];

  return (
    <>
      {loading && (
        <Row type="flex" justify="center">
          <Col span={2}>
            <Spin
              tip="Generando reporte ..."
              size="large"
              style={{ textAlign: "center" }}
            ></Spin>
          </Col>
        </Row>
      )}
      {results.length === 0 && !loading && (
        <Empty
          image={Empty.PRESENTED_IMAGE_DEFAULT}
          description={
            "No hay resultados para mostrar, intente ajustando los filtros."
          }
        />
      )}

      {results.length > 0 && !loading && (
        <Table
          rowKey="user_id"
          className="components-table-demo-nested"
          columns={columns}
          expandedRowRender={expandedRowRender}
          dataSource={results}
        />
      )}
    </>
  );
};

// ***********************************************************************************************/
// ******************* COMPONENT TO SHOW LIST TO PRINT        ************************************/
// ***********************************************************************************************/
class ListItemToPrint extends React.Component {
  styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
      fontSize: 12,
    },
    section: {
      flexGrow: 1,
      marginBottom: 10,
    },
    children: {
      marginLeft: 25,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    row: {
      marginTop: 15,
    },
    tag: {
      color: "#1890ff",
      backgroundColor: "#e6f7ff",
      borderColor: "#91d5ff",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 10,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    releaseDate: {
      position: "absolute",
      fontSize: 10,
      bottom: 30,
      left: 0,
      right: 35,
      textAlign: "right",
      color: "grey",
    },
  });
  getItemList = (item, parent, level) => {
    let itemRow;
    let objective_level = OBJECTIVE_LEVELS.filter((obj) => obj.id === level);

    if (item.children.length > 0) {
      itemRow = (
        <View key={item.id} style={this.styles.section}>
          <View>
            {objective_level.length > 0 && (
              <TextPDF style={this.styles.tag}>
                {objective_level[0].name}
              </TextPDF>
            )}
            <TextPDF>{item.name}</TextPDF>
          </View>
          <View style={this.styles.children}>
            {this.getList(item.children, "b" + item.id, level + 1)}
          </View>
        </View>
      );
    } else {
      itemRow = (
        <View key={item.id} style={this.styles.section}>
          {objective_level.length > 0 && (
            <TextPDF style={this.styles.tag}>{objective_level[0].name}</TextPDF>
          )}
          <TextPDF>{item.name}</TextPDF>
        </View>
      );
    }

    return itemRow;
  };

  getList = (dataSource, parent, level = 1) => {
    let list = dataSource.map((item) => {
      return this.getItemList(item, "b" + item.id, level);
    });

    return list;
  };

  render() {
    const { dataSource } = this.props;
    return (
      <Document>
        <Page style={this.styles.page} orientation="landscape">
          <View style={this.styles.section}>{this.getList(dataSource, 0)}</View>

          <TextPDF
            style={this.styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />

          <TextPDF
            style={this.styles.releaseDate}
            render={({ pageNumber, totalPages }) =>
              `Fecha: ${moment().format("YYYY-MM-DD")}`
            }
            fixed
          />
        </Page>
      </Document>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isReportManager: state.auth.payload.is_report_manager,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(GlobalTask);
