import axios from "axios";
import { BASE_URL, NAMESPACE_URL } from "./constants";
import { authenticationService } from "./services";

const http = axios.create({
  baseURL: BASE_URL + NAMESPACE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(function (config) {
  let auth = JSON.parse(localStorage.getItem("persist:mngmt")).auth;
  auth = JSON.parse(auth);
  const token = auth.payload.access_token || null;
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

// http.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response !== undefined) {
//       const { status, config } = error.response;
//       if (status === 401) {
//       }
//     }

//     return error;
//   }
// );

export default http;
