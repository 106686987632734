import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notifications } from "../../_redux/actions";
import history from "../../_helpers/history";
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Button,
  Divider,
  Row,
  Col,
  Tag,
  Modal,
  Radio,
  Select,
  Checkbox,
} from "antd";
import { planningService } from "../../_helpers/services/";
import { NOTIFICATION } from "../../_helpers/constants";

const data = [];
const EditableContext = React.createContext();
const { Option } = Select;

const View = (props) => {
  const [planningList, setPlanningList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [yearsAvailable, setYearsAvailable] = useState([
    <Option key={0}>Seleccione el año</Option>,
  ]);

  const { Title } = Typography;
  const EditableFormTable = Form.create()(EditableTable);

  const updateLoading = () => {
    setLoading(!loading);
  };

  async function savePlanning(values) {
    let message;
    let msg;
    let msg_error;
    let error = false;
    let res = await planningService.insert(values);

    if (res.status === 201) {
      message = {
        title: "Nuevo Plan Operativo creado",
        body: `El plan: ${values.name}, ha sido agregado exitosamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      try {
        msg_error = JSON.parse(res.request.response);
      } catch (error) {
        msg_error = res.request.response;
      }

      if (typeof msg_error === "object") msg_error = JSON.stringify(msg_error);
      if (msg_error.indexOf("year") !== -1) {
        msg = (
          <span>
            El <strong>año vigente</strong> ingresado ya esta registrado,
            seleccione uno diferente.
          </span>
        );
      } else {
        //default message
        msg = res.message;
      }

      message = {
        title: "ERROR AL GUARDAR",
        body: msg,
        type: NOTIFICATION.TYPES.ERROR,
      };
      error = true;
    }

    props.showNotification(message);

    if (!error) {
      //clear table data source
      data.splice(0, data.length);
      loadPlannings();
      updateLoading();
    }
    return error;
  }

  async function updateStatusPlanning(item) {
    let message;
    item.active = !item.status || false;

    let res = await planningService.update(item.id, item);

    if (res.status === 200) {
      message = {
        title: "Actualización exitosa ",
        body: `El plan: ${item.name} se ha ${
          item.status ? "Desactivado" : "Activado"
        } correctamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      let msg_error = "";

      try {
        msg_error = JSON.parse(res.request.response);
        msg_error = msg_error.error_exception || msg_error;
      } catch (error) {
        msg_error = res.message;
      }

      message = {
        title: "ERROR AL ACTUALIZAR ESTADO",
        body: msg_error,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    //clear table data source
    data.splice(0, data.length);
    loadPlannings();
    updateLoading();
  }

  async function updatePlannings(item, row) {
    let message;
    let msg_error;
    let msg;
    let data = item;
    data.name = row.name;
    data.description = row.description;
    data.year = row.year;
    data.current = row.current;

    let res = await planningService.update(data.id, data);

    if (res.status === 200) {
      message = {
        title: `Actualización exitosa`,
        body: `La información del plan: ${data.name}, se actualizó correctamente`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      try {
        msg_error = JSON.parse(res.request.response);
      } catch (error) {
        msg_error = res.request.response;
      }
      if (typeof msg_error === "object") msg_error = JSON.stringify(msg_error);
      if (msg_error.indexOf("year") !== -1) {
        msg = (
          <span>
            El <strong>año vigente</strong> ingresado ya esta registrado,
            seleccione uno diferente.
          </span>
        );
      } else if (msg_error.indexOf("el registro esta desactivado.") !== -1) {
        msg = <span>{JSON.parse(msg_error).error_exception}</span>;
      } else {
        //default message
        msg = res.message;
      }

      message = {
        title: "ERROR AL GUARDAR",
        body: msg,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    loadPlannings();
    updateLoading();
  }

  async function loadPlannings() {
    let message;
    let res = await planningService.fetch();
    if (res.status === 200) {
      // Process reciveid data
      setPlanningList(res.data);

      data.splice(0, data.length);
      res.data.forEach((planning, idx) => {
        data.push({
          key: idx + 1,
          id: planning.id,
          name: planning.name,
          description: planning.description,
          current: planning.current,
          status: planning.active,
          year: planning.year,
        });
      });

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} planes obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    setLoading(false);
  }

  useEffect(() => {
    if (planningList.length === 0 && firstLoad && props.isAuthenticated) {
      const years = [];
      let currentDate = new Date();
      let minYear = currentDate.getFullYear() - 3;
      let maxYear = currentDate.getFullYear() + 5;

      for (let i = minYear; i <= maxYear; i++) {
        years.push(<Option key={i}>{i}</Option>);
      }

      setYearsAvailable(years);
      loadPlannings();
      setFirstLoad(false);
    }
  }, [planningList]);

  return (
    <>
      <Row type="flex" justify="center">
        <Col span={16}>
          <Title type="secondary">Listado de Planes Operativos</Title>
        </Col>
        <Col span={8}>
          <PlanningCreateModal
            save={savePlanning}
            yearsAvailable={yearsAvailable}
            isAdmin={props.isAdmin}
          />
        </Col>
      </Row>
      <EditableFormTable
        loading={loading}
        update={updatePlannings}
        updateStatus={updateStatusPlanning}
        updateLoading={updateLoading}
        yearsAvailable={yearsAvailable}
        isAdmin={props.isAdmin}
      />
    </>
  );
};

// ************************ COMPONENT TO SHOW MODAL WITH FORM ************************************
class PlanningCreateModal extends React.Component {
  state = {
    visible: false,
    loading: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({ ...this.state, loading: true });
      let error = await this.props.save(values);
      if (!error) {
        form.resetFields();
        this.setState({ loading: false, visible: false });
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div>
        {this.props.isAdmin && (
          <Button
            type="primary"
            shape="round"
            size="large"
            icon="plus-circle"
            style={{ float: "right" }}
            onClick={this.showModal}
          >
            Agregar Plan
          </Button>
        )}
        <PlanningCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          loading={this.state.loading}
          yearsAvailable={this.props.yearsAvailable}
        />
      </div>
    );
  }
}

const PlanningCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        loading,
        yearsAvailable,
      } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          confirmLoading={loading}
          title="Agregar un nuevo plan"
          okText="Guardar"
          cancelText="Cancelar"
          onCancel={onCancel}
          onOk={onCreate}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Form.Item label="Nombre del plan">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Debe ingresar el nombre del plan",
                    whitespace: true,
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Descripción del plan">
              {getFieldDecorator("description")(<Input type="textarea" />)}
            </Form.Item>
            <Form.Item label="Año vigente">
              {getFieldDecorator("year", {
                initialValue: new Date().getFullYear(),
              })(<Select>{yearsAvailable}</Select>)}
            </Form.Item>
            <Form.Item
              label="¿Plan vigente?"
              className="collection-create-form_last-form-item"
            >
              {getFieldDecorator("current", {
                initialValue: true,
              })(
                <Radio.Group>
                  <Radio value={true}>SI</Radio>
                  <Radio value={false}>NO</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item className="collection-create-form_last-form-item">
              {getFieldDecorator("active", {
                initialValue: true,
              })(
                <Radio.Group>
                  <Radio value={true}>Activo</Radio>
                  <Radio value={false}>Inactivo</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

// ************************ COMPONENT TO RENDER TABLE ********************************************
class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data, editingKey: "" };
    this.columns = [
      {
        title: "#",
        dataIndex: "key",
        width: "5%",
        align: "center",
        editable: false,
        sorter: (a, b) => a.key - b.key,
      },
      {
        title: "Plan operativo",
        dataIndex: "name",
        editable: true,
        sorter: (a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          // names must be equal
          return 0;
        },
      },
      {
        title: "Descripción",
        dataIndex: "description",
        editable: true,
        sorter: (a, b) => {
          if (a.description < b.description) {
            return -1;
          }
          if (a.description > b.description) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Año",
        dataIndex: "year",
        editable: true,
        align: "center",
        render: (year) => <Tag color="geekblue">{year}</Tag>,
        sorter: (a, b) => {
          if (a.year < b.year) {
            return -1;
          }
          if (a.year > b.year) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Plan vigente",
        dataIndex: "current",
        editable: true,
        align: "center",
        render: (current) => (
          <Tag color={current ? "blue" : "magenta"}>
            {current ? "SI" : "NO"}
          </Tag>
        ),
        sorter: (a, b) => a.current - b.current,
      },
      {
        title: "Estado",
        dataIndex: "status",
        editable: false,
        align: "center",
        render: (status) => (
          <Tag color={status ? "blue" : "magenta"}>
            {status ? "Activo" : "Inactivo"}
          </Tag>
        ),
        sorter: (a, b) => a.status - b.status,
      },
      {
        title: "Acciones",
        dataIndex: "operation",
        width: this.props.isAdmin ? "320px" : "100px",
        align: this.props.isAdmin ? "right" : "center",

        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    type="primary"
                    icon="save"
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    Guardar
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="¿Seguro que deseas cancelar?"
                onConfirm={() => this.cancel(record.key)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  ghost
                  type="danger"
                  icon="close-circle"
                  style={{ marginRight: 8 }}
                >
                  Cancelar
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <>
              {this.props.isAdmin && (
                <>
                  <Button
                    type="primary"
                    icon="edit"
                    ghost
                    onClick={() => this.edit(record.key)}
                    disabled={editingKey !== ""}
                  >
                    Editar
                  </Button>
                  <Divider type="vertical" />
                  <Popconfirm
                    title={` ¿Seguro que deseas ${
                      record.status ? "desactivar" : "activar"
                    } este planning?`}
                    onConfirm={() => this.updateStatus(record.key)}
                    okText="Si"
                    cancelText="No"
                  >
                    <Button
                      type={record.status ? "danger" : "primary"}
                      icon={record.status ? "close" : "check"}
                      ghost
                      disabled={editingKey !== ""}
                    >
                      {record.status ? "Desactivar" : "Activar"}
                    </Button>
                  </Popconfirm>
                  <Divider type="vertical" />{" "}
                </>
              )}
              <Button
                type="primary"
                icon="file-done"
                ghost
                onClick={() => history.push(`planning/${record.id}`)}
                disabled={editingKey !== ""}
              />
            </>
          );
        },
      },
    ];
  }

  isEditing = (record) => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  updateStatus = (key) => {
    const newData = [...this.state.data];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
      });
      this.setState({ editingKey: "" });

      //activate/deactivate register
      this.props.updateStatus(item);
    }
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: "" });

        //update register
        this.props.update(item, row);
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType:
            col.dataIndex === "year"
              ? "select"
              : col.dataIndex === "current"
              ? "checkbox"
              : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          yearsAvailable: this.props.yearsAvailable,
        }),
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          bordered
          dataSource={this.state.data}
          loading={this.props.loading}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            onChange: this.cancel,
            showSizeChanger: true,
            locale: { items_per_page: "/ página" },
            pageSizeOptions: ["10", "25", "50", "100"],
          }}
        />
      </EditableContext.Provider>
    );
  }
}

// ************************ COMPONENT TO MAKE EDITABLE CELLS *************************************
class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "select") {
      return (
        <Select style={{ width: "80px" }}>{this.props.yearsAvailable}</Select>
      );
    } else if (this.props.inputType === "checkbox") {
      return <Checkbox />;
    } else return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      yearsAvailable,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {inputType === "text" &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                    whitespace: true,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}
            {inputType === "select" &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}

            {inputType === "checkbox" &&
              getFieldDecorator(dataIndex, {
                valuePropName: "checked",
                initialValue: record[dataIndex],
              })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.auth.payload.is_admin,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
