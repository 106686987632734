export const types = {
  UPDATE_MENU_PATH: "UPDATE_MENU_PATH",
  UPDATE_MENU_ROUTE: "UPDATE_MENU_ROUTE",
  LOGOUT: "LOGOUT",
};

export const updateMenuPath = (data) => {
  return {
    type: types.UPDATE_MENU_PATH,
    payload: data,
  };
};

export const updateMenuRoute = (data) => {
  return {
    type: types.UPDATE_MENU_ROUTE,
    payload: data,
  };
};

export const logout = () => {
  return {
    type: types.LOGOUT,
  };
};
