import rest from "../rest";
import http from "../axios";
import { PERIOD_ENDPOINT, PERIOD_FULL_LIST_ENDPOINT } from "../constants";

export default {
  ...rest(PERIOD_ENDPOINT), //to get other endpoints
  get_list: async () => {
    try {
      const res = await http.get(PERIOD_FULL_LIST_ENDPOINT);
      return res;
    } catch (e) {
      return e;
    }
  },
};
