import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { notifications } from "../../_redux/actions";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Button,
  Divider,
  Row,
  Col,
  Tag,
  Modal,
  Select,
  Tooltip,
  Badge,
  Icon,
  DatePicker,
  Checkbox,
} from "antd";
import {
  userService,
  positionService,
  rolService,
  accessRoleService,
  userStatusService,
  userLogReasonService,
  userProcessOwnerService,
} from "../../_helpers/services/";
import { NOTIFICATION, TIMEZONE } from "../../_helpers/constants";
import moment from "moment-timezone";

const data = [];
var csvData = [];
const EditableContext = React.createContext();
const { TextArea } = Input;
const { Option } = Select;
const disabledDate = (current = moment().tz(TIMEZONE)) => {
  return current && current > moment().tz(TIMEZONE).endOf("day");
};

const View = (props) => {
  const [userList, setUserList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [accessRoleList, setAccessRoleList] = useState([]);
  const [userStatusList, setUserStatusList] = useState([]);
  const [userLogReasonList, setUserLogReasonList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [positionOptions, setPositionOptions] = useState([
    <Option key={0}>Selecciona un cargo</Option>,
  ]);
  const [roleOptions, setRoleOptions] = useState([
    <Option key={0}>Selecciona un rol administrativo</Option>,
  ]);
  const [accessRoleOptions, setAccessRoleOptions] = useState([
    <Option key={0}>Selecciona un rol de acceso</Option>,
  ]);
  const [userStatusOptions, setUserStatusOptions] = useState([
    <Option key={0}>Selecciona estado</Option>,
  ]);
  const [userLogReasonOptions, setUserLogReasonOptions] = useState([
    <Option key={0}>Selecciona el motivo</Option>,
  ]);
  const [userProcessOwnerOptions, setUserProcessOwnerOptions] = useState([
    <Option key={0}>Selecciona el supervisor del usuario</Option>,
  ]);

  const { Title } = Typography;
  const EditableFormTable = Form.create()(EditableTable);

  const updateLoading = () => {
    setLoading(!loading);
  };

  async function saveUser(values) {
    let message;
    let error = false;
    let res = await userService.insert(values);

    if (res.status === 201) {
      message = {
        title: "Nuevo usuario creado",
        body: (
          <span>
            El usuario <strong>{values.first_name}</strong> ha sido agregado
            exitosamente.
          </span>
        ),
        type: NOTIFICATION.TYPES.SUCCESS,
      };

      //props.showNotification(message);

      Modal.warning({
        title: "Nuevo usuario creado",
        content: (
          <span>
            <br />
            La contraseña temporal para el usuario{" "}
            <strong>{values.email}</strong> es:
            <br />
            <strong>
              <Title type="danger" level={3}>
                {res.data.password}
              </Title>
            </strong>
          </span>
        ),
      });
    } else {
      let msg_error = "";
      let msg = "";
      try {
        msg_error = JSON.parse(res.request.response);
        msg_error = msg_error.error_exception || msg_error;
      } catch (error) {
        msg_error = res.request.response;
      }

      if (typeof msg_error === "object") msg_error = JSON.stringify(msg_error);
      if (msg_error.indexOf("telephone") !== -1) {
        msg = (
          <span>
            El <strong>número teléfonico</strong>ingresado ya esta registrado,
            ingrese uno diferente.
          </span>
        );
      } else if (msg_error.indexOf("user_code") !== -1) {
        msg = (
          <span>
            El <strong>código de usuario</strong> ingresado ya esta registrado,
            ingrese uno diferente.
          </span>
        );
      } else if (msg_error.indexOf("username") !== -1) {
        msg = (
          <span>
            El <strong>correo electrónico</strong> ingresado ya esta registrado,
            ingrese uno diferente.
          </span>
        );
      } else {
        //default message
        msg = res.message;
      }
      message = {
        title: "ERROR AL GUARDAR",
        body: msg,
        type: NOTIFICATION.TYPES.ERROR,
      };
      error = true;
      props.showNotification(message);
    }

    if (!error) {
      //clear table data source
      data.splice(0, data.length);
      loadUsers();
      updateLoading();
    }

    return error;
  }

  async function updatePassword(item) {
    let res = await userService.update_password(item.id, { id: item.id });
    if (res.status === 200) {
      Modal.warning({
        title: "Actualización de contraseña exitosa",
        content: (
          <span>
            <br />
            La contraseña temporal para el usuario <strong>
              {item.email}
            </strong>{" "}
            es:
            <br />
            <strong>
              <Title type="danger" level={3}>
                {res.data.password}
              </Title>
            </strong>
          </span>
        ),
      });
    } else {
      let message = {
        title: "ERROR AL ACTUALIZAR CONTRASEÑA",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function updateStatusUser(item) {
    let message;
    item.active = !item.status || false;
    let res = await userService.update(item.id, item);

    if (res.status === 200) {
      message = {
        title: "Actualización exitosa ",
        body: `El usuario ${item.first_name} ${item.last_name} se ha ${
          item.status ? "Desactivado" : "Activado"
        } correctamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "ERROR",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    //clear table data source
    data.splice(0, data.length);
    loadPositions();
    loadRoles();
    loadUsers();
    updateLoading();
  }

  async function updateUserStatusAndLogReason(values) {
    let message;
    let error = false;
    let res = await userService.update_status(values);

    if (res.status === 200) {
      message = {
        title: `Actualización exitosa`,
        body: `La información del usuario se actualizó correctamente`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      error = true;
      let msg = "";
      msg = res.message;
      message = {
        title: "Error",
        body: msg,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    loadPositions();
    loadRoles();
    loadAccessRoles();
    loadUsers();
    return error;
  }

  async function updateUsers(item, row) {
    let message;
    let data = item;
    data.first_name = row.first_name;
    data.last_name = row.last_name;
    data.profile.position = row.profile.position;
    data.profile.role = row.profile.role;
    data.profile.access_role = row.profile.access_role;
    data.profile.telephone = row.profile.telephone;
    data.profile.user_code = row.profile.user_code;
    data.profile.is_process_owner = row.profile.is_process_owner;
    data.profile.is_report_manager = row.profile.is_report_manager;
    data.profile.supervisor = row.profile.supervisor;
    //data.profile.status = row.profile.status;
    data.profile.start_job_date = moment(row.profile.start_job_date)
      .tz(TIMEZONE)
      .format();
    //data.log_observation = row.log_observation;
    //data.log_reason = row.log_reason;
    let res = await userService.update(data.id, data);

    if (res.status === 200) {
      message = {
        title: `Actualización exitosa`,
        body: `La información del usuario ${data.first_name} ${data.last_name} se actualizó correctamente`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      let msg_error = "";
      let msg = "";
      try {
        msg_error = JSON.parse(res.request.response);
        msg_error = msg_error.error_exception || msg_error;
      } catch (error) {
        msg_error = res.request.response;
      }

      if (typeof msg_error === "object") msg_error = JSON.stringify(msg_error);
      if (msg_error.indexOf("telephone") !== -1) {
        msg = (
          <span>
            El <strong>número teléfonico</strong>ingresado ya esta registrado,
            ingrese uno diferente.
          </span>
        );
      } else if (msg_error.indexOf("user_code") !== -1) {
        msg = (
          <span>
            El <strong>código de usuario</strong> ingresado ya esta registrado,
            ingrese uno diferente.
          </span>
        );
      } else {
        //default message
        msg = res.message;
      }
      message = {
        title: "Error",
        body: msg,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    loadPositions();
    loadRoles();
    loadAccessRoles();
    loadUsers();
  }

  async function loadPositions() {
    let message;
    let filters = [
      {
        criteria: "active",
        value: true,
      },
    ];
    let res = await positionService.search(filters);
    if (res.status === 200) {
      // Process reciveid data
      setPositionList(res.data);

      // Setup position options
      let options = res.data.map((position) => {
        return (
          <Option key={position.id} value={position.id}>
            {position.name}
          </Option>
        );
      });

      setPositionOptions(options);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} cargos obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
    //setLoading(false);
  }

  async function loadUserLogReason() {
    let message;
    let filters = [
      {
        criteria: "active",
        value: true,
      },
    ];
    let res = await userLogReasonService.search(filters);
    if (res.status === 200) {
      // Process received data
      setUserLogReasonList(res.data);

      // Setup position options
      let options = res.data.map((reason) => {
        return (
          <Option key={reason.id} value={reason.id}>
            {reason.name}
          </Option>
        );
      });

      setUserLogReasonOptions(options);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} motivos obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadUserStatus() {
    let message;
    let filters = [
      {
        criteria: "active",
        value: true,
      },
    ];
    let res = await userStatusService.search(filters);
    if (res.status === 200) {
      // Process reciveid data
      setUserStatusList(res.data);

      // Setup position options
      let options = res.data.map((state) => {
        return (
          <Option key={state.id} value={state.id}>
            {state.name}
          </Option>
        );
      });

      setUserStatusOptions(options);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} estados obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadProcessOwner() {
    let message;
    let filters = [
      {
        criteria: "active",
        value: true,
      },
      {
        criteria: "is_superuser",
        value: false,
      },
    ];
    let res = await userProcessOwnerService.search(filters);
    if (res.status === 200) {
      // Setup position options
      let options = res.data.map((user) => {
        return (
          <Option key={user.id} value={user.id}>
            {`${user.first_name} ${user.last_name}`}
          </Option>
        );
      });

      setUserProcessOwnerOptions(options);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} usuarios dueños de proceso obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
    //setLoading(false);
  }

  async function loadRoles() {
    let message;
    let filters = [
      {
        criteria: "active",
        value: true,
      },
    ];
    let res = await rolService.search(filters);
    if (res.status === 200) {
      // Process reciveid data
      setRoleList(res.data);

      // Setup position options
      let options = res.data.map((role) => {
        return (
          <Option key={role.id} value={role.id}>
            {role.name}
          </Option>
        );
      });

      setRoleOptions(options);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} roles obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
    //setLoading(false);
  }

  async function loadAccessRoles() {
    let message;
    let filters = [
      {
        criteria: "active",
        value: true,
      },
    ];
    let res = await accessRoleService.search(filters);
    if (res.status === 200) {
      // Process reciveid data
      setAccessRoleList(res.data);

      // Setup position options
      let options = res.data.map((role) => {
        return (
          <Option key={role.id} value={role.id}>
            {role.name}
          </Option>
        );
      });

      setAccessRoleOptions(options);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} roles de acceso obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
    //setLoading(false);
  }

  async function loadUsers() {
    let message;
    let res = await userService.fetch();
    if (res.status === 200) {
      // Process reciveid data
      setUserList(res.data);

      data.splice(0, data.length);
      res.data.forEach((user, idx) => {
        data.push({
          key: idx + 1,
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          profile: user.profile,
          status: user.active,
        });
      });

      res.data.map((user) => {
        csvData.push({
          id: user.id,
          "Codigo de usuario": String(user.profile.user_code),
          Usuario: user.email,
          Nombres: user.first_name,
          Apellidos: user.last_name,
          Telefono: user.profile.telephone,
        });
      });

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} usuarios obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (userList.length === 0 && firstLoad && props.isAuthenticated) {
      loadPositions();
      loadAccessRoles();
      loadRoles();
      loadUserStatus();
      loadUserLogReason();
      loadProcessOwner();
      loadUsers();
      setFirstLoad(false);
    }
  }, [userList]);

  return (
    <>
      <Row type="flex" justify="center">
        <Col span={16}>
          <Title type="secondary">Listado de usuarios</Title>
        </Col>
        <Col span={8}>
          <UserCreateModal
            save={saveUser}
            positionOpts={positionOptions}
            roleOpts={roleOptions}
            accessRoleOpts={accessRoleOptions}
            userStatusOpts={userStatusOptions}
            userProcessOwnerOpts={userProcessOwnerOptions}
          />
        </Col>
      </Row>
      <EditableFormTable
        loading={loading}
        update={updateUsers}
        positionOpts={positionOptions}
        roleOpts={roleOptions}
        accessRoleOpts={accessRoleOptions}
        userStatusOpts={userStatusOptions}
        userLogReasonOpts={userLogReasonOptions}
        updateStatus={updateStatusUser}
        updatePassword={updatePassword}
        updateLoading={updateLoading}
        positionList={positionList}
        userStatusList={userStatusList}
        roleList={roleList}
        accessRoleList={accessRoleList}
        updateUserStatus={updateUserStatusAndLogReason}
        userProcessOwnerOpts={userProcessOwnerOptions}
      />
    </>
  );
};

// ************************ COMPONENT TO SHOW MODAL WITH FORM ************************************
class UserCreateModal extends React.Component {
  state = {
    visible: false,
    loading: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      this.setState({ ...this.state, loading: true });

      let saved = await this.props.save(values);
      if (!saved) {
        //saved successfully
        form.resetFields();
        this.setState({ loading: false, visible: false });
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    return (
      <>
        <Row type="flex" justify="end">
          <Col span={24} style={{ textAlign: "right", marginBottom: "5px" }}>
            <CSVLink data={csvData} filename={"tpl_user_list.csv"}>
              <Button type="primary" shape="round" size="large" icon="download">
                Descargar plantilla
              </Button>
            </CSVLink>
          </Col>
        </Row>

        <Button
          type="default"
          shape="round"
          size="large"
          icon="plus-circle"
          style={{ float: "right", marginBottom: "10px" }}
          onClick={this.showModal}
        >
          Agregar Usuario
        </Button>
        <UserCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          positionOpts={this.props.positionOpts}
          roleOpts={this.props.roleOpts}
          accessRoleOpts={this.props.accessRoleOpts}
          userStatusOpts={this.props.userStatusOpts}
          userProcessOwnerOpts={this.props.userProcessOwnerOpts}
          loading={this.state.loading}
        />
      </>
    );
  }
}

const UserCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        roleOpts,
        accessRoleOpts,
        positionOpts,
        userStatusOpts,
        userProcessOwnerOpts,
        loading,
      } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          confirmLoading={loading}
          title="Agregar un nuevo usuario"
          okText="Guardar"
          cancelText="Cancelar"
          onCancel={onCancel}
          onOk={onCreate}
          maskClosable={false}
          className={"create-user-modal"}
        >
          <Form>
            <Row gutter={16} type="flex" align="middle">
              <Col span={12}>
                {/* ************* nombre del usuario ********************** */}
                <Form.Item label="Código">
                  {getFieldDecorator("user_code", {
                    rules: [
                      {
                        required: true,
                        message: "Debe ingresar el código del usuario",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* ************* apellido del usuario ********************** */}
                <Form.Item label="Correo (nombre de usuario)">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        pattern: /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/,
                        message: "Debe ingresar el email del usuario",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} type="flex" align="middle">
              <Col span={12}>
                {/* ************* nombre del usuario ********************** */}
                <Form.Item label="Nombres">
                  {getFieldDecorator("first_name", {
                    rules: [
                      {
                        required: true,
                        message: "Debe ingresar los nombres del usuario",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* ************* apellido del usuario ********************** */}
                <Form.Item label="Apellidos">
                  {getFieldDecorator("last_name", {
                    rules: [
                      {
                        required: true,
                        message: "Debe ingresar los apellidos del usuario",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} type="flex" align="middle">
              <Col span={12}>
                {/* ************* fecha de inicio de labores del usuario ********************** */}
                <Form.Item label="Fecha inicio de labores">
                  {getFieldDecorator("start_job_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: `Debe ingresar la fecha de inicio del periodo`,
                        whitespace: true,
                      },
                    ],
                    initialValue: moment().tz(TIMEZONE),
                  })(
                    <DatePicker
                      format="DD/MM/YYYY"
                      placeholder="Seleccione una fecha"
                      style={{ width: "100%" }}
                      disabledDate={disabledDate}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* ************* telefono del usuario ********************** */}
                <Form.Item label="Teléfono">
                  {getFieldDecorator("telephone", {
                    rules: [
                      {
                        pattern: /^[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4}$/,
                        message: "Ingrese un número telefónico válido",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} type="flex" align="middle">
              <Col span={12}>
                {/* ************* departamento del usuario ********************** */}
                <Form.Item label="Cargo">
                  {getFieldDecorator("position", {
                    rules: [
                      {
                        required: true,
                        message:
                          "Debe ingresar el cargo al que pertenece este usuario",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {positionOpts}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* ************* rol del usuario ********************** */}
                <Form.Item label="Rol administrativo">
                  {getFieldDecorator("role", {
                    rules: [
                      {
                        required: true,
                        message:
                          "Debe ingresar el rol al que pertenece este usuario",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {roleOpts}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} type="flex" align="middle">
              <Col span={12}>
                {/* ************* rol del usuario para accesos al sistema ********************** */}
                <Form.Item label="Rol de acceso">
                  {getFieldDecorator("access_role", {
                    rules: [
                      {
                        required: true,
                        message:
                          "Debe ingresar rol de acceso que posee este usuario",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {accessRoleOpts}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* ************* estado del usuario ********************** */}
                <Form.Item label="Estado">
                  {getFieldDecorator("user_status", {
                    rules: [
                      {
                        required: true,
                        message:
                          "Debe ingresar el estado que posee este usuario",
                      },
                    ],
                  })(<Select>{userStatusOpts}</Select>)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} type="flex" align="middle">
              <Col span={12}>
                {/* ************* El usuario es dueño de proceso ********************** */}
                <Form.Item label="Es dueño de proceso">
                  {getFieldDecorator("is_process_owner", {})(<Checkbox />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* ************* El usuario es dueño de proceso ********************** */}
                <Form.Item label="Supervisor">
                  {getFieldDecorator(
                    "supervisor",
                    {}
                  )(
                    <Select
                      allowClear={true}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {userProcessOwnerOpts}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      );
    }
  }
);

// ************************ COMPONENT TO RENDER TABLE ********************************************
class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data, editingKey: "" };
    this.columns = [
      {
        title: "#",
        dataIndex: "key",
        width: "75px",
        align: "center",
        editable: false,
        sorter: (a, b) => a.key - b.key,
      },
      // {
      //   title: "Correo",
      //   dataIndex: "email",
      //   editable: false,
      //   width: "400px",
      //   sorter: (a, b) => {
      //     if (a.email < b.email) {
      //       return -1;
      //     }
      //     if (a.email > b.email) {
      //       return 1;
      //     }
      //     return 0;
      //   }
      // },
      {
        title: "Código",
        dataIndex: "profile.user_code",
        editable: true,
        width: "125px",
        align: "center",
        render: (user_code) => {
          let item = data.filter((item) => {
            return item.profile.user_code === user_code;
          });

          let user;
          if (item[0].profile.request_password_reset_by_user) {
            user = (
              <Badge count={<Icon type="lock" style={{ color: "#f5222d" }} />}>
                <Tooltip placement="top" title={item[0].email}>
                  <Tag color={"purple"}>{user_code}</Tag>
                </Tooltip>
              </Badge>
            );
          } else {
            user = (
              <Tooltip placement="top" title={item[0].email}>
                <Tag color={"purple"}>{user_code}</Tag>
              </Tooltip>
            );
          }

          return user;
        },
        sorter: (a, b) => {
          if (a.profile.user_code < b.profile.user_code) {
            return -1;
          }
          if (a.profile.user_code > b.profile.user_code) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Nombres",
        dataIndex: "first_name",
        editable: true,
        width: "250px",
        sorter: (a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }

          // names must be equal
          return 0;
        },
      },
      {
        title: "Apellidos",
        dataIndex: "last_name",
        editable: true,
        width: "250px",
        sorter: (a, b) => {
          if (a.last_name < b.last_name) {
            return -1;
          }
          if (a.last_name > b.last_name) {
            return 1;
          }
          return 0;
        },
      },

      {
        title: "Teléfono",
        dataIndex: "profile.telephone",
        editable: true,
        width: "125px",
        sorter: (a, b) => {
          if (a.profile.telephone < b.profile.telephone) {
            return -1;
          }
          if (a.profile.telephone > b.profile.telephone) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Fecha inicio de labores",
        dataIndex: "profile.start_job_date",
        editable: true,
        width: "150px",
        align: "center",
        render: (start_job_date) => {
          return moment(start_job_date).tz(TIMEZONE).format("DD/MM/YYYY");
        },
        sorter: (a, b) => {
          if (a.profile.start_job_date < b.profile.start_job_date) {
            return -1;
          }
          if (a.profile.start_job_date > b.profile.start_job_date) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Supervisor",
        dataIndex: "profile.supervisor",
        editable: true,
        align: "center",
        width: "300px",
        render: (supervisor) => {
          return (
            <Tag color={"geekblue"}>
              {supervisor
                ? `${supervisor.first_name} ${supervisor.last_name}`
                : "No asignado"}
            </Tag>
          );
        },
      },
      {
        title: "Cargo",
        dataIndex: "profile.position",
        editable: true,
        align: "center",
        width: "300px",
        render: (position) => {
          let pos = this.props.positionList.filter((pos) => {
            return pos.id === position;
          });

          return <Tag color={"geekblue"}>{pos[0] ? pos[0].name : "N/A"}</Tag>;
        },
      },
      {
        title: "Rol Administrativo",
        dataIndex: "profile.role",
        editable: true,
        align: "center",
        width: "300px",
        render: (role) => {
          let rol = this.props.roleList.filter((rol) => {
            return rol.id === role;
          });

          return <Tag color={"geekblue"}>{rol[0] ? rol[0].name : "N/A"}</Tag>;
        },
      },
      {
        title: "Rol de acceso",
        dataIndex: "profile.access_role",
        editable: true,
        align: "center",
        width: "300px",
        render: (access_role) => {
          let rol = this.props.accessRoleList.filter((r) => {
            return r.id === access_role;
          });

          return <Tag color={"geekblue"}>{rol[0] ? rol[0].name : "N/A"}</Tag>;
        },
      },
      {
        title: "Dueño de proceso",
        dataIndex: "profile.is_process_owner",
        editable: true,
        align: "center",
        width: "150px",
        render: (is_process_owner) => {
          return (
            <Tag color={is_process_owner ? "geekblue" : "magenta"}>
              {is_process_owner ? "SI" : "NO"}
            </Tag>
          );
        },
      },
      {
        title: "Administrador de reportes",
        dataIndex: "profile.is_report_manager",
        editable: true,
        align: "center",
        width: "150px",
        render: (is_report_manager) => {
          return (
            <Tag color={is_report_manager ? "geekblue" : "magenta"}>
              {is_report_manager ? "SI" : "NO"}
            </Tag>
          );
        },
      },
      {
        title: "Estado",
        dataIndex: "profile.status",
        editable: true,
        align: "center",
        width: "300px",
        render: (status) => {
          let user_status = this.props.userStatusList.filter((state) => {
            return state.id === status;
          });

          return (
            <Tag color={"geekblue"}>
              {user_status[0] ? user_status[0].name : "N/A"}
            </Tag>
          );
        },
      },
      {
        title: "Disponibilidad",
        dataIndex: "status",
        editable: false,
        align: "center",
        width: "150px",
        render: (status) => (
          <Tag color={status ? "geekblue" : "magenta"}>
            {status ? "Habilitado" : "Deshabilitado"}
          </Tag>
        ),
        sorter: (a, b) => a.status - b.status,
      },
      {
        title: "Acciones",
        dataIndex: "operation",
        width: "345px",
        align: "center",
        fixed: "right",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    type="primary"
                    icon="save"
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    Guardar
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="¿Seguro que deseas cancelar?"
                onConfirm={() => this.cancel(record.key)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  ghost
                  type="danger"
                  icon="close-circle"
                  style={{ marginRight: 8 }}
                >
                  Cancelar
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <>
              <Button
                type="primary"
                icon="edit"
                ghost
                onClick={() => this.edit(record.key)}
                disabled={editingKey !== ""}
              >
                Editar
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title={` ¿Seguro que deseas ${
                  record.status ? "deshabilitar" : "habilitar"
                } este usuario?`}
                onConfirm={() => this.updateStatus(record.key)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  type={record.status ? "danger" : "primary"}
                  icon={record.status ? "close" : "check"}
                  ghost
                  disabled={editingKey !== ""}
                >
                  {record.status ? "Deshabilitar" : "Habilitar"}
                </Button>
              </Popconfirm>

              <Divider type="vertical" />
              <Popconfirm
                title={`¿Deseas reiniciar la contraseña del usuario ${record.first_name}?`}
                onConfirm={() => this.updatePassword(record.key)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  type="danger"
                  icon="lock"
                  ghost
                  disabled={editingKey !== ""}
                />
              </Popconfirm>
            </>
          );
        },
      },
    ];
  }

  isEditing = (record) => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  updateStatus = (key) => {
    const newData = [...this.state.data];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
      });
      this.setState({ editingKey: "" });

      //activate/deactivate register
      this.props.updateStatus(item);
    }
  };

  updatePassword = (key) => {
    const newData = [...this.state.data];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
      });
      this.setState({ editingKey: "" });

      //update password
      this.props.updatePassword(item);
    }
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: "" });
        //update register
        this.props.update(item, row);
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      let dataIndex;
      switch (col.dataIndex) {
        case "profile.role":
          dataIndex = "roleSelect";
          break;
        case "profile.position":
          dataIndex = "positionSelect";
          break;
        case "profile.access_role":
          dataIndex = "accessRoleSelect";
          break;
        case "profile.status":
          dataIndex = "userStatusSelect";
          break;
        case "profile.start_job_date":
          dataIndex = "startJobDate";
          break;
        case "profile.supervisor":
          dataIndex = "supervisorSelect";
          break;
        case "profile.is_process_owner":
          dataIndex = "isProcessOwner";
          break;
        case "profile.is_report_manager":
          dataIndex = "isReportManager";
          break;
        default:
          dataIndex = "text";
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: dataIndex,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          options: {
            positions: this.props.positionOpts,
            roles: this.props.roleOpts,
            accessRoles: this.props.accessRoleOpts,
            userStatus: this.props.userStatusOpts,
            userLogReason: this.props.userLogReasonOpts,
            updateUserStatus: this.props.updateUserStatus,
            processOwners: this.props.userProcessOwnerOpts,
          },
        }),
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          bordered
          dataSource={this.state.data}
          loading={this.props.loading}
          columns={columns}
          rowClassName="editable-row"
          scroll={{ x: 2145 }}
          pagination={{
            onChange: this.cancel,
            showSizeChanger: true,
            locale: { items_per_page: "/ página" },
            pageSizeOptions: ["10", "25", "50", "100"],
          }}
        />
      </EditableContext.Provider>
    );
  }
}

// ************************ COMPONENT TO MAKE EDITABLE CELLS *************************************
class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "text") {
      return <Input />;
    } else if (this.props.inputType === "roleSelect") {
      return (
        <Select style={{ minWidth: "200px" }}>
          {this.props.options.roles}
        </Select>
      );
    } else if (this.props.inputType === "positionSelect") {
      return (
        <Select style={{ minWidth: "200px" }}>
          {this.props.options.positions}
        </Select>
      );
    } else if (this.props.inputType === "accessRoleSelect") {
      return (
        <Select name="log_reason" style={{ minWidth: "200px" }}>
          {this.props.options.accessRoles}
        </Select>
      );
    } else if (this.props.inputType === "supervisorSelect") {
      return (
        <Select allowClear={true} style={{ minWidth: "200px" }}>
          {this.props.options.processOwners}
        </Select>
      );
    } else if (this.props.inputType === "isProcessOwner") {
      return <Checkbox />;
    } else if (this.props.inputType === "isReportManager") {
      return <Checkbox />;
    } else if (this.props.inputType === "startJobDate") {
      return (
        <DatePicker
          format="DD/MM/YYYY"
          placeholder="Seleccione una fecha"
          disabledDate={disabledDate}
        />
      );
    } else return <InputNumber />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {/* Text as inputType and telephone validation */}
            {inputType === "text" &&
              dataIndex === "profile.telephone" &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: false,
                    pattern: /^[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4}$/,
                    message: `Ingrese un ${title} válido!`,
                    whitespace: true,
                  },
                ],
                initialValue: record["profile"].telephone,
              })(this.getInput())}
            {/* text as inputType in generic fields */}
            {inputType === "text" &&
              dataIndex !== "profile.telephone" &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                    whitespace: true,
                  },
                ],
                initialValue:
                  dataIndex === "profile.user_code"
                    ? record["profile"].user_code
                    : record[dataIndex],
              })(this.getInput())}
            {/* Datetime picker */}
            {inputType === "startJobDate" &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    type: "object",
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                    whitespace: true,
                  },
                ],
                initialValue: moment(record[dataIndex]).tz(TIMEZONE),
              })(this.getInput())}
            {/* Select as InputType  */}
            {(inputType === "roleSelect" ||
              inputType === "positionSelect" ||
              inputType === "accessRoleSelect") &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                  },
                ],
                initialValue:
                  dataIndex === "profile.role"
                    ? record["profile"].role
                    : dataIndex === "profile.position"
                    ? record["profile"].position
                    : record["profile"].access_role,
              })(this.getInput())}

            {inputType === "supervisorSelect" &&
              getFieldDecorator(dataIndex, {
                initialValue: record["profile"].supervisor
                  ? record["profile"].supervisor.id
                  : null,
              })(this.getInput())}

            {/*  Select as input type*/}
            {inputType === "userStatusSelect" && (
              <UpdateStateUserModal
                userStatusOpts={this.props.options.userStatus}
                userLogReasonOpts={this.props.options.userLogReason}
                userStatus={record["profile"].status}
                updateUserStatus={this.props.options.updateUserStatus}
                user={record["id"]}
              />
            )}

            {/* Checkbox */}
            {inputType === "isProcessOwner" &&
              getFieldDecorator(dataIndex, {
                initialValue: record["profile"].is_process_owner,
                valuePropName: "checked",
              })(this.getInput())}

            {/* Checkbox */}
            {inputType === "isReportManager" &&
              getFieldDecorator(dataIndex, {
                initialValue: record["profile"].is_report_manager,
                valuePropName: "checked",
              })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class UpdateStateUserModal extends React.Component {
  state = { visible: false };
  showModal = () => {
    this.setState({
      visible: true,
      loading: false,
    });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      this.setState({ ...this.state, loading: true });
      let user = this.props.user;
      values.user = user;
      let error = await this.props.updateUserStatus(values);
      if (!error) {
        //saved successfully
        form.resetFields();
        this.setState({ loading: false, visible: false });
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
      loading: false,
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    const { userStatusOpts, userLogReasonOpts, userStatus, form } = this.props;

    return (
      <UpdateStateUserModalForm
        wrappedComponentRef={this.saveFormRef}
        userStatusOpts={userStatusOpts}
        userLogReasonOpts={userLogReasonOpts}
        userStatus={userStatus}
        visible={this.state.visible}
        loading={this.state.loading}
        handleCreate={this.handleCreate}
        handleCancel={this.handleCancel}
        showModal={this.showModal}
      />
    );
  }
}

const UpdateStateUserModalForm = Form.create({
  name: "form_update_user_status",
})(
  class extends React.Component {
    render() {
      const {
        userStatusOpts,
        userLogReasonOpts,
        userStatus,
        visible,
        handleCreate,
        handleCancel,
        showModal,
        form,
        loading,
      } = this.props;

      const { getFieldDecorator } = form;
      return (
        <div>
          <Button icon="sync" type="primary" onClick={showModal}>
            Cambiar estado
          </Button>

          <Modal
            title="Actualizar el estado del usuario"
            visible={visible}
            onOk={handleCreate}
            onCancel={handleCancel}
            okText="Guardar"
            cancelText="Cancelar"
            maskClosable={false}
            confirmLoading={loading}
          >
            <Form>
              <Form.Item label="Estado">
                {getFieldDecorator("status", {
                  rules: [
                    {
                      required: true,
                      message: "Debe ingresar un estado para este usuario",
                    },
                  ],
                  initialValue: userStatus,
                })(<Select>{userStatusOpts}</Select>)}
              </Form.Item>

              <Divider />
              <Form.Item label="Motivo">
                {getFieldDecorator(
                  "log_reason",
                  {}
                )(<Select>{userLogReasonOpts}</Select>)}
              </Form.Item>
              <Form.Item label="Observación">
                {getFieldDecorator("log_observation", {})(<Input />)}
              </Form.Item>
            </Form>
          </Modal>
        </div>
      );
    }
  }
);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
