export const types = {
  LOGGED_IN: "LOGGED_IN",
  LOGGED_OUT: "LOGGED_OUT",
  PASSWORD_UPDATED: "PASSWORD_UPDATED"
};

export const logIn = data => {
  //Get token expires date
  let token_expires = new Date();
  token_expires.setSeconds(token_expires.getSeconds() + data.expires_in);
  data.expires_in = token_expires;
  return {
    type: types.LOGGED_IN,
    payload: data
  };
};

export const logOut = () => {
  return {
    type: types.LOGGED_OUT
  };
};

export const passwordUpdated = () => {
  return {
    type: types.PASSWORD_UPDATED
  };
};
