import React from "react";
import { Menu, Button, Tag } from "antd";
import { connect } from "react-redux";

const AvatarMenu = (props) => {
  return (
    <>
      <Tag color="geekblue">{props.user}</Tag>
      <Menu>
        {/* <Menu.Item key="0">
          <a href="#">1st menu item</a>
        </Menu.Item>
        <Menu.Item key="1">
          <a href="#">2nd menu item</a>
        </Menu.Item> */}
        <Menu.Divider />
        <Menu.Item key="3">
          <Button
            type="danger"
            shape="round"
            icon="logout"
            size="small"
            onClick={props.logout}
          >
            Cerrar sesión
          </Button>
        </Menu.Item>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.payload.user_name,
  };
};
export default connect(mapStateToProps)(AvatarMenu);
