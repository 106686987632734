import rest from "../rest";
import http from "../axios";
import {
  REPORT_PERSONAL_ACHIEVEMENT,
  REPORT_INCOMPLETED_TASK,
  REPORT_GLOBAL_TASK,
} from "../constants";

export default {
  ...rest(REPORT_PERSONAL_ACHIEVEMENT), //to get other endpoints
  personal_achievement: async (data) => {
    try {
      const res = await http.post(REPORT_PERSONAL_ACHIEVEMENT, data);
      return res;
    } catch (e) {
      return e;
    }
  },
  incompleted_task: async (data) => {
    try {
      const res = await http.post(REPORT_INCOMPLETED_TASK, data);
      return res;
    } catch (e) {
      return e;
    }
  },
  global_task: async (data) => {
    try {
      const res = await http.post(REPORT_GLOBAL_TASK, data);
      return res;
    } catch (e) {
      return e;
    }
  },
};
