const validate = values => {
  let errors = {};
  if (!values.password) {
    errors.password = "Contraseña requerida";
  } else if (values.password.length < 6) {
    errors.password = "Contraseña mayor a 6 caracteres";
  }
  return errors;
};

export default validate;
