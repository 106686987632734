import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notifications } from "../../_redux/actions";
import {
  Table,
  Input,
  Form,
  Typography,
  Button,
  Row,
  Col,
  Tag,
  Modal,
  Radio,
  Checkbox,
  Switch,
} from "antd";
import { accessRoleService, permissionService } from "../../_helpers/services/";
import { NOTIFICATION } from "../../_helpers/constants";

const View = (props) => {
  const [accessRoleList, setAccessRoleList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const { Title } = Typography;

  const updateLoading = () => {
    setLoading(!loading);
  };

  async function saveAccessRole(values) {
    let message;
    let error = false;

    let res = await accessRoleService.insert(values);

    if (res.status === 201) {
      message = {
        title: "Nuevo perfil de acceso creado",
        body: `El perfil de acceso ${values.name} ha sido agregado exitosamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "ERROR AL GUARDAR",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      error = true;
    }
    props.showNotification(message);

    if (!error) {
      loadAccessRoles();
      updateLoading();
    }
    return error;
  }

  async function updatePermission(data) {
    let message;
    let res = await accessRoleService.update_permission(data);
    if (res.status === 200) {
      message = {
        title: "Actualización exitosa ",
        body: `El perfil de acceso se ha actualizado correctamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "ERROR",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
  }

  async function loadAccessRoles() {
    let message;
    let res = await accessRoleService.fetch();
    if (res.status === 200) {
      // Process recived data
      setAccessRoleList(res.data);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} perfiles de acceso obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    setLoading(false);
  }

  async function loadPermissions() {
    let message;
    let filter = [
      {
        criteria: "active",
        value: true,
      },
    ];

    let res = await permissionService.search(filter);
    if (res.status === 200) {
      // Process received data
      setPermissionList(res.data);
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  useEffect(() => {
    if (accessRoleList.length === 0 && firstLoad && props.isAuthenticated) {
      loadPermissions();
      loadAccessRoles();
      setFirstLoad(false);
    }
  }, [accessRoleList]);

  return (
    <>
      <Row type="flex" justify="center">
        <Col span={16}>
          <Title type="secondary">Listado de Perfiles de Acceso</Title>
        </Col>
        <Col span={8}>
          <AccessRoleCreateModal save={saveAccessRole} />
        </Col>
      </Row>

      <AccessRoleTableDetail
        loading={loading}
        roles={accessRoleList}
        permissions={permissionList}
        updatePermission={updatePermission}
      />
    </>
  );
};

// ************************ COMPONENT TO RENDER TABLE *******************************************
class AccessRoleTableDetail extends React.Component {
  onChange = (checked, role_id, permission_id) => {
    this.props.updatePermission({
      checked: checked,
      role: role_id,
      permission: permission_id,
    });
  };

  render() {
    const { loading, roles, permissions } = this.props;
    let cols = [
      {
        title: "Perfil de acceso",
        width: 250,
        dataIndex: "name",
      },
    ];
    permissions.forEach((permission, idx) => {
      cols.push({
        key: permission.id,
        title: permission.code,
        dataIndex: `id`,
        width: 125,
        align: "center",
        render: (id) => {
          let current_role = roles.filter((rol) => {
            return rol.id === id;
          });
          if (current_role.length > 0) {
            current_role = current_role[0];
            let permission_assigned = current_role.permissions.map(
              (permission) => permission.code
            );

            return (
              <Switch
                defaultChecked={permission_assigned.includes(permission.code)}
                onChange={(checked) =>
                  this.onChange(checked, current_role.id, permission.id)
                }
              />
            );
          } else {
            return <Tag color="magenta">N/A</Tag>;
          }
        },
      });
    });

    return (
      <Table
        rowKey="id"
        bordered
        dataSource={roles}
        loading={loading}
        columns={cols}
        rowClassName="editable-row"
        scroll={{ x: permissions.length * 125 + 250 }} //col_number * col_width + first_col_width
        pagination={{
          onChange: this.cancel,
          showSizeChanger: true,
          locale: { items_per_page: "/ página" },
          pageSizeOptions: ["10", "25", "50", "100"],
        }}
      />
    );
  }
}

// ************************ COMPONENT TO SHOW MODAL WITH FORM ************************************
class AccessRoleCreateModal extends React.Component {
  state = {
    visible: false,
    loading: false,
    isModule: false,
  };

  showModal = () => {
    this.setState({ ...this.state, visible: true });
  };

  handleCancel = () => {
    this.setState({ ...this.state, visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({ ...this.state, loading: true });
      let error = await this.props.save(values);
      if (!error) {
        form.resetFields();
        this.setState({ isModule: false, loading: false, visible: false });
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  handleChangeIsModule = (e) => {
    this.setState({ ...this.state, isModule: e.target.checked });
  };

  render() {
    return (
      <div>
        <Button
          type="primary"
          shape="round"
          size="large"
          icon="plus-circle"
          style={{ float: "right" }}
          onClick={this.showModal}
        >
          Agregar perfil de acceso
        </Button>
        <AccessRoleCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          loading={this.state.loading}
          handleChangeIsModule={this.handleChangeIsModule}
          isModule={this.state.isModule}
        />
      </div>
    );
  }
}

const AccessRoleCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        loading,
        handleChangeIsModule,
        isModule,
      } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          confirmLoading={loading}
          title="Agregar un nuevo perfil de acceso"
          okText="Guardar"
          cancelText="Cancelar"
          onCancel={onCancel}
          onOk={onCreate}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Form.Item label="Nombre del perfil de acceso">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Debe ingresar el nombre del perfil de acceso",
                    whitespace: true,
                  },
                  {
                    max: 100,
                    message: "Nombre demasiado largo",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item className="collection-create-form_last-form-item">
              {getFieldDecorator("active", {
                initialValue: true,
              })(
                <Radio.Group>
                  <Radio value={true}>Activo</Radio>
                  <Radio value={false}>Inactivo</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
