export const types = {
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  CLEAR_NOTIFICATIONS: "CLEAR_NOTIFICATIONS"
};

export const addNotification = data => {
  return {
    type: types.ADD_NOTIFICATION,
    payload: data
  };
};

export const removeNotification = data => {
  return {
    type: types.REMOVE_NOTIFICATION,
    payload: data
  };
};

export const updateNotification = data => {
  return {
    type: types.UPDATE_NOTIFICATION,
    payload: data
  };
};

export const clearNotifications = () => {
  return {
    type: types.CLEAR_NOTIFICATIONS
  };
};
