import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ThunkMiddleware from "redux-thunk";
import {
  authenticationReducer,
  dataReducer,
  notificationReducer,
  navigationReducer,
} from "./index";

const persistConfig = {
  key: "mngmt",
  storage,
};

const rootReducer = combineReducers({
  auth: authenticationReducer,
  data: dataReducer,
  notification: notificationReducer,
  navigation: navigationReducer,
});

//const rootReducer = authenticationReducer;
const persistedReducer = persistReducer(persistConfig, rootReducer);
const configureStore = () => {
  let store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(ThunkMiddleware), //logger
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
        compose
    )
  );
  let persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
