import http from "./axios";

export default (resource, except = []) => {
  const rest = {
    fetch: async (page = false) => {
      try {
        const pagination = page !== false ? `?page=${page}` : "/";
        const res = await http.get(`${resource}${pagination}`);
        return res;
      } catch (e) {
        return e;
      }
    },
    get: async (id) => {
      try {
        const res = await http.get(`${resource}/${id}/`);
        return res;
      } catch (e) {
        return e;
      }
    },
    insert: async (data) => {
      try {
        const res = await http.post(`${resource}/`, data);
        return res;
      } catch (e) {
        return e;
      }
    },
    update: async (id, data) => {
      try {
        const res = await http.put(`${resource}/${id}/`, data);
        return res;
      } catch (e) {
        return e;
      }
    },
    delete: async (id) => {
      try {
        const res = await http.delete(`${resource}/${id}/`);
        return res;
      } catch (e) {
        return e;
      }
    },
    search: async (filters) => {
      try {
        //const pagination = page !== false ? `?page=${page}` : "";
        let slug = "?";
        filters.forEach((filter) => {
          slug += `${filter.criteria}=${filter.value}&`;
        });
        const res = await http.get(`${resource}/${slug}`);
        return res;
      } catch (e) {
        return e;
      }
    },
  };

  const _r = {};
  for (const k in rest) {
    if (except.indexOf(k) === -1) {
      _r[k] = rest[k];
    }
  }

  return _r;
};
