import { useState, useEffect } from "react";

const useForm = (callback, validate, data = {}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (
      Object.keys(errors).length === 0 &&
      isSubmitting &&
      Object.keys(values).length > 0
    ) {
      callback();
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values, data));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value.trim(),
    }));
  };

  const handleClear = () => {
    setValues({});
    setErrors({});
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    handleClear,
  };
};

export default useForm;
