import PersonalAchievement from "./PersonalAchievement";
import IncompletedTask from "./IncompletedTask";
import GlobalTask from "./GlobalTask";

let Report = {
  PersonalAchievement: PersonalAchievement,
  IncompletedTask: IncompletedTask,
  GlobalTask: GlobalTask,
};

export default Report;
