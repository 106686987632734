import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notifications } from "../../_redux/actions";
import {
  Row,
  Col,
  Typography,
  Select,
  Tag,
  Button,
  Empty,
  Table,
  Spin,
  Progress,
} from "antd";
import {
  periodService,
  reportService,
  departmentService,
} from "../../_helpers/services/";
import { NOTIFICATION, TIMEZONE } from "../../_helpers/constants";
import moment from "moment-timezone";

const { Title } = Typography;
const { Option } = Select;

const PersonalAchievement = (props) => {
  const [periodList, setPeriodList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [periodSelected, setPeriodSelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  async function loadPeriods() {
    let message;
    let res = await periodService.get_list();
    if (res.status === 200) {
      // Process reciveid data
      setPeriodList(res.data);
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadDeparments() {
    let message;
    let res = await departmentService.fetch();
    if (res.status === 200) {
      // Process reciveid data
      setDepartmentList(res.data);
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  useEffect(() => {
    if (periodList.length === 0 && firstLoad && props.isAuthenticated) {
      loadPeriods();
      loadDeparments();
      setFirstLoad(false);
    }
  }, [periodList]);

  const handleChangePeriod = (value) => {
    if (value === undefined) {
      setPeriodSelected(null);
      setResults([]);
    } else {
      setPeriodSelected(value);
    }
  };

  const handleChangeDepartment = (value) => {
    if (value === undefined) {
      setDepartmentSelected(null);
    } else {
      setDepartmentSelected(value);
    }
  };

  const handleGenerateReport = async () => {
    if (periodSelected === null) {
      let message = {
        title: "COMPLETAR FILTRO",
        body: "Debe seleccionar el periodo",
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    } else {
      setLoading(true);
      let data = {};
      if (departmentSelected !== null) {
        data = {
          period: periodSelected,
          department: departmentSelected,
        };
      } else {
        data = {
          period: periodSelected,
        };
      }

      let res = await reportService.personal_achievement(data);
      setResults(res.data);
      setLoading(false);
    }
  };

  return (
    <>
      <Row type="flex" justifiy="center" align="middle">
        <Col span={24}>
          <Title type="secondary">Reporte de logro por usuario</Title>
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={10}>
          <Select
            showSearch
            style={{ width: "100%", marginBottom: "20px" }}
            placeholder="Seleccione un periodo"
            optionFilterProp="children"
            optionLabelProp="title"
            onChange={handleChangePeriod}
            allowClear={true}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {periodList.map((period) => {
              return (
                <Option key={period.id} value={period.id} title={period.name}>
                  <Tag color="blue">
                    {moment(period.start_date)
                      .tz(TIMEZONE)
                      .format("DD/MM/YYYY")}
                  </Tag>{" "}
                  -{" "}
                  <Tag color="blue">
                    {moment(period.end_date).tz(TIMEZONE).format("DD/MM/YYYY")}
                  </Tag>
                  <span>{period.name}</span>
                </Option>
              );
            })}
          </Select>
        </Col>
        {props.isReportManager && (
          <Col span={10}>
            <Select
              showSearch
              style={{
                width: "100%",
                marginBottom: "20px",
                paddingLeft: "10px",
              }}
              placeholder="Seleccione un departamento"
              optionFilterProp="children"
              optionLabelProp="title"
              onChange={handleChangeDepartment}
              allowClear={true}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {departmentList.map((department) => {
                return (
                  <Option
                    key={department.id}
                    value={department.id}
                    title={department.name}
                  >
                    {department.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        )}
        <Col span={4}>
          <Button
            type="primary"
            shape="round"
            size="large"
            icon="plus-circle"
            style={{ marginBottom: "20px", marginLeft: "10px", width: "100%" }}
            onClick={handleGenerateReport}
          >
            Generar reporte
          </Button>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <NestedTable results={results} loading={loading} />
        </Col>
      </Row>
    </>
  );
};

// Component for nested table render
const NestedTable = (props) => {
  const { results, loading } = props;
  const expandedRowRender = (record, index, indent, expanded) => {
    const data = [];
    const rows = results.filter((row) => {
      return row.user === record.user;
    });

    rows.forEach((row) => {
      if (row.objectives.length > 0) {
        row.objectives.forEach((objective) => {
          data.push({
            id: objective.id,
            name: objective.name,
            achievement: objective.achievement,
          });
        });
      }
    });
    const columns = [
      { title: "Objetivos del periodo", dataIndex: "name" },
      {
        title: "% del objetivo",
        dataIndex: "achievement",
        align: "center",
        render: (total) => {
          return (
            <Progress
              percent={parseFloat(total)}
              style={{ paddingLeft: 10, paddingRight: 10 }}
              size="small"
            />
          );
        },
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
      />
    );
  };

  // For main table
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      width: "50px",
      align: "center",
      editable: false,
      key: "key",
      sorter: (a, b) => a.key - b.key,
    },
    { title: "Usuario", dataIndex: "user", key: "user" },
    {
      title: "Porcentaje de logro del período",
      dataIndex: "total_achievement",
      key: "total_achievement",
      align: "center",
      render: (total) => {
        return (
          <Progress type="circle" percent={parseFloat(total)} width={80} />
        );
      },
    },
    {
      title: "Departamento",
      dataIndex: "department",
      key: "department",
    },
  ];

  return (
    <>
      {loading && (
        <Row type="flex" justify="center">
          <Col span={2}>
            <Spin
              tip="Generando reporte ..."
              size="large"
              style={{ textAlign: "center" }}
            ></Spin>
          </Col>
        </Row>
      )}
      {results.length === 0 && !loading && (
        <Empty
          image={Empty.PRESENTED_IMAGE_DEFAULT}
          description={
            "No hay resultados para mostrar, intente ajustando los filtros."
          }
        />
      )}

      {results.length > 0 && !loading && (
        <Table
          rowKey="user_id"
          className="components-table-demo-nested"
          columns={columns}
          expandedRowRender={expandedRowRender}
          dataSource={results}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isReportManager: state.auth.payload.is_report_manager,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalAchievement);
