import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notifications } from "../../_redux/actions";
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Button,
  Divider,
  Row,
  Col,
  Tag,
  Modal,
  Radio,
  Checkbox,
} from "antd";
import { permissionService } from "../../_helpers/services/";
import { NOTIFICATION } from "../../_helpers/constants";

const data = [];
const EditableContext = React.createContext();

const View = (props) => {
  const [permissionList, setPermissionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const { Title } = Typography;
  const EditableFormTable = Form.create()(EditableTable);

  const updateLoading = () => {
    setLoading(!loading);
  };

  async function savePermission(values) {
    let message;
    let error = false;
    let res = await permissionService.insert(values);

    if (res.status === 201) {
      message = {
        title: "Nuevo permiso creado",
        body: `El permiso ${values.name} ha sido agregado exitosamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "ERROR AL GUARDAR",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      error = true;
    }
    props.showNotification(message);

    if (!error) {
      //clear table data source
      data.splice(0, data.length);
      loadPermissions();
      updateLoading();
    }
    return error;
  }

  async function updateStatusPermission(item) {
    let message;
    item.active = !item.status || false;

    let res = await permissionService.update(item.id, item);

    if (res.status === 200) {
      message = {
        title: "Actualización exitosa ",
        body: `El permiso ${item.name} se ha ${
          item.status ? "Desactivado" : "Activado"
        } correctamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "ERROR",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    //clear table data source
    data.splice(0, data.length);
    loadPermissions();
    updateLoading();
  }

  async function updatePermissions(item, row) {
    let message;
    let data = item;
    data.feature = row.feature;
    data.code = row.code;
    data.read = row.read;
    data.write = row.write;

    let res = await permissionService.update(data.id, data);

    if (res.status === 200) {
      message = {
        title: `Actualización exitosa`,
        body: `La información del permiso ${data.name}, se actualizó correctamente`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
  }

  async function loadPermissions() {
    let message;
    let res = await permissionService.fetch();
    if (res.status === 200) {
      // Process recived data
      setPermissionList(res.data);

      data.splice(0, data.length);
      res.data.forEach((permission, idx) => {
        data.push({
          key: idx + 1,
          id: permission.id,
          code: permission.code,
          feature: permission.feature,
          read: permission.read,
          write: permission.write,
          status: permission.active,
        });
      });

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} permisos obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    setLoading(false);
  }

  useEffect(() => {
    if (permissionList.length === 0 && firstLoad && props.isAuthenticated) {
      loadPermissions();
      setFirstLoad(false);
    }
  }, [permissionList]);

  return (
    <>
      <Row type="flex" justify="center">
        <Col span={16}>
          <Title type="secondary">Listado de Permisos de Acceso</Title>
        </Col>
        <Col span={8}>
          <PermissionCreateModal save={savePermission} />
        </Col>
      </Row>
      <EditableFormTable
        loading={loading}
        update={updatePermissions}
        updateStatus={updateStatusPermission}
        updateLoading={updateLoading}
      />
    </>
  );
};

// ************************ COMPONENT TO SHOW MODAL WITH FORM ************************************
class PermissionCreateModal extends React.Component {
  state = {
    visible: false,
    loading: false,
    isModule: false,
  };

  showModal = () => {
    this.setState({ ...this.state, visible: true });
  };

  handleCancel = () => {
    this.setState({ ...this.state, visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({ ...this.state, loading: true });
      let error = await this.props.save(values);
      if (!error) {
        form.resetFields();
        this.setState({ isModule: false, loading: false, visible: false });
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  handleChangeIsModule = (e) => {
    this.setState({ ...this.state, isModule: e.target.checked });
  };

  render() {
    return (
      <div>
        <Button
          type="primary"
          shape="round"
          size="large"
          icon="plus-circle"
          style={{ float: "right" }}
          onClick={this.showModal}
        >
          Agregar permiso de acceso
        </Button>
        <PermissionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          loading={this.state.loading}
          handleChangeIsModule={this.handleChangeIsModule}
          isModule={this.state.isModule}
        />
      </div>
    );
  }
}

const PermissionCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        loading,
        handleChangeIsModule,
        isModule,
      } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          confirmLoading={loading}
          title="Agregar un nuevo permiso de acceso"
          okText="Guardar"
          cancelText="Cancelar"
          onCancel={onCancel}
          onOk={onCreate}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Form.Item label="Código del permiso">
              {getFieldDecorator("code", {
                rules: [
                  {
                    required: true,
                    message: "Debe ingresar el código del permiso",
                    whitespace: true,
                  },
                  {
                    max: 10,
                    message: "Código demasiado largo",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Nombre de la funcionalidad">
              {getFieldDecorator("feature", {
                rules: [
                  {
                    required: true,
                    message: "Debe ingresar la funcionalidad del permiso",
                    whitespace: true,
                  },
                  {
                    max: 100,
                    message: "Descripción demasiado larga",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item label="¿Acceso de lectura?">
                  {getFieldDecorator("read", {
                    valuePropName: "checked",
                    initialValue: false,
                  })(<Checkbox disabled={isModule} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="¿Acceso de escritura?">
                  {getFieldDecorator("write", {
                    valuePropName: "checked",
                    initialValue: false,
                  })(<Checkbox disabled={isModule} />)}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item className="collection-create-form_last-form-item">
              {getFieldDecorator("active", {
                initialValue: true,
              })(
                <Radio.Group>
                  <Radio value={true}>Activo</Radio>
                  <Radio value={false}>Inactivo</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

// ************************ COMPONENT TO RENDER TABLE ********************************************
class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data, editingKey: "" };
    this.columns = [
      {
        title: "#",
        dataIndex: "key",
        width: "60px",
        align: "center",
        editable: false,
        sorter: (a, b) => a.key - b.key,
      },
      {
        title: "Código",
        dataIndex: "code",
        editable: true,
        width: "110px",
        sorter: (a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          // names must be equal
          return 0;
        },
      },
      {
        title: "Funcionalidad",
        dataIndex: "feature",
        editable: true,
        width: "300px",
        sorter: (a, b) => {
          if (a.feature < b.feature) {
            return -1;
          }
          if (a.feature > b.feature) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Lectura",
        dataIndex: "read",
        editable: true,
        align: "center",
        width: "110px",
        render: (read) => (
          <Tag color={read ? "blue" : "magenta"}>{read ? "SI" : "NO"}</Tag>
        ),
        sorter: (a, b) => a.read - b.read,
      },
      {
        title: "Escritura",
        dataIndex: "write",
        editable: true,
        align: "center",
        width: "120px",
        render: (write) => (
          <Tag color={write ? "blue" : "magenta"}>{write ? "SI" : "NO"}</Tag>
        ),
        sorter: (a, b) => a.write - b.write,
      },

      {
        title: "Estado",
        dataIndex: "status",
        editable: false,
        align: "center",
        width: "110px",
        render: (status) => (
          <Tag color={status ? "blue" : "magenta"}>
            {status ? "Activo" : "Inactivo"}
          </Tag>
        ),
        sorter: (a, b) => a.status - b.status,
      },
      {
        title: "Acciones",
        dataIndex: "operation",
        width: "300px",
        align: "center",
        fixed: "right",

        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    type="primary"
                    icon="save"
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    Guardar
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="¿Seguro que deseas cancelar?"
                onConfirm={() => this.cancel(record.key)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  ghost
                  type="danger"
                  icon="close-circle"
                  style={{ marginRight: 8 }}
                >
                  Cancelar
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <>
              <Button
                type="primary"
                icon="edit"
                ghost
                onClick={() => this.edit(record.key)}
                disabled={editingKey !== ""}
              >
                Editar
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title={` ¿Seguro que deseas ${
                  record.status ? "desactivar" : "activar"
                } este permiso?`}
                onConfirm={() => this.updateStatus(record.key)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  type={record.status ? "danger" : "primary"}
                  icon={record.status ? "close" : "check"}
                  ghost
                  disabled={editingKey !== ""}
                >
                  {record.status ? "Desactivar" : "Activar"}
                </Button>
              </Popconfirm>
            </>
          );
        },
      },
    ];
  }

  isEditing = (record) => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  updateStatus = (key) => {
    const newData = [...this.state.data];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
      });
      this.setState({ editingKey: "" });

      //activate/deactivate register
      this.props.updateStatus(item);
    }
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: "" });

        //update register
        this.props.update(item, row);
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      let checkbox_fields = ["read", "write"];
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: checkbox_fields.includes(col.dataIndex)
            ? "checkbox"
            : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          bordered
          dataSource={this.state.data}
          loading={this.props.loading}
          columns={columns}
          rowClassName="editable-row"
          scroll={{ x: 1215 }}
          pagination={{
            onChange: this.cancel,
            showSizeChanger: true,
            locale: { items_per_page: "/ página" },
            pageSizeOptions: ["10", "25", "50", "100"],
          }}
        />
      </EditableContext.Provider>
    );
  }
}

// ************************ COMPONENT TO MAKE EDITABLE CELLS *************************************
class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "checkbox") {
      return <Checkbox />;
    } else return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {inputType === "text" &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                    whitespace: true,
                  },
                  {
                    max: dataIndex === "code" ? 10 : 100,
                    message: `${title} demasiado largo`,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}

            {inputType === "checkbox" &&
              getFieldDecorator(dataIndex, {
                valuePropName: "checked",
                initialValue: record[dataIndex],
              })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
