import rest from "../rest";
import http from "../axios";
import {
  LOGIN_ENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
  PASSWORD_RESET_REQUEST_ENDPOINT
} from "../../_helpers/constants";

export default {
  //...rest("administrators"), //to get other endpoints
  login: async data => {
    try {
      const res = await http.post(LOGIN_ENDPOINT, data);
      return res;
    } catch (e) {
      return e;
    }
  },
  update_password: async data => {
    try {
      const res = await http.post(UPDATE_PASSWORD_ENDPOINT, data);
      return res;
    } catch (e) {
      return e;
    }
  },
  password_reset_request: async data => {
    try {
      const res = await http.post(PASSWORD_RESET_REQUEST_ENDPOINT, data);
      return res;
    } catch (e) {
      return e;
    }
  }
};
