import React from "react";
import { Route, Redirect } from "react-router-dom";

//rest are the props inherit from parent component
const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        let redirect;

        if (rest.updatePassword && rest.isAuthenticated) {
          redirect = (
            <Redirect
              to={{
                pathname: "/update-password",
                state: { from: props.location }
              }}
            />
          );
        } else if (rest.isAuthenticated) {
          redirect = (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        } else {
          redirect = <Component {...props} />;
        }

        return redirect;
      }}
    />
  );
};

export default LoginRoute;
