import { data } from "../actions";

let initialState = {
  parents: [],
  products: []
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case data.types.GET_PARENTS:
      return {
        ...state,
        parents: action.payload
      };
    case data.types.GET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    default:
      return state;
  }
};
export default dataReducer;
