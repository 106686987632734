import React from "react";
import { Router } from "react-router-dom";
import { MainRouter } from "../../_routes";
import history from "../../_helpers/history";
import "./App.css";

const App = props => {
  return (
    <Router history={history}>
      <MainRouter />
    </Router>
  );
};

export default App;
