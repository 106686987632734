import React, { useEffect, useState, useDebugValue, useRef } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
  Typography,
  Button,
  Row,
  Col,
  Tag,
  Select,
  Modal,
  Form,
  Input,
  InputNumber,
  Popover,
  DatePicker,
  Alert,
  Spin,
  Progress,
  Tooltip,
  Checkbox,
  Empty,
  Upload,
  message,
} from "antd";
import {
  objectiveService,
  planningService,
  tagService,
  periodService,
  instrumentService,
  userProfileService,
} from "../../_helpers/services/";

import moment from "moment-timezone";
import {
  NOTIFICATION,
  TIMEZONE,
  EVALUATION_PERIODICITY,
  OBJECTIVE_LEVELS,
  COLORS
} from "../../_helpers/constants";
import { notifications } from "../../_redux/actions";

const { Option } = Select;
const { Text, Title } = Typography;
var globalTags = [];

const Detail = (props) => {
  const componentRef = useRef();
  const [objectiveList, setObjectiveList] = useState([]);
  const [planning, setPlanning] = useState([]);
  const [tags, setTags] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filesLoader, setFilesLoader] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [listItems, setItems] = useState([]);
  const [visibility, setVisibility] = useState([]);
  const [objectiveOptions, setObjectiveOptions] = useState([]);
  const [fileList, setFileList] = useState([]);

  //*********************************************************************
  //*********************** Initial data loaders ************************
  //*********************************************************************
  async function loadUsers() {
    let message;
    let filters = [
      {
        criteria: "is_active",
        value: true,
      },
      {
        criteria: "is_superuser",
        value: false,
      },
    ];
    let res = await userProfileService.search(filters);
    if (res.status === 200) {
      // Process reciveid data
      setUsers(res.data);
    } else {
      message = {
        title: "Error al cargar el listado de usuarios",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadPlanning() {
    let res = await planningService.get(props.match.params.id);
    let message;
    if (res.status === 200) {
      // Process reciveid data
      setPlanning(res.data);
    } else {
      message = {
        title: "Error al cargar el plan operativo",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadInstruments() {
    let message;
    let filters = [
      {
        criteria: "active",
        value: true,
      },
    ];
    let res = await instrumentService.search(filters);
    if (res.status === 200) {
      setInstruments(res.data);
    } else {
      message = {
        title: "Error al obtener el listado de instrumentos de evaluación",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadTags() {
    let message;
    let filters = [
      {
        criteria: "active",
        value: true,
      },
    ];
    let res = await tagService.search(filters);
    if (res.status === 200) {
      setTags(res.data);
      globalTags = res.data;
    } else {
      message = {
        title: "Error al obtener el listado de etiquetas",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadPeriods() {
    let message;
    let filters = [
      {
        criteria: "active",
        value: true,
      },
      {
        criteria: "filtered",
        value: true,
      },
    ];
    let res = await periodService.search(filters);
    if (res.status === 200) {
      setPeriods(res.data);
    } else {
      message = {
        title: "Error al obtener el listado de periodos",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadObjectivesList() {
    let message;
    let data = {
      planning_id: props.match.params.id,
    };
    let res = await objectiveService.get_all(data);
    if (res.status === 200) {
      //create objective List - for select control on create objective form
      setObjectiveOptions(res.data);
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  }

  async function loadObjectives(isParent = true) {
    let message;
    let data = {
      planning_id: props.match.params.id,
      is_parent: isParent,
    };
    let res = await objectiveService.get_list(data);
    if (res.status === 200) {
      // Process reciveid data
      setObjectiveList(res.data);

      //create objective list
      setItems(res.data);

      //create objective List - for cascader control
      //setObjectiveOptions(res.data);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} objetivos obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
    setLoading(false);
  }

  async function loadNestedObjectives(isParent = true, objectiveId = null) {
    //setLoading(true);
    let message;
    let response = [];
    let data = {
      planning_id: props.match.params.id,
      is_parent: isParent,
      objective_id: objectiveId,
    };
    let res = await objectiveService.get_list(data);
    if (res.status === 200) {
      response = res.data;
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
    //setLoading(false);
    return response;
  }
  //*********************************************************************
  //*********************** Saving new objectives ***********************
  //*********************************************************************
  const updateLoading = () => {
    setLoading(!loading);
  };

  async function saveObjective(values) {
    let message;
    let msg;
    let error = false;
    //Obtener el objeto tag completo - no solo el id que proporciona el select
    if (values.tags?.length > 0) {
      let aux = [];
      values.tags.forEach((tag) => {
        let currentTag = tags.filter((t) => t.id === tag);

        //obtener el primer elemento del arreglo
        aux.push(currentTag.pop());
      });

      values.tags = aux;
    }

    //Obtener el objeto period completo - no solo el id que proporciona el select
    if (values.period && values.period.length > 0) {
      let aux = [];
      values.period.forEach((period) => {
        let currentPeriod = periods.filter((t) => t.id === period);

        //obtener el primer elemento del arreglo
        aux.push(currentPeriod.pop());
      });

      values.period = aux;
    } else {
      values.period = [];
    }

    if (values.dates) {
      values.start_date = moment(values.dates[0])
        .startOf("day")
        .tz(TIMEZONE)
        .format();
      values.end_date = moment(values.dates[1])
        .endOf("day")
        .tz(TIMEZONE)
        .format();
    }
    // inverse value, because the question meaning (requiere seguimiento? -> SI -> NO es macro actividad)
    values.is_macro_activity = !values.is_macro_activity;
    let res = await objectiveService.insert(values);
    if (res.request) {
      if (res.request.status === 400) {
        message = {
          title: "ERROR AL GUARDAR",
          body: (
            <span>Error con: {JSON.parse(res.request.response).error}</span>
          ),
          type: NOTIFICATION.TYPES.ERROR,
        };
        error = true;
      } else if (res.request.status === 201) {
        message = {
          title: "Nuevo objetivo creado",
          body: `El objetivo ${values.name} ha sido agregado exitosamente.`,
          type: NOTIFICATION.TYPES.SUCCESS,
        };
      } else {
        message = {
          title: "ERROR AL GUARDAR",
          body: "Ocurrio un error inesperado.",
          type: NOTIFICATION.TYPES.ERROR,
        };
        error = true;
      }
    }

    props.showNotification(message);

    if (!error) {
      loadObjectives();
      loadObjectivesList();
      updateLoading();
    }
    return error;
  }

  //*********************************************************************
  //*********************** Handle Update Objective  ********************
  //*********************************************************************
  const updateObjective = async (
    objective,
    value,
    reload = true,
    attachments = null
  ) => {
    let error = true;
    let message = "";
    let attr = Object.keys(value)[0];
    let data = {
      id: objective,
      [attr]: value[attr],
    };
    const formData = new FormData();
    if (attachments) {
      formData.append("id", objective);
      formData.append([attr], value[attr]);

      attachments.forEach((file) => {
        formData.append("attachments[]", file);
      });
    }

    let res = await objectiveService.update_per_field(
      attachments ? formData : data
    );
    if (res.status !== 200) {
      message = {
        title: `Error al actualizar el ${attr}`,
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    } else {
      error = false;
    }
    if (reload) loadObjectives();
    return error;
  };

  //*********************************************************************
  //*********************** Handle auto add tags ************************
  //*********************************************************************
  const handleCloseTag = async (tag, objective) => {
    let data = {
      tag: tag,
      objective: objective,
    };

    let res = await tagService.remove(data);
    let message;
    if (res.status !== 200) {
      message = {
        title: "Error al elminar la etiqueta",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }
  };

  const handleAddTag = async (tags, objective) => {
    let message;
    let data = {
      tags: tags,
      objective: objective,
    };
    let res = await tagService.add(data);
    if (res.status !== 200) {
      message = {
        title: "Error al elminar la etiqueta",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }

    await loadTags();
    await loadObjectives();
  };

  //*********************************************************************
  //*********************** Handle Update percentage ********************
  //*********************************************************************
  const updatePercentage = async (value, objective) => {
    let message;
    let data = {
      value: parseInt(value),
      objective: objective.id,
    };

    let res = await objectiveService.update_percentage(data);
    if (res.status !== 200) {
      message = {
        title: "Error al actualizar el porcentaje",
        body: res.response.data.out_of_range
          ? "El total del porcentaje no debe exceder 100%"
          : res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      props.showNotification(message);
    }

    // await loadTags();
    await loadObjectives();
  };

  //*********************************************************************
  //*********************** Handle Upload objective file ****************
  //*********************************************************************
  const customRequest = async (xhr) => {
    setFilesLoader(true);
    let formData = new FormData();
    let message;
    fileList.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("planning", planning.id);

    let res = await objectiveService.upload_objective_file(formData);

    if (res.request) {
      if (res.request.status === 400) {
        message = {
          title: "ERROR AL GUARDAR",
          body: <span>Error con: {res.request.response}</span>,
          type: NOTIFICATION.TYPES.ERROR,
        };
      } else if (res.request.status === 200) {
        message = {
          title: "CREACION EXITOSA",
          body: `Lista de objetivos creados con éxito`,
          type: NOTIFICATION.TYPES.SUCCESS,
        };
      } else {
        message = {
          title: "ERROR AL GUARDAR",
          body: "Ocurrio un error inesperado.",
          type: NOTIFICATION.TYPES.ERROR,
        };
      }
    }

    props.showNotification(message);

    setFilesLoader(false);
    await loadObjectives();
    await loadObjectivesList();
    return;
  };

  // Section to upoad objectives files
  const onRemove = (file) => {
    this.setState((state) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  };

  const beforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    const isMaxFiles = fileList.length > 4;
    const filesAccepted = file.type === "text/csv";
    if (!filesAccepted) {
      message.error("¡ATENCIÓN: Solo se permiten archivos .CSV!");
    }

    if (isMaxFiles) {
      message.error("¡ATENCIÓN: Máximo 5 archivos!");
      return !isMaxFiles;
    }

    if (isLt10M) {
      setFileList([fileList, file]);
    } else {
      message.error("¡ATENCIÓN: El peso del archivo debe ser menor a 10MB!");
      return isLt10M;
    }
  };

  const fileProps = {
    name: "objective_file",
    listType: "text",
    multiple: false,
    onRemove: onRemove,
    beforeUpload: beforeUpload,
    customRequest: customRequest,
    fileList: fileList,
    showUploadList: false,
  };

  //*********************************************************************
  //*************************** Handle onSearch  ************************
  //*********************************************************************
  const onSearch = (value) => {
    let filtered_objectives = [];
    if (value.length > 0) {
      filter_objectives(objectiveList, filtered_objectives, value);
      setItems(filtered_objectives);
    } else {
      //reset list
      setItems(objectiveList);
    }
  };

  const filter_objectives = (objectives, list, criteria) => {
    objectives.forEach((obj) => {
      if (
        criteria &&
        obj.name.toLowerCase().includes(criteria.toString().toLowerCase())
      ) {
        list.push(obj);
      }

      if (obj.children.length > 0) {
        filter_objectives(obj.children, list, criteria);
      } else {
        return;
      }
    });
  };

  const getNestedObjectives = async (objective_id, level = 1) => {
    let response = [];
    let btnDown = document.querySelector(`.expand-button-${objective_id}`);
    let btnUp = document.querySelector(`.collapse-button-${objective_id}`);

    if (btnDown.getAttribute("data-show") === "selected") {
      //change button
      let item = document.querySelector(`.item-${objective_id}`);
      item && (item.style.display = "inherit");

      btnUp.style.display = "initial";
      btnUp.setAttribute("data-show", "selected");
      btnDown.style.display = "none";
      btnDown.setAttribute("data-show", "non-selected");
      //change button
      response = await loadNestedObjectives(false, objective_id);
      let indexParent = -1;

      if (level == 1) {
        indexParent = listItems.findIndex(
          (objective) => objective.id === objective_id
        );

        if (indexParent !== -1) {
          let copy = [...listItems];
          copy[indexParent].children = response;

          setItems(copy);
        }
      }

      if (level === 2) {
        let auxIndex = 0;
        listItems.forEach((item, idx) => {
          auxIndex = idx;
          if (typeof item.children === "object") {
            let index = item.children.findIndex(
              (objective) => objective.id === objective_id
            );

            if (index !== -1) {
              let copy = [...listItems];

              copy[auxIndex].children[index].children = response;

              setItems(copy);
              return;
            }
          }
        });
      }

      if (level === 3) {
        let auxIndex0 = 0;
        let auxIndex1 = 0;
        listItems.forEach((item, idx) => {
          auxIndex0 = idx; //level 1

          item.children.forEach((objectiveL2, idx) => {
            auxIndex1 = idx; //level 2

            if (typeof objectiveL2 === "object") {
              //level 3
              let index = objectiveL2.children.findIndex(
                (objective) => objective.id === objective_id
              );

              if (index !== -1) {
                let copy = [...listItems];
                copy[auxIndex0].children[auxIndex1].children[
                  index
                ].children = response;

                setItems(copy);
                return;
              }
            }
          });
        });
      }

      if (level === 4) {
        let auxIndex0 = 0;
        let auxIndex1 = 0;
        let auxIndex2 = 0;
        listItems.forEach((item, idx) => {
          auxIndex0 = idx; //level 1

          item.children.forEach((objectiveL2, idx) => {
            auxIndex1 = idx; //level 2

            if (typeof objectiveL2 === "object") {
              objectiveL2.children.forEach((objectiveL3, idx) => {
                auxIndex2 = idx; //level 3

                if (typeof objectiveL3 === "object") {
                  //level 4
                  let index = objectiveL3.children.findIndex(
                    (objective) => objective.id === objective_id
                  );

                  if (index !== -1) {
                    let copy = [...listItems];
                    copy[auxIndex0].children[auxIndex1].children[
                      auxIndex2
                    ].children[index].children = response;

                    setItems(copy);
                    return;
                  }
                }
              });
            }
          });
        });
      }

      if (level === 5) {
        let auxIndex0 = 0;
        let auxIndex1 = 0;
        let auxIndex2 = 0;
        let auxIndex3 = 0;
        listItems.forEach((item, idx) => {
          auxIndex0 = idx; //level 1

          item.children.forEach((objectiveL2, idx) => {
            auxIndex1 = idx; //level 2

            if (typeof objectiveL2 === "object") {
              objectiveL2.children.forEach((objectiveL3, idx) => {
                auxIndex2 = idx; //level 3
                if (typeof objectiveL3 === "object") {
                  objectiveL3.children.forEach((objectiveL4, idx) => {
                    auxIndex3 = idx; //level 4

                    if (typeof objectiveL4 === "object") {
                      //level 5
                      let index = objectiveL4.children.findIndex(
                        (objective) => objective.id === objective_id
                      );

                      if (index !== -1) {
                        let copy = [...listItems];
                        copy[auxIndex0].children[auxIndex1].children[
                          auxIndex2
                        ].children[auxIndex3].children[
                          index
                        ].children = response;

                        setItems(copy);
                        return;
                      }
                    }
                  });
                }
              });
            }
          });
        });
      }

      if (level === 6) {
        let auxIndex0 = 0;
        let auxIndex1 = 0;
        let auxIndex2 = 0;
        let auxIndex3 = 0;
        let auxIndex4 = 0;
        listItems.forEach((item, idx) => {
          auxIndex0 = idx; //level 1

          item.children.forEach((objectiveL2, idx) => {
            auxIndex1 = idx; //level 2

            if (typeof objectiveL2 === "object") {
              objectiveL2.children.forEach((objectiveL3, idx) => {
                auxIndex2 = idx; //level 3
                if (typeof objectiveL3 === "object") {
                  objectiveL3.children.forEach((objectiveL4, idx) => {
                    auxIndex3 = idx; //level 4

                    if (typeof objectiveL4 === "object") {
                      objectiveL4.children.forEach((objectiveL5, idx) => {
                        auxIndex4 = idx; //level 5

                        if (typeof objectiveL5 === "object") {
                          //level 6
                          let index = objectiveL5.children.findIndex(
                            (objective) => objective.id === objective_id
                          );

                          if (index !== -1) {
                            let copy = [...listItems];
                            copy[auxIndex0].children[auxIndex1].children[
                              auxIndex2
                            ].children[auxIndex3].children[auxIndex4].children[
                              index
                            ].children = response;

                            setItems(copy);
                            return;
                          }
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        });
      }

      if (level === 7) {
        let auxIndex0 = 0;
        let auxIndex1 = 0;
        let auxIndex2 = 0;
        let auxIndex3 = 0;
        let auxIndex4 = 0;
        let auxIndex5 = 0;
        listItems.forEach((item, idx) => {
          auxIndex0 = idx; //level 1

          item.children.forEach((objectiveL2, idx) => {
            auxIndex1 = idx; //level 2

            if (typeof objectiveL2 === "object") {
              objectiveL2.children.forEach((objectiveL3, idx) => {
                auxIndex2 = idx; //level 3
                if (typeof objectiveL3 === "object") {
                  objectiveL3.children.forEach((objectiveL4, idx) => {
                    auxIndex3 = idx; //level 4

                    if (typeof objectiveL4 === "object") {
                      objectiveL4.children.forEach((objectiveL5, idx) => {
                        auxIndex4 = idx; //level 5

                        if (typeof objectiveL5 === "object") {
                          objectiveL5.children.forEach((objectiveL6, idx) => {
                            auxIndex5 = idx; //level 6

                            if (typeof objectiveL6 === "object") {
                              //level 7
                              let index = objectiveL6.children.findIndex(
                                (objective) => objective.id === objective_id
                              );

                              if (index !== -1) {
                                let copy = [...listItems];
                                copy[auxIndex0].children[auxIndex1].children[
                                  auxIndex2
                                ].children[auxIndex3].children[
                                  auxIndex4
                                ].children[auxIndex5].children[
                                  index
                                ].children = response;

                                setItems(copy);
                                return;
                              }
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        });
      }

      if (level === 8) {
        let auxIndex0 = 0;
        let auxIndex1 = 0;
        let auxIndex2 = 0;
        let auxIndex3 = 0;
        let auxIndex4 = 0;
        let auxIndex5 = 0;
        let auxIndex6 = 0;
        listItems.forEach((item, idx) => {
          auxIndex0 = idx; //level 1

          item.children.forEach((objectiveL2, idx) => {
            auxIndex1 = idx; //level 2

            if (typeof objectiveL2 === "object") {
              objectiveL2.children.forEach((objectiveL3, idx) => {
                auxIndex2 = idx; //level 3
                if (typeof objectiveL3 === "object") {
                  objectiveL3.children.forEach((objectiveL4, idx) => {
                    auxIndex3 = idx; //level 4

                    if (typeof objectiveL4 === "object") {
                      objectiveL4.children.forEach((objectiveL5, idx) => {
                        auxIndex4 = idx; //level 5

                        if (typeof objectiveL5 === "object") {
                          objectiveL5.children.forEach((objectiveL6, idx) => {
                            auxIndex5 = idx; //level 6

                            if (typeof objectiveL6 === "object") {
                              objectiveL6.children.forEach(
                                (objectiveL7, idx) => {
                                  auxIndex6 = idx; //level 7

                                  if (typeof objectiveL7 === "object") {
                                    //level 8
                                    let index = objectiveL7.children.findIndex(
                                      (objective) =>
                                        objective.id === objective_id
                                    );

                                    if (index !== -1) {
                                      let copy = [...listItems];
                                      copy[auxIndex0].children[
                                        auxIndex1
                                      ].children[auxIndex2].children[
                                        auxIndex3
                                      ].children[auxIndex4].children[
                                        auxIndex5
                                      ].children[auxIndex6].children[
                                        index
                                      ].children = response;

                                      setItems(copy);
                                      return;
                                    }
                                  }
                                }
                              );
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        });
      }

      if (level === 9) {
        let auxIndex0 = 0;
        let auxIndex1 = 0;
        let auxIndex2 = 0;
        let auxIndex3 = 0;
        let auxIndex4 = 0;
        let auxIndex5 = 0;
        let auxIndex6 = 0;
        let auxIndex7 = 0;
        listItems.forEach((item, idx) => {
          auxIndex0 = idx; //level 1

          item.children.forEach((objectiveL2, idx) => {
            auxIndex1 = idx; //level 2

            if (typeof objectiveL2 === "object") {
              objectiveL2.children.forEach((objectiveL3, idx) => {
                auxIndex2 = idx; //level 3

                if (typeof objectiveL3 === "object") {
                  objectiveL3.children.forEach((objectiveL4, idx) => {
                    auxIndex3 = idx; //level 4

                    if (typeof objectiveL4 === "object") {
                      objectiveL4.children.forEach((objectiveL5, idx) => {
                        auxIndex4 = idx; //level 5

                        if (typeof objectiveL5 === "object") {
                          objectiveL5.children.forEach((objectiveL6, idx) => {
                            auxIndex5 = idx; //level 6

                            if (typeof objectiveL6 === "object") {
                              objectiveL6.children.forEach(
                                (objectiveL7, idx) => {
                                  auxIndex6 = idx; //level 7

                                  if (typeof objectiveL7 === "object") {
                                    objectiveL7.children.forEach(
                                      (objectiveL8, idx) => {
                                        auxIndex7 = idx; //level 8

                                        if (typeof objectiveL8 === "object") {
                                          //level 9
                                          let index = objectiveL8.children.findIndex(
                                            (objective) =>
                                              objective.id === objective_id
                                          );

                                          if (index !== -1) {
                                            let copy = [...listItems];
                                            copy[auxIndex0].children[
                                              auxIndex1
                                            ].children[auxIndex2].children[
                                              auxIndex3
                                            ].children[auxIndex4].children[
                                              auxIndex5
                                            ].children[auxIndex6].children[
                                              auxIndex7
                                            ].children[
                                              index
                                            ].children = response;

                                            setItems(copy);
                                            return;
                                          }
                                        }
                                      }
                                    );
                                  }
                                }
                              );
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        });
      }

      if (level === 10) {
        let auxIndex0 = 0;
        let auxIndex1 = 0;
        let auxIndex2 = 0;
        let auxIndex3 = 0;
        let auxIndex4 = 0;
        let auxIndex5 = 0;
        let auxIndex6 = 0;
        let auxIndex7 = 0;
        let auxIndex8 = 0;
        listItems.forEach((item, idx) => {
          auxIndex0 = idx; //level 1

          item.children.forEach((objectiveL2, idx) => {
            auxIndex1 = idx; //level 2

            if (typeof objectiveL2 === "object") {
              objectiveL2.children.forEach((objectiveL3, idx) => {
                auxIndex2 = idx; //level 3
                if (typeof objectiveL3 === "object") {
                  objectiveL3.children.forEach((objectiveL4, idx) => {
                    auxIndex3 = idx; //level 4

                    if (typeof objectiveL4 === "object") {
                      objectiveL4.children.forEach((objectiveL5, idx) => {
                        auxIndex4 = idx; //level 5

                        if (typeof objectiveL5 === "object") {
                          objectiveL5.children.forEach((objectiveL6, idx) => {
                            auxIndex5 = idx; //level 6

                            if (typeof objectiveL6 === "object") {
                              objectiveL6.children.forEach(
                                (objectiveL7, idx) => {
                                  auxIndex6 = idx; //level 7

                                  if (typeof objectiveL7 === "object") {
                                    objectiveL7.children.forEach(
                                      (objectiveL8, idx) => {
                                        auxIndex7 = idx; //level 8

                                        if (typeof objectiveL8 === "object") {
                                          objectiveL8.children.forEach(
                                            (objectiveL9, idx) => {
                                              auxIndex8 = idx; //level 9

                                              if (
                                                typeof objectiveL9 === "object"
                                              ) {
                                                //level 10
                                                let index = objectiveL9.children.findIndex(
                                                  (objective) =>
                                                    objective.id ===
                                                    objective_id
                                                );

                                                if (index !== -1) {
                                                  let copy = [...listItems];
                                                  copy[auxIndex0].children[
                                                    auxIndex1
                                                  ].children[
                                                    auxIndex2
                                                  ].children[
                                                    auxIndex3
                                                  ].children[
                                                    auxIndex4
                                                  ].children[
                                                    auxIndex5
                                                  ].children[
                                                    auxIndex6
                                                  ].children[
                                                    auxIndex7
                                                  ].children[
                                                    auxIndex8
                                                  ].children[
                                                    index
                                                  ].children = response;

                                                  setItems(copy);
                                                  return;
                                                }
                                              }
                                            }
                                          );
                                        }
                                      }
                                    );
                                  }
                                }
                              );
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        });
      }
    } else {
      btnUp.style.display = "none";
      btnUp.setAttribute("data-show", "non-selected");
      btnDown.style.display = "initial";
      btnDown.setAttribute("data-show", "selected");

      let item = document.querySelector(`.item-${objective_id}`);
      item && (item.style.display = "none");
    }
    return response;
  };

  //*********************************************************************
  //*********************** Use effect to intial load *******************
  //*********************************************************************
  useEffect(() => {
    if (objectiveList.length === 0 && firstLoad && props.isAuthenticated) {
      loadPlanning();
      loadTags();
      loadPeriods();
      loadInstruments();
      loadUsers();
      loadObjectivesList();
      loadObjectives();
      setFirstLoad(false);
    }
  }, [objectiveList]);
  return (
    <>
      <Row type="flex" justify="end">
        <Col>
          <Upload {...fileProps}>
            <Button
              type="primary"
              shape="round"
              size="large"
              icon="upload"
              disabled={filesLoader}
              style={{ marginLeft: "5px" }}
            >
              {!filesLoader && "Subir archivo"}
              {filesLoader && "Cargando archivo ..."}
            </Button>
          </Upload>
        </Col>
        <Col>
          <ObjectiveCreateModal
            save={saveObjective}
            planning={planning}
            objectiveOpts={objectiveOptions}
            tagOpts={tags}
            periodOpts={periods}
            customRequest={customRequest}
            fileList={fileList}
            setFileList={setFileList}
            usersOpts={users}
          />
        </Col>
      </Row>

      <Row type="flex" justify="center">
        <Col span={24}>
          <Title level={2} type="secondary">
            Objetivos del plan: {planning.name}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {/* <Search
            placeholder="Buscar objetivo ..."
            onSearch={onSearch}
            style={{ marginBottom: "35px" }}
            enterButton
            allowClear
          /> */}
        </Col>
      </Row>
      {!loading && (
        <ObjectiveList
          objectives={listItems}
          getNestedObjectives={getNestedObjectives}
          handleCloseTag={handleCloseTag}
          handleAddTag={handleAddTag}
          updatePercentage={updatePercentage}
          updateObjective={updateObjective}
          visibility={visibility}
          setVisibility={setVisibility}
          objectiveOpts={objectiveOptions}
          periodOpts={periods}
          instrumentOpts={instruments}
          usersOpts={users}
          loadObjectives={loadObjectives}
        />
      )}
      {loading && (
        <Row type="flex" justify="center" align="top">
          <Col>
            <Spin
              style={{ marginTop: "50px" }}
              size="large"
              tip="Cargando matriz de objetivos, por favor espere..."
            />
          </Col>
        </Row>
      )}
      {objectiveOptions.length === 0 && !loading && (
        <Empty description="Este plan no tiene objetivos" />
      )}
    </>
  );
};
// ***********************************************************************************************/
// ******************* COMPONENT TO SHOW LIST OF OBJECTIVES   ************************************/
// ***********************************************************************************************/
class ObjectiveList extends React.Component {
  render() {
    // loop through the persons array and create a new component for each, passing the current person (id and name) and it's children (person.people) as props
    const {
      objectives,
      getNestedObjectives,
      handleCloseTag,
      handleAddTag,
      updatePercentage,
      updateObjective,
      visibility,
      setVisibility,
      objectiveOpts,
      periodOpts,
      instrumentOpts,
      usersOpts,
      loadObjectives,
    } = this.props;
    let list = objectives.map((objective) => {
      return (
        <ListItem
          key={objective.id}
          objective={objective}
          children={objective.children}
          getNestedObjectives={getNestedObjectives}
          handleCloseTag={handleCloseTag}
          handleAddTag={handleAddTag}
          updatePercentage={updatePercentage}
          updateObjective={updateObjective}
          visibility={visibility}
          setVisibility={setVisibility}
          objectiveOpts={objectiveOpts}
          periodOpts={periodOpts}
          instrumentOpts={instrumentOpts}
          usersOpts={usersOpts}
          loadObjectives={loadObjectives}
        />
      );
    });

    return (
      <div>
        <ul
          style={{
            listStyleType: "none",
            marginLeft: "0",
            paddingLeft: "0px",
            display: "inherit",
            backgroundColor: "#f0f2f5",
            borderRadius: "10px",
          }}
        >
          {list}
        </ul>
      </div>
    );
  }
}

class ListItem extends React.Component {
  render() {
    const {
      objective,
      children,
      getNestedObjectives,
      updateObjective,
      updatePercentage,
      objectiveOpts,
      periodOpts,
      instrumentOpts,
      usersOpts,
      loadObjectives,
      handleCloseTag,
      handleAddTag,
      visibility,
      setVisibility,
    } = this.props;
    let childnodes = null;

    // the Node component calls itself if there are children
    if (children) {
      if (typeof children[0] === "object") {
        childnodes = children.map((childnode) => {
          return (
            <ListItem
              key={childnode.id}
              objective={childnode}
              children={childnode.children}
              getNestedObjectives={getNestedObjectives}
              handleCloseTag={handleCloseTag}
              handleAddTag={handleAddTag}
              updatePercentage={updatePercentage}
              updateObjective={updateObjective}
              visibility={visibility}
              setVisibility={setVisibility}
              objectiveOpts={objectiveOpts}
              periodOpts={periodOpts}
              instrumentOpts={instrumentOpts}
              usersOpts={usersOpts}
              loadObjectives={loadObjectives}
            />
          );
        });
      }
    }
    let additional_row_span = objective.is_macro_activity ? 4 : 0;
    // return our list element
    // display children if there are any
    return (
      <li key={objective.id} className='list-item' style={{ border: `1px solid ${COLORS[objective.level - 1 ]}`}}>
        <>
          <Row type="flex" align="middle" >
            <Col span={1} style={{ textAlign: "center" }}>
              {objective.children.length > 0 && (
                <>
                  <Button
                    ghost
                    data-show={`selected`}
                    shape="circle"
                    type="primary"
                    icon="down"
                    className={`expand-button expand-button-${objective.id}`}
                    onClick={() =>
                      getNestedObjectives(objective.id, objective.level)
                    }
                  />
                  <Button
                    ghost
                    data-show={`non-selected`}
                    shape="circle"
                    type="primary"
                    icon="up"
                    data-objective={objective.id}
                    className={`collapse-button collapse-button-${objective.id}`}
                    onClick={() =>
                      getNestedObjectives(objective.id, objective.level)
                    }
                  />
                </>
              )}
            </Col>
            <Col
              span={
                objective.children.length > 0
                  ? 14 + additional_row_span
                  : 16 + additional_row_span
              }
            >
              {/*
           Is macro activity
         */}
              {objective.is_macro_activity && (
                <>
                  <Tag>{objective.code}</Tag>
                  {objective.name}
                </>
              )}

              {/*
           Is objective, show modal
         */}
              {!objective.is_macro_activity && (
                <ObjectiveShowDetailModal
                  item={objective}
                  updateObjective={updateObjective}
                  updatePercentage={updatePercentage}
                  objectiveOpts={objectiveOpts}
                  periodOpts={periodOpts}
                  instrumentOpts={instrumentOpts}
                  usersOpts={usersOpts}
                  loadObjectives={loadObjectives}
                />
              )}
            </Col>
            {objective.children.length > 0 && (
              <Col
                span={2}
                style={{ textAlign: "center", paddingRight: "5px" }}
              >
                <Tooltip title="Avance de los objetivos anidados">
                  <Progress
                    percent={parseInt(objective.child_achievement)}
                    size="small"
                  />
                </Tooltip>
              </Col>
            )}
            {!objective.is_macro_activity && (
              <Col span={2} style={{ textAlign: "center" }}>
                <Tooltip title="Avance del objetivo">
                  <Progress
                    percent={parseInt(objective.achievement)}
                    size="small"
                  />
                </Tooltip>
              </Col>
            )}

            <Col span={2} style={{ textAlign: "center" }}>
              <Percentage
                item={objective}
                updatePercentage={updatePercentage}
              />
            </Col>
            {!objective.is_macro_activity && (
              <Col span={1} style={{ textAlign: "center" }}>
                <PopoverDepartment item={objective} />
              </Col>
            )}
            {!objective.is_macro_activity && (
              <Col span={1} style={{ textAlign: "center" }}>
                <PopoverDates item={objective} />
              </Col>
            )}
            <Col span={1} style={{ textAlign: "center" }}>
              <PopoverTags
                item={objective}
                handleCloseTag={handleCloseTag}
                handleAddTag={handleAddTag}
                tagOpts={globalTags}
                tagCount={objective.tags.length}
              />
            </Col>
          </Row>
        </>

        {/* Render nested list */}
        {childnodes ? (
          <ul
            style={{
              listStyleType: "none",
              marginLeft: 0,
              paddingLeft: "40px",
              display: "inherit",
              backgroundColor: "#fafafa",
              borderRadius: 0,
            }}
            className={`item-${objective.id}`}
          >
            {childnodes}
          </ul>
        ) : null}
      </li>
    );
  }
}
// ***********************************************************************************************/
// ******************* COMPONENT TO SHOW AND EDIT PERCENTAGE  ************************************/
// ***********************************************************************************************/
class Percentage extends React.Component {
  state = {
    visibleInputPercentage: true,
    value: 0,
    loading: false,
  };
  changeVisibleInputPercentage = () => {
    this.setState({
      ...this.state,
      visibleInputPercentage: !this.state.visibleInputPercentage,
      value: this.props.item.percentage,
    });
  };

  updatePercentageValue = (value) => {
    this.setState({
      ...this.state,
      value: value,
    });
  };

  updateLoading = () => {
    this.setState({
      ...this.state,
      loading: !this.state.loading,
    });
  };

  savePercentage = async (objective) => {
    if (this.state.value >= 0 && this.state.value <= 100) {
      this.setState({
        ...this.state,
        visibleInputPercentage: !this.state.visibleInputPercentage,
        loading: true,
      });
      await this.props.updatePercentage(this.state.value, objective);
      this.updateLoading();
    }
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      value: this.props.item.percentage,
    });
  }
  render() {
    const { item, updatePercentage } = this.props;
    return (
      <>
        {this.state.visibleInputPercentage && (
          <Tag onClick={this.changeVisibleInputPercentage} color="blue">
            {`${item.percentage} %`}
          </Tag>
        )}
        {this.state.loading && (
          <Spin size="small" style={{ float: "right", marginRight: "10px" }} />
        )}

        {!this.state.visibleInputPercentage && (
          <>
            <Button
              className="close-input-in-row-button"
              shape="circle"
              ghost
              icon="close"
              onClick={this.changeVisibleInputPercentage}
            />
            <Button
              className="save-input-in-row-button"
              shape="circle"
              ghost
              icon="save"
              onClick={() => {
                this.savePercentage(item);
              }}
            />
            <InputNumber
              defaultValue={item.percentage}
              min={0}
              max={100}
              style={{ scale: 0.7 }}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onPressEnter={() => {
                this.savePercentage(item);
              }}
              onChange={this.updatePercentageValue}
            />
          </>
        )}
      </>
    );
  }
}

// ***********************************************************************************************/
// ******************* COMPONENT TO SHOW DEPARTMENT NAME WHAT OBJECTIVE BELONGS*******************/
// ***********************************************************************************************/
class PopoverDepartment extends React.Component {
  render() {
    const { item } = this.props;

    return (
      <Popover
        placement="left"
        trigger="click"
        title={"Departamento/Unidad"}
        content={
          <Tag color={item.department ? "purple" : "red"}>
            {item.department ? item.department.name : "No Asignado"}
          </Tag>
        }
      >
        <Button
          className={
            item.department
              ? "date-in-list-button"
              : "date-in-list-button-empty"
          }
          shape="circle"
          ghost
          icon="layout"
        />
      </Popover>
    );
  }
}

// ***********************************************************************************************/
// ******************* COMPONENT TO SHOW DATES OR PERIODS INSIDE OF POPOVER **********************/
// ***********************************************************************************************/
class PopoverDates extends React.Component {
  render() {
    const { item } = this.props;

    return (
      <Popover
        placement="left"
        trigger="click"
        title={
          <>
            <span>
              {item.periods.length > 0
                ? "Periodo(s) de evaluación"
                : "Fechas inicio/finalización"}
            </span>
          </>
        }
        content={
          <>
            {item.periods.length > 0 && (
              <div>
                {item.periods.map((period) => {
                  return (
                    <div key={period.id}>
                      <strong>{period.name}</strong>
                      <br />
                      <Tag color="geekblue">
                        {moment(period.start_date)
                          .tz(TIMEZONE)
                          .format("DD/MM/YYYY")}
                      </Tag>
                      {` - `}
                      <Tag color="geekblue">
                        {moment(period.end_date)
                          .tz(TIMEZONE)
                          .format("DD/MM/YYYY")}
                      </Tag>
                    </div>
                  );
                })}
              </div>
            )}

            {item.start_date && item.end_date && (
              <div>
                <Tag color="geekblue">
                  {moment(item.start_date).tz(TIMEZONE).format("DD/MM/YYYY")}
                </Tag>
                {` - `}
                <Tag color="geekblue">
                  {moment(item.end_date).tz(TIMEZONE).format("DD/MM/YYYY")}
                </Tag>
              </div>
            )}

            {!item.start_date &&
              !item.end_date &&
              item.periods.length === 0 && (
                <Alert message="Periodos no asignados" type="info" />
              )}
          </>
        }
      >
        <Button
          className={
            item.periods.length > 0 || (item.start_date && item.end_date)
              ? "date-in-list-button"
              : "date-in-list-button-empty"
          }
          shape="circle"
          ghost
          icon="calendar"
        />
      </Popover>
    );
  }
}

// ***********************************************************************************************/
// ************************ COMPONENT TO SHOW TAGS INSIDE OF POPOVER *****************************/
// ***********************************************************************************************/
class PopoverTags extends React.Component {
  state = {
    selectedItems: [],
    defaultValue: [],
    displayTagSelector: false,
  };
  handleInputChange = (items) => {
    this.setState({ ...this.state, selectedItems: items, defaultValue: items });
  };

  handleShowTagSelector = () => {
    this.setState({
      ...this.state,
      displayTagSelector: !this.state.displayTagSelector,
    });
  };

  componentDidMount() {
    //update selected items list
    let selectedTags = this.props.item.tags.map((tag) => tag.id);
    this.setState({
      ...this.state,
      selectedItems: selectedTags,
      defaultValue: selectedTags,
    });
  }

  render() {
    let { item, handleCloseTag, handleAddTag, tagOpts, tagCount } = this.props;

    //Proccess request
    const handleSaveTags = async (objective) => {
      let tags = this.state.selectedItems;
      if (tags.length > 0) {
        this.setState({
          ...this.state,
          displayTagSelector: !this.state.displayTagSelector,
          selectedItems: [],
          defaultValue: [],
        });
        await handleAddTag(tags, objective);
      }
    };

    return (
      <Popover
        placement="left"
        trigger="click"
        title={
          <>
            <span>Etiquetas</span>
            <div style={{ float: "right", marginTop: "-5px" }}>
              {this.state.displayTagSelector && (
                <Button
                  style={{ scale: "0.8" }}
                  className="save-tag-in-popover-button"
                  shape="circle"
                  ghost
                  icon="save"
                  onClick={() => {
                    handleSaveTags(item.id);
                  }}
                />
              )}
              <Button
                style={{ scale: "0.8" }}
                className="save-tag-in-popover-button"
                type="link"
                onClick={this.handleShowTagSelector}
              >
                {this.state.displayTagSelector ? "Cancelar" : "Agregar"}
              </Button>
            </div>
          </>
        }
        content={
          <div style={{ minWidth: 150, maxWidth: 250 }}>
            {item.tags.map((tag) => (
              <React.Fragment key={tag.id}>
                <Tag
                  color="geekblue"
                  closable={true}
                  onClose={() => handleCloseTag(tag.id, item.id)}
                >
                  {tag.name}
                </Tag>
              </React.Fragment>
            ))}

            <Select
              mode="multiple"
              placeholder="Agregar una etiqueta"
              allowClear={false}
              mode="tags"
              onChange={this.handleInputChange}
              style={{
                width: "100%",
                maxWidth: "250px",
                display: this.state.displayTagSelector ? "inherit" : "none",
              }}
              optionLabelProp="title"
              defaultValue={this.state.selectedItems}
              value={this.state.defaultValue}
              //value={[]}
            >
              {tagOpts.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  title={<Text style={{ color: "#40a9ff" }}>{item.name}</Text>}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        }
      >
        <Button
          className={
            tagCount > 0 ? "tag-in-list-button" : "tag-in-list-button-empty"
          }
          shape="circle"
          ghost
          icon="tag"
        />
      </Popover>
    );
  }
}

// ***********************************************************************************************/
// ***************** COMPONENT TO SHOW OBJECTIVE DETAIL IN A MODAL *******************************/
// ***********************************************************************************************/
const ObjectiveShowDetailModal = Form.create({ name: "objective_modal" })(
  class extends React.Component {
    state = {
      visible: false,
      loading: false,
      showAddFollowUp: false,
      showAddDeal: false,
      showPeriods:
        this.props.item.start_date && this.props.item.end_date ? false : true,
      fileList: [],
      files: [],
    };

    showModal = () => {
      this.setState({ ...this.state, visible: !this.state.visible });
    };
    closeModal = async () => {
      this.setState({ ...this.state, loading: true });
      await this.props.loadObjectives();
      this.setState({
        ...this.state,
        visible: !this.state.visible,
        loading: false,
      });
    };

    cancelModal = async () => {
      this.setState({ ...this.state, loading: true });
      this.setState({
        ...this.state,
        visible: !this.state.visible,
        loading: false,
      });
    };

    handleSubmitName = () => {
      const { form } = this.props;
      form.validateFields(async (err, value) => {
        if (err) {
          return;
        }
        value = {
          name: value.name,
        };
        this.setState({ ...this.state, loading: true });
        let error = await this.props.updateObjective(this.props.item.id, value);
        this.setState({ ...this.state, loading: false });
      });
    };

    handleSubmitCode = () => {
      const { form } = this.props;
      form.validateFields(async (err, value) => {
        if (err) {
          return;
        }
        value = {
          code: value.code,
        };
        this.setState({ ...this.state, loading: true });
        let error = await this.props.updateObjective(this.props.item.id, value);
        this.setState({ ...this.state, loading: false });
      });
    };

    handleSubmitObjectiveSupervisor = async (value) => {
      this.setState({ ...this.state, loading: true });
      if (value) {
        value = { objective_supervisor: value };
      } else {
        value = { objective_supervisor: "none" };
      }
      //Not reloading list
      let error = await this.props.updateObjective(
        this.props.item.id,
        value,
        false
      );
      this.setState({ ...this.state, loading: false });
    };

    handleSubmitObjectiveResponsible = async (value) => {
      this.setState({ ...this.state, loading: true });
      if (value) {
        value = { objective_responsible: value };
      } else {
        value = { objective_responsible: "none" };
      }
      //Not reloading list
      let error = await this.props.updateObjective(
        this.props.item.id,
        value,
        false
      );
      this.setState({ ...this.state, loading: false });
    };

    handleSubmitParent = async (value) => {
      this.setState({ ...this.state, loading: true });
      if (value.length > 0) {
        value = { parent: value[value.length - 1] };
      } else {
        value = { parent: "none" };
      }
      //Not reloading list
      let error = await this.props.updateObjective(
        this.props.item.id,
        value,
        false
      );
      this.setState({ ...this.state, loading: false });
    };

    handleSubmitPeriod = async (value) => {
      this.setState({ ...this.state, loading: true });
      if (value) {
        value = { period: value };
      } else {
        value = { period: "none" };
      }
      //Not reloading list
      let error = await this.props.updateObjective(
        this.props.item.id,
        value,
        false
      );
      this.setState({ ...this.state, loading: false });
    };

    handleSubmitPeriodicity = async (value) => {
      this.setState({ ...this.state, loading: true });
      if (value) {
        value = { periodicity: value };
      } else {
        value = { periodicity: "none" };
      }
      //Not reloading list
      let error = await this.props.updateObjective(
        this.props.item.id,
        value,
        false
      );
      this.setState({ ...this.state, loading: false });
    };

    handleSubmitDates = async (value) => {
      this.setState({ ...this.state, loading: true });

      if (value.length > 0) {
        value = {
          dates: {
            start_date: moment(value[0]).startOf("day").tz(TIMEZONE).format(),
            end_date: moment(value[1]).endOf("day").tz(TIMEZONE).format(),
          },
        };
      } else {
        value = { dates: "none" };
      }
      //Not reloading list
      let error = await this.props.updateObjective(
        this.props.item.id,
        value,
        false
      );
      this.setState({ ...this.state, loading: false });
    };

    handleSubmitDueDate = async (value) => {
      this.setState({ ...this.state, loading: true });
      if (value) {
        value = { due_date: moment(value).tz(TIMEZONE).format() };
      } else {
        value = { due_date: "none" };
      }

      let error = await this.props.updateObjective(
        this.props.item.id,
        value,
        false
      );
      this.setState({ ...this.state, loading: false });
    };

    handleSubmitPercentage = async (e) => {
      let percentage = parseInt(e.target.value.replace("%", ""));
      if (!Object.is(percentage, NaN)) {
        if (percentage > 0 && percentage <= 100) {
          this.setState({ ...this.state, loading: true });
          let error = await this.props.updatePercentage(
            percentage,
            this.props.item
          );
          this.setState({ ...this.state, loading: false });
        }
      }
    };

    handleSubmitCheckbox = async (attr, value) => {
      value = {
        [attr]: value.target.checked,
      };
      this.setState({ ...this.state, loading: true });
      let error = await this.props.updateObjective(
        this.props.item.id,
        value,
        false
      );
      this.setState({ ...this.state, loading: false });
    };

    handleSubmitInstrument = async (value) => {
      this.setState({ ...this.state, loading: true });
      if (value) {
        value = { instrument: value };
      } else {
        value = { instrument: "none" };
      }
      //Not reloading list
      let error = await this.props.updateObjective(
        this.props.item.id,
        value,
        false
      );
      this.setState({ ...this.state, loading: false });
    };

    showAddFollowUpInput = () => {
      this.setState({
        ...this.state,
        showAddFollowUp: !this.state.showAddFollowUp,
      });
    };

    handleSubmitFollowup = () => {
      const { form } = this.props;
      form.validateFields(async (err, value) => {
        if (err) {
          return;
        }
        this.setState({ ...this.state, loading: true });
        let data = {
          followup: value.followup,
        };
        let error = await this.props.updateObjective(
          this.props.item.id,
          data,
          true, //reload
          this.state.fileList
        );
        this.setState({
          ...this.state,
          loading: false,
          showAddFollowUp: false,
          fileList: [],
        });
      });
    };

    showAddDealInput = () => {
      this.setState({
        ...this.state,
        showAddDeal: !this.state.showAddDeal,
      });
    };

    disabledTime = () => {
      const current = new moment();
      return {
        disabledHours: () => range(0, current.hour()),
        disabledMinutes: () => range(0, current.minute() + 1),
      };
    };

    disabledDate = (current = moment().tz(TIMEZONE)) => {
      return current && current < moment().tz(TIMEZONE).startOf("day");
    };

    handleSubmitDeal = () => {
      const { form } = this.props;
      form.validateFields(async (err, value) => {
        if (err) {
          return;
        }
        this.setState({ ...this.state, loading: true });
        value = {
          deal: {
            detail: value.deal_detail,
            date: value.deal_date,
            responsible: value.responsible,
          },
        };
        let error = await this.props.updateObjective(this.props.item.id, value);
        this.setState({
          ...this.state,
          loading: false,
          showAddDeal: false,
        });
      });
    };

    handleSubmitAchievement = async (e) => {
      let achievement = parseInt(e.target.value.replace("%", ""));
      if (!Object.is(achievement, NaN)) {
        if (achievement > 0 && achievement <= 100) {
          this.setState({ ...this.state, loading: true });
          let value = {
            achievement: achievement,
          };
          let error = await this.props.updateObjective(
            this.props.item.id,
            value,
            false
          );
          this.setState({ ...this.state, loading: false });
        }
      }
    };

    handleSubmitCompleteObjective = async () => {
      this.setState({ ...this.state, loading: true });
      let value = {
        achievement: 100,
      };
      let error = await this.props.updateObjective(this.props.item.id, value);
      this.setState({
        ...this.state,
        loading: false,
      });
    };

    inspectChildren = (children, item) => {
      var path = [];
      for (const obj of children) {
        //If they are not the same objective
        if (obj.id !== item.id) {
          if (obj.children.length > 0) {
            path = this.inspectChildren(obj.children, item, path);
            if (path.length > 0) {
              path.push(obj.id);
              break;
            }
          } else {
            //continue with the other sibling
            continue;
          }
        } else {
          return Array(obj.id);
        }
      }

      return path;
    };
    handleUsePeriods = (value) => {
      this.setState({ ...this.state, showPeriods: value });
    };
    disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < moment().tz(TIMEZONE).endOf("day");
    };

    onChange = (info) => {
      //this.setState({ ...this.state, loading: true });
      if (info.file.status === "uploading") {
        this.setState({ ...this.state, loading: true });
      }
      if (info.file.status === "done") {
        message.success(`¡Archivo subido exitosamente!`);
        this.setState({ ...this.state, loading: false });
      } else if (info.file.status === "error") {
        message.error(`¡ATENCIÓN: Ocurrio un error al subir el archivo!"`);
        this.setState({ ...this.state, loading: false });
      }
    };
    onRemove = (file) => {
      this.setState((state) => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
        };
      });
    };

    beforeUpload = (file) => {
      const isLt10M = file.size / 1024 / 1024 < 10;
      const isMaxFiles = this.state.fileList.length > 4;
      if (isMaxFiles) {
        message.error("¡ATENCIÓN: Máximo 5 archivos!");
        return !isMaxFiles;
      }

      if (isLt10M) {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
      } else {
        message.error("¡ATENCIÓN: El peso del archivo debe ser menor a 10MB!");
        return isLt10M;
      }
    };

    customRequest = (xhr) => {
      return;
    };

    componentDidMount() {
      this.setState({
        ...this.state,
        name: this.props.item.name,
      });
    }
    render() {
      const {
        item,
        form,
        objectiveOpts,
        periodOpts,
        instrumentOpts,
        usersOpts,
      } = this.props;
      const { getFieldDecorator } = form;
      const props = {
        name: "attachment",
        listType: "text",
        multiple: false,
        onRemove: this.onRemove,
        beforeUpload: this.beforeUpload,
        customRequest: this.customRequest,
        fileList: this.state.fileList,
        showUploadList: {
          showPreviewIcon: false,
          showDownloadIcon: false,
          showRemoveIcon: true,
        },
      };
      let parentPath = [];

      // if (!Object.is(item.parent, null)) {
      //   parentPath = this.inspectChildren(objectiveOpts, item);
      //   parentPath.reverse();
      // }

      let defaultOptionsDueDate =
        item.due_date !== null
          ? {
              initialValue: moment(item.due_date).tz(TIMEZONE),
            }
          : {};

      return (
        <>
          <span className="objective-name" onClick={this.showModal}>
            <Tag>{item.code}</Tag>
            {item.name}
          </span>
          <Modal
            visible={this.state.visible}
            //confirmLoading={loading}
            title={
              <Row>
                <Col span={23}>Detalle del objetivo</Col>
                {this.state.loading && (
                  <Col style={{ textAlign: "center" }} span={1}>
                    <Spin size="small" />
                  </Col>
                )}
              </Row>
            }
            okText="Guardar"
            cancelText="Cancelar"
            onOk={this.closeModal}
            maskClosable={false}
            keyboard={false}
            className="objective-modal"
            closable={false}
            onCancel={this.cancelModal}
            //cancelButtonProps={{ style: { display: "none" } }}
          >
            <Row type="flex" align="middle">
              <Col span={24}>
                <Form>
                  <Form.Item
                    className="modal-objective-detail-form-item"
                    label={"Código del objetivo"}
                  >
                    {getFieldDecorator("code", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor ingrese el código del objetivo",
                          whitespace: true,
                        },
                        {
                          max: 25,
                          message: "Código demasiado largo",
                        },
                      ],
                      initialValue: item.code,
                    })(<Input onPressEnter={this.handleSubmitCode} />)}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" align="middle">
              <Col span={24}>
                <Form>
                  <Form.Item
                    className="modal-objective-detail-form-item"
                    label={"Nombre del objetivo"}
                  >
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor ingrese el nombre del objetivo",
                          whitespace: true,
                        },
                        {
                          max: 255,
                          message: "Nombre demasiado largo",
                        },
                      ],
                      initialValue: item.name,
                    })(<Input onPressEnter={this.handleSubmitName} />)}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" align="middle">
              <Col span={24}>
                <Form>
                  <Form.Item
                    className="modal-objective-detail-form-item"
                    label={"Objetivo padre"}
                  >
                    {getFieldDecorator("parent", {
                      initialValue: item.parent,
                    })(
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Seleccione un objetivo padre"
                        optionFilterProp="children"
                        optionLabelProp="title"
                        allowClear={true}
                        filterOption={(input, option) => {
                          return (
                            option.props.title
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {objectiveOpts.map((objective) => {
                          return (
                            <Option
                              key={objective.id}
                              value={objective.id}
                              title={`${objective.code} - ${objective.name}`}
                            >
                              <Tag>{objective.code}</Tag>
                              {objective.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" align="middle">
              <Col span={24}>
                <Form>
                  <Form.Item
                    className="modal-objective-detail-form-item"
                    label={"Supervisor del objetivo"}
                  >
                    {getFieldDecorator(
                      "objective_supervisor",
                      item.supervisor
                        ? { initialValue: item.supervisor.id }
                        : {}
                    )(
                      <Select
                        showSearch
                        placeholder="Seleccione un supervisor"
                        allowClear={true}
                        onChange={this.handleSubmitObjectiveSupervisor}
                        optionLabelProp="title"
                        style={{ width: "100%" }}
                        filterOption={(input, option) => {
                          return (
                            option.props.title
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {usersOpts.map((user) => (
                          <Option
                            key={user.id}
                            value={user.id}
                            title={`${user.first_name} ${user.last_name}`}
                          >
                            <span>{`${user.first_name} ${user.last_name}`}</span>
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" align="middle">
              <Col span={24}>
                <Form>
                  <Form.Item
                    className="modal-objective-detail-form-item"
                    label={"Responsable del objetivo"}
                  >
                    {getFieldDecorator(
                      "objective_responsible",
                      item.responsible
                        ? { initialValue: item.responsible.id }
                        : {}
                    )(
                      <Select
                        showSearch
                        placeholder="Seleccione un colaborador"
                        allowClear={true}
                        onChange={this.handleSubmitObjectiveResponsible}
                        optionLabelProp="title"
                        style={{ width: "100%" }}
                        filterOption={(input, option) => {
                          return (
                            option.props.title
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {usersOpts.map((user) => (
                          <Option
                            key={user.id}
                            value={user.id}
                            title={`${user.first_name} ${user.last_name}`}
                          >
                            <span>{`${user.first_name} ${user.last_name}`}</span>
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" align="middle" gutter={16}>
              <Col span={7}>
                <Form>
                  <Form.Item
                    className="modal-objective-detail-form-item"
                    label={`Instrumento`}
                  >
                    {getFieldDecorator("instrument", {
                      initialValue: item.instrument
                        ? item.instrument
                        : undefined,
                    })(
                      <Select
                        placeholder="Seleccione un instrumento"
                        allowClear={true}
                        optionLabelProp="title"
                        style={{ width: "100%" }}
                        onChange={this.handleSubmitInstrument}
                      >
                        {instrumentOpts.map((instrument) => (
                          <Option
                            key={instrument.id}
                            value={instrument.id}
                            title={instrument.name}
                          >
                            <span>{instrument.name}</span>
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Form>
              </Col>
              <Col span={7}>
                <Form>
                  {this.state.showPeriods && (
                    <Form.Item
                      className="modal-objective-detail-form-item"
                      label={`Periodo de evaluación`}
                    >
                      {getFieldDecorator("period", {
                        initialValue:
                          item.periods.length > 0
                            ? item.periods.map((p) => p.id)
                            : undefined,
                      })(
                        <Select
                          placeholder="Seleccione un periodo"
                          allowClear={true}
                          optionLabelProp="title"
                          style={{ width: "100%" }}
                          onChange={this.handleSubmitPeriod}
                          mode={"multiple"}
                        >
                          {periodOpts.map((period) => (
                            <Option
                              key={period.id}
                              value={period.id}
                              title={period.name}
                            >
                              <Tag color="blue">
                                {moment(period.start_date)
                                  .tz(TIMEZONE)
                                  .format("DD/MM/YYYY")}
                              </Tag>{" "}
                              -{" "}
                              <Tag color="blue">
                                {moment(period.end_date)
                                  .tz(TIMEZONE)
                                  .format("DD/MM/YYYY")}
                              </Tag>
                              <span>{period.name}</span>
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  )}
                </Form>
              </Col>
              <Col span={5}>
                <Form>
                  {/* Selector de periodos de evaluación */}
                  <Form.Item
                    label="Periodicidad de la evaluación"
                    className="modal-objective-detail-form-item"
                  >
                    {getFieldDecorator("periodicity", {
                      rules: [
                        {
                          required: true,
                          message:
                            "Debe seleccionar la periodicidad de evaluación",
                        },
                      ],
                      initialValue: item.periodicity, //MONTHLY
                    })(
                      <Select
                        placeholder="Seleccione una periodicidad"
                        style={{ width: "100%" }}
                        onChange={this.handleSubmitPeriodicity}
                      >
                        {EVALUATION_PERIODICITY.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Form>
              </Col>
              <Col span={2}>
                <Form>
                  <Form.Item
                    className="modal-objective-detail-form-item"
                    label={"Porcentaje"}
                  >
                    {getFieldDecorator("percentage", {
                      initialValue: item.percentage,
                    })(
                      <InputNumber
                        min={1}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                        style={{ width: "100%" }}
                        step={1}
                        onPressEnter={this.handleSubmitPercentage}
                      />
                    )}
                  </Form.Item>
                </Form>
              </Col>
              <Col span={3}>
                <Form>
                  <Form.Item
                    label={"Fecha meta"}
                    className="modal-objective-detail-form-item"
                    style={{ textAlign: "left" }}
                  >
                    {getFieldDecorator(
                      "due_date",
                      defaultOptionsDueDate
                    )(
                      <DatePicker
                        onChange={this.handleSubmitDueDate}
                        format="DD/MM/YYYY"
                        placeholder="Fecha meta"
                      />
                    )}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>
        </>
      );
    }
  }
);

// ***********************************************************************************************/
// ************************ COMPONENT TO SHOW MODAL WITH FORM ************************************/
// ***********************************************************************************************/

class ObjectiveCreateModal extends React.Component {
  state = {
    visible: false,
    loading: false,
    selectedItems: [],
    showPeriods: true,
    is_macro_activity: false,
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().tz(TIMEZONE).endOf("day");
  };

  updateIsMacroActivity = () => {
    this.setState({
      ...this.state,
      is_macro_activity: !this.state.is_macro_activity,
    });
  };

  handleChange = (selectedItems) => {
    this.setState({ ...this.state, selectedItems: selectedItems });
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false, selectedItems: [] });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({ ...this.state, loading: true });
      let error = await this.props.save(values);
      if (!error) {
        form.resetFields();
        this.setState({
          loading: false,
          visible: false,
          showPeriods: true,
          selectedItems: [],
          is_macro_activity: false,
        });
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  };

  handleUsePeriods = (value) => {
    this.setState({ ...this.state, showPeriods: value });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div>
        <Button
          type="default"
          shape="round"
          size="large"
          icon="plus-circle"
          style={{ float: "right" }}
          onClick={this.showModal}
        >
          Agregar
        </Button>
        <PlanningCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          loading={this.state.loading}
          yearsAvailable={this.props.yearsAvailable}
          planning={this.props.planning}
          objectiveOpts={this.props.objectiveOpts}
          tagOpts={this.props.tagOpts}
          handleChange={this.handleChange}
          selectedItems={this.state.selectedItems}
          disabledDate={this.disabledDate}
          showPeriods={this.state.showPeriods}
          isMacroActivity={this.state.is_macro_activity}
          updateIsMacroActivity={this.updateIsMacroActivity}
          handleUsePeriods={this.handleUsePeriods}
          periodOpts={this.props.periodOpts}
          usersOpts={this.props.usersOpts}
        />
      </div>
    );
  }
}

const PlanningCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        loading,
        planning,
        objectiveOpts,
        tagOpts,
        selectedItems,
        handleChange,
        disabledDate,
        showPeriods,
        handleUsePeriods,
        periodOpts,
        isMacroActivity,
        updateIsMacroActivity,
        usersOpts,
      } = this.props;
      const filteredOptions = tagOpts.filter(
        (o) => !selectedItems.includes(o.id)
      );
      const { getFieldDecorator } = form;

      return (
        <Modal
          visible={visible}
          confirmLoading={loading}
          title="Agregar un nuevo objetivo"
          okText="Guardar"
          cancelText="Cancelar"
          onCancel={onCancel}
          onOk={onCreate}
          maskClosable={false}
          className="modal-create-objective"
        >
          <Form layout="vertical">
            <Form.Item label="Código">
              {getFieldDecorator("code", {
                rules: [
                  {
                    required: true,
                    message: "Debe ingresar el código del objetivo",
                    whitespace: true,
                  },
                  {
                    max: 25,
                    message: "Nombre demasiado largo",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Nombre del objetivo">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Debe ingresar el nombre del objetivo",
                    whitespace: true,
                  },
                  {
                    max: 255,
                    message: "Nombre demasiado largo",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            {/* Porcentaje del objetivo */}
            <Form.Item label="Porcentaje global">
              {getFieldDecorator("percentage", {
                initialValue: 1,
              })(
                <InputNumber
                  min={1}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                  style={{ width: "100%" }}
                  step={1}
                />
              )}
            </Form.Item>
            {/* Objetivo padre - si aplica */}
            <Form.Item label="Objetivo padre">
              {getFieldDecorator("parent")(
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Seleccione un objetivo padre"
                  optionFilterProp="children"
                  optionLabelProp="title"
                  allowClear={true}
                  filterOption={(input, option) => {
                    return (
                      option.props.title
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {objectiveOpts.map((objective) => {
                    return (
                      <Option
                        key={objective.id}
                        value={objective.id}
                        title={`${objective.code} - ${objective.name}`}
                      >
                        <Tag>{objective.code}</Tag>
                        {objective.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            {/* Selector del supervisor */}
            <Form.Item label={"Supervisor del objetivo"}>
              {getFieldDecorator("supervisor")(
                <Select
                  showSearch
                  placeholder="Seleccione un supervisor"
                  allowClear={true}
                  //onChange={this.handleSubmitObjectiveSupervisor}
                  optionLabelProp="title"
                  style={{ width: "100%" }}
                  filterOption={(input, option) => {
                    return (
                      option.props.title
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {usersOpts.map((user) => (
                    <Option
                      key={user.id}
                      value={user.id}
                      title={`${user.first_name} ${user.last_name}`}
                    >
                      <span>{`${user.first_name} ${user.last_name}`}</span>
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {/* Selector del responsable */}
            <Form.Item label={"Responsable del objetivo"}>
              {getFieldDecorator("responsible")(
                <Select
                  showSearch
                  placeholder="Seleccione un colaborador"
                  allowClear={true}
                  //onChange={this.handleSubmitObjectiveSupervisor}
                  optionLabelProp="title"
                  style={{ width: "100%" }}
                  filterOption={(input, option) => {
                    return (
                      option.props.title
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {usersOpts.map((user) => (
                    <Option
                      key={user.id}
                      value={user.id}
                      title={`${user.first_name} ${user.last_name}`}
                    >
                      <span>{`${user.first_name} ${user.last_name}`}</span>
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {/* Selector de etiquetas */}
            <Form.Item label="Etiquetas">
              {getFieldDecorator("tags", {
                valuePropName: "name",
                initialValue: selectedItems,
              })(
                <Select
                  mode="multiple"
                  placeholder="Seleccione una etiqueta"
                  allowClear={true}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  optionLabelProp="title"
                >
                  {filteredOptions.map((item) => (
                    <Option
                      key={item.id}
                      value={item.id}
                      title={
                        <Text style={{ color: "#40a9ff" }}>{item.name}</Text>
                      }
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {/* Bandera si es macro actividad */}
            <Form.Item>
              {getFieldDecorator("is_macro_activity", {
                valuePropName: "checked",
                initialValue: false,
              })(
                <Checkbox onChange={updateIsMacroActivity}>
                  ¿Requiere seguimiento?
                </Checkbox>
              )}
            </Form.Item>
            {showPeriods && isMacroActivity && (
              <Form.Item label={`Periodo de evaluación`}>
                {getFieldDecorator("period", {
                  initialValue: periodOpts.map((item) => item.id),
                  rules: [
                    {
                      required: true,
                      message: `Por favor seleccione al menos un periodo de evaluación`,
                    },
                  ],
                })(
                  <Select
                    placeholder="Seleccione un periodo"
                    allowClear={true}
                    style={{ width: "100%" }}
                    optionLabelProp="title"
                    mode={"multiple"}
                  >
                    {periodOpts.map((item) => (
                      <Option key={item.id} value={item.id} title={item.name}>
                        <Tag color="blue">
                          {moment(item.start_date)
                            .tz(TIMEZONE)
                            .format("DD/MM/YYYY")}
                        </Tag>{" "}
                        -{" "}
                        <Tag color="blue">
                          {moment(item.end_date)
                            .tz(TIMEZONE)
                            .format("DD/MM/YYYY")}
                        </Tag>
                        <span>{item.name}</span>
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            )}
            {/* Selector de periodos de evaluación */}
            {isMacroActivity && (
              <>
                <Form.Item label="Periodicidad de la evaluación">
                  {getFieldDecorator("periodicity", {
                    rules: [
                      {
                        required: true,
                        message:
                          "Debe seleccionar la periodicidad de evaluación",
                      },
                    ],
                    initialValue: 4, //MONTHLY
                  })(
                    <Select
                      placeholder="Seleccione una periodicidad"
                      style={{ width: "100%" }}
                    >
                      {EVALUATION_PERIODICITY.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  label={"Fecha meta"}
                  className="modal-objective-detail-form-item"
                  style={{ textAlign: "left" }}
                >
                  {getFieldDecorator("due_date")(
                    <DatePicker
                      onChange={this.handleSubmitDueDate}
                      format="DD/MM/YYYY"
                      placeholder="Fecha meta"
                      style={{ width: "100%" }}
                    />
                  )}
                </Form.Item>
              </>
            )}
            {/* UUID del planning asociado */}
            <Form.Item>
              {getFieldDecorator("planning", {
                initialValue: planning.id,
              })(<Input hidden />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
