import rest from "../rest";
import http from "../axios";
import { DEPARTMENT_ENDPOINT } from "../constants";

export default {
  ...rest(DEPARTMENT_ENDPOINT) //to get other endpoints
  //   login: async data => {
  //     try {
  //       const res = await http.post(DEPARTMENT_ENDPOINT, data);
  //       return res;
  //     } catch (e) {
  //       return {
  //         message: e.message,
  //         status: e.response.status
  //       };
  //     }
  //   }
};
