import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { notifications } from "../../_redux/actions";
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Button,
  Divider,
  Row,
  Col,
  Tag,
  Modal,
  Radio,
  Checkbox,
  Select,
  Alert,
} from "antd";
import {
  instrumentService,
  instrumentTypeService,
} from "../../_helpers/services/";
import { NOTIFICATION } from "../../_helpers/constants";

const data = [];
const EditableContext = React.createContext();
const { Option } = Select;
const InputGroup = Input.Group;

const View = (props) => {
  const [instrumentList, setInstrumentList] = useState([]);
  const [instrumentTypeList, setInstrumentTypeList] = useState([]);
  const [instrumentTypeOptions, setInstrumentTypeOptions] = useState([
    <Option key={0}>Selecciona un tipo de instrumento</Option>,
  ]);
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const { Title } = Typography;
  const EditableFormTable = Form.create()(EditableTable);

  const updateLoading = () => {
    setLoading(!loading);
  };

  async function saveInstrument(values) {
    let message;
    let error = false;
    let obj_keys = Object.keys(values);
    let checkbox_list = [];
    let input_list = [];

    obj_keys.forEach((key) => {
      if (key.indexOf("chkbx") !== -1) {
        checkbox_list.push(key);
      }

      if (key.indexOf("text") !== -1) {
        input_list.push(key);
      }
    });

    let instrument = {
      active: values.active,
      description: values.description ? values.description : "",
      type: values.instrument_type,
      name: values.name,
      checkboxes: checkbox_list.map((item) => {
        return { name: item, value: values[item] };
      }),
      inputs: input_list.map((item) => {
        return { name: item, value: values[item] };
      }),
    };

    let res = await instrumentService.insert(instrument);

    if (res.status === 201) {
      message = {
        title: "Nuevo instrumento de evaluación creado",
        body: `El instrumento de evaluación ${values.name} ha sido agregado exitosamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "ERROR AL GUARDAR",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      error = true;
    }
    props.showNotification(message);

    if (!error) {
      //clear table data source
      data.splice(0, data.length);
      loadInstruments();
      updateLoading();
    }
    return error;
  }

  async function updateInstrumentsFromModal(values) {
    let message;
    let error = false;
    let obj_keys = Object.keys(values);
    let checkbox_list = [];
    let input_list = [];

    obj_keys.forEach((key) => {
      if (key.indexOf("chkbx") !== -1) {
        checkbox_list.push(key);
      }

      if (key.indexOf("text") !== -1) {
        input_list.push(key);
      }
    });

    let instrument = {
      id: values.id,
      active: values.active,
      description: values.description ? values.description : "",
      type: values.instrument_type,
      name: values.name,
      checkboxes: checkbox_list.map((item) => {
        return { name: item, value: values[item] };
      }),
      inputs: input_list.map((item) => {
        return { name: item, value: values[item] };
      }),
    };

    console.log(instrument);
    let res = await instrumentService.update(instrument.id, instrument);

    if (res.status === 200) {
      message = {
        title: `Actualización exitosa`,
        body: `La información del instrumento de evaluación ${instrument.name} se actualizó correctamente`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
      error = true;
    }
    if (!error) {
      props.showNotification(message);
      //clear table data source
      data.splice(0, data.length);
      loadInstruments();
      updateLoading();
    }
    return error;
  }

  async function updateStatusInstrument(item) {
    let message;
    item.active = !item.status || false;

    let res = await instrumentService.update(item.id, item);

    if (res.status === 200) {
      message = {
        title: "Actualización exitosa ",
        body: `El instrumento de evaluación ${item.name} se ha ${
          item.status ? "Desactivado" : "Activado"
        } correctamente.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "ERROR",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    //clear table data source
    data.splice(0, data.length);
    loadInstruments();
    updateLoading();
  }

  async function updateInstruments(item, row) {
    let message;
    let data = item;
    data.name = row.name;
    data.description = row.description;
    data.active = item.status;

    console.log(data);

    let res = await instrumentService.update(data.id, data);

    if (res.status === 200) {
      message = {
        title: `Actualización exitosa`,
        body: `La información del instrumento de evaluación ${data.name} se actualizó correctamente`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
  }

  async function loadInstruments() {
    let message;
    let res = await instrumentService.fetch();
    if (res.status === 200) {
      // Process recived data
      setInstrumentList(res.data);

      data.splice(0, data.length);
      res.data.forEach((instrument, idx) => {
        data.push({
          key: idx + 1,
          id: instrument.id,
          name: instrument.name,
          description: instrument.description,
          type: instrument.type,
          status: instrument.active,
          fields: instrument.fields,
        });
      });

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} instrumentos de evaluación obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    props.showNotification(message);
    setLoading(false);
  }

  async function loadInstrumentTypes() {
    let message;
    let filters = [
      {
        criteria: "need_form",
        value: true,
      },
      {
        criteria: "active",
        value: true,
      },
    ];
    let res = await instrumentTypeService.search(filters);
    if (res.status === 200) {
      // Process recived data
      // Setup position options
      setInstrumentTypeList(res.data);

      let options = res.data.map((instrumentType) => {
        return (
          <Option key={instrumentType.id} value={instrumentType.id}>
            {instrumentType.name}
          </Option>
        );
      });

      setInstrumentTypeOptions(options);

      // Notification
      message = {
        title: "Carga de datos",
        body: `Listado de ${res.data.length} instrumentos de evaluación obtenido con éxito.`,
        type: NOTIFICATION.TYPES.SUCCESS,
      };
    } else {
      message = {
        title: "Error",
        body: res.message,
        type: NOTIFICATION.TYPES.ERROR,
      };
    }
    //props.showNotification(message);
  }

  useEffect(() => {
    if (instrumentList.length === 0 && firstLoad && props.isAuthenticated) {
      loadInstrumentTypes();
      loadInstruments();
      setFirstLoad(false);
    }
  }, [instrumentList]);

  return (
    <>
      <Row type="flex" justify="center">
        <Col span={16}>
          <Title type="secondary">Listado de instrumentos de evaluación</Title>
        </Col>
        <Col span={8}>
          <InstrumentCreateModal
            save={saveInstrument}
            instrumentOpts={instrumentTypeOptions}
          />
        </Col>
      </Row>
      <EditableFormTable
        loading={loading}
        update={updateInstruments}
        updateStatus={updateStatusInstrument}
        updateFromModal={updateInstrumentsFromModal}
        updateLoading={updateLoading}
        instrumentTypeList={instrumentTypeList}
        instrumentOpts={instrumentTypeOptions}
      />
    </>
  );
};

// ************************ COMPONENT TO SHOW MODAL WITH FORM ************************************
class InstrumentCreateModal extends React.Component {
  state = {
    visible: false,
    loading: false,
    checkbox: [],
    input: [],
  };
  id = 0; //Checkbox
  id2 = 0; //Input
  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ loading: false, visible: false, checkbox: [], input: [] });
    this.id = 0;
    this.id2 = 0;
  };

  addInput = () => {
    let currentInputs = this.state.input;
    currentInputs.push({ name: `text${this.id2++}`, id: 0, value: "" });

    this.setState({ ...this.state, input: currentInputs });
  };

  removeInput = (item) => {
    let updatedInputs = this.state.input.filter((input) => input.name !== item);
    this.setState({ ...this.state, input: updatedInputs });
  };

  addCheckbox = () => {
    let currentCheckboxes = this.state.checkbox;
    currentCheckboxes.push({ name: `chkbx${this.id++}`, id: 0, value: "" });

    this.setState({ ...this.state, checkbox: currentCheckboxes });
  };

  removeCheckbox = (item) => {
    let updatedCheckboxes = this.state.checkbox.filter(
      (checkbox) => checkbox.name !== item
    );
    this.setState({ ...this.state, checkbox: updatedCheckboxes });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({ ...this.state, loading: true });
      let error = await this.props.save(values);
      if (!error) {
        form.resetFields();
        this.setState({
          loading: false,
          visible: false,
          checkbox: [],
          input: [],
        });
        this.id = 0;
        this.id2 = 0;
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  updateIdCount = (id, id2) => {
    this.id = id;
    this.id2 = id2;
  };

  render() {
    return (
      <div>
        <Button
          type="primary"
          shape="round"
          size="large"
          icon="plus-circle"
          style={{ float: "right" }}
          onClick={this.showModal}
        >
          Agregar Instrumento
        </Button>
        <InstrumentCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          loading={this.state.loading}
          instrumentOpts={this.props.instrumentOpts}
          addCheckbox={this.addCheckbox}
          removeCheckbox={this.removeCheckbox}
          checkboxList={this.state.checkbox}
          addInput={this.addInput}
          removeInput={this.removeInput}
          inputList={this.state.input}
          updateIdCount={this.updateIdCount}
          current={null}
        />
      </div>
    );
  }
}

const InstrumentCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        loading,
        instrumentOpts,
        addCheckbox,
        removeCheckbox,
        checkboxList,
        addInput,
        removeInput,
        inputList,
        current,
        updateIdCount,
      } = this.props;
      const { getFieldDecorator } = form;

      if (inputList.length > 0 && checkboxList.length > 0) {
        updateIdCount(checkboxList.length, inputList.length);
      }
      //const checkboxes = this.state.checkbox;
      return (
        <Modal
          visible={visible}
          confirmLoading={loading}
          title="Agregar un nuevo instrumento de evaluación"
          okText="Guardar"
          cancelText="Cancelar"
          onCancel={onCancel}
          onOk={onCreate}
          maskClosable={false}
        >
          <Form layout="vertical">
            {/* UUID del instrumento */}
            <Form.Item label="">
              {getFieldDecorator("id", {
                initialValue: current ? current.id : "",
              })(<Input hidden />)}
            </Form.Item>

            {/* Nombre del instrumento de evaluación */}
            <Form.Item label="Nombre del instrumento de evaluación">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message:
                      "Debe ingresar el nombre del instrumento de evaluación",
                    whitespace: true,
                  },
                ],
                initialValue: current ? current.name : "",
              })(<Input />)}
            </Form.Item>

            {/* Descripción del instrumento de evaluación */}
            <Form.Item label="Descripción del instrumento de evaluación">
              {getFieldDecorator("description", {
                initialValue: current ? current.description : "",
              })(<Input type="textarea" />)}
            </Form.Item>

            <Form.Item label="Tipo">
              {getFieldDecorator("instrument_type", {
                rules: [
                  {
                    required: true,
                    message:
                      "Debe ingresar el tipo de instrumento de evaluación",
                  },
                ],
                initialValue: current ? current.type : "",
              })(<Select>{instrumentOpts}</Select>)}
            </Form.Item>

            {/* Estado del instrumento de evaluación */}
            <Form.Item className="collection-create-form_last-form-item">
              {getFieldDecorator("active", {
                initialValue: current ? current.status : true,
              })(
                <Radio.Group>
                  <Radio value={true}>Activo</Radio>
                  <Radio value={false}>Inactivo</Radio>
                </Radio.Group>
              )}
            </Form.Item>

            {/* Sección para agregar custom fields */}
            <Divider />
            <Button type="dashed" icon="plus" onClick={() => addInput()}>
              Agregar campo de texto
            </Button>
            <Button
              type="dashed"
              icon="plus"
              onClick={addCheckbox}
              style={{ float: "right" }}
            >
              Agregar checkbox
            </Button>
            <div className="mt-15">
              {checkboxList && checkboxList.length > 0 && (
                <Alert
                  showIcon
                  message="Agregue el nombre del(os) item(s)"
                  type="info"
                  className="mb-20"
                />
              )}

              {checkboxList &&
                checkboxList.map((checkbox, idx) => {
                  return (
                    <Form.Item key={idx} className="mb-10">
                      <InputGroup>
                        <Checkbox
                          key={checkbox.name}
                          name={checkbox.name}
                          checked
                          style={{
                            width: "5%",
                            marginTop: "4px",
                            scale: "1.3",
                          }}
                        />
                        <Button
                          type="danger"
                          icon="delete"
                          ghost
                          onClick={() => removeCheckbox(checkbox.name)}
                          style={{
                            width: "10%",
                            float: "right",
                            marginLeft: "5px",
                          }}
                        />
                        {getFieldDecorator(`${checkbox.name}`, {
                          rules: [
                            {
                              required: true,
                              message: "Debe ingresar el nombre del item",
                            },
                          ],
                          initialValue: checkbox.value,
                        })(
                          <Input
                            key={`${checkbox.id}`}
                            style={{ width: "82%", float: "right" }}
                          />
                        )}
                      </InputGroup>
                    </Form.Item>
                  );
                })}

              {/* Sección para agregar los input */}
              {inputList && inputList.length > 0 && (
                <Alert
                  showIcon
                  message="Agregue el nombre del(os) criterio(s)"
                  type="info"
                  className="mb-20"
                />
              )}
              {inputList &&
                inputList.map((input, idx) => {
                  return (
                    <Form.Item key={idx} className="mb-10">
                      <InputGroup>
                        {getFieldDecorator(`${input.name}`, {
                          rules: [
                            {
                              required: true,
                              message: "Debe ingresar el nombre del criterio",
                            },
                          ],
                          initialValue: input.value,
                        })(
                          <Input key={`${input.id}`} style={{ width: "88%" }} />
                        )}
                        <Button
                          type="danger"
                          icon="delete"
                          ghost
                          onClick={() => removeInput(input.name)}
                          style={{
                            width: "10%",
                            float: "right",
                          }}
                        />
                      </InputGroup>
                    </Form.Item>
                  );
                })}
            </div>
          </Form>
        </Modal>
      );
    }
  }
);

// ************************ COMPONENT TO RENDER TABLE ********************************************
class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data,
      editingKey: "",
      visible: false,
      loading: false,
      current: null,
      checkbox: [],
      input: [],
    };
    this.columns = [
      {
        title: "#",
        dataIndex: "key",
        width: "5%",
        align: "center",
        editable: false,
        sorter: (a, b) => a.key - b.key,
      },
      {
        title: "Instrumento",
        dataIndex: "name",
        editable: true,
        sorter: (a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          // names must be equal
          return 0;
        },
      },
      {
        title: "Descripción",
        dataIndex: "description",
        editable: true,
        sorter: (a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Tipo de instrumento",
        dataIndex: "type",
        editable: true,
        align: "center",
        width: "150px",
        render: (type) => {
          let instrument_type = this.props.instrumentTypeList.filter((item) => {
            return item.id === type;
          });
          return (
            <Tag color="geekblue">
              {instrument_type[0] ? instrument_type[0].name : "N/A"}
            </Tag>
          );
        },
        sorter: (a, b) => {
          if (a.type < b.type) {
            return -1;
          }
          if (a.type > b.type) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Estado",
        dataIndex: "status",
        editable: false,
        align: "center",
        width: "150px",
        render: (status) => (
          <Tag color={status ? "blue" : "magenta"}>
            {status ? "Activo" : "Inactivo"}
          </Tag>
        ),
        sorter: (a, b) => a.status - b.status,
      },
      {
        title: "Acciones",
        dataIndex: "operation",
        width: "225px",
        align: "right",

        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    type="primary"
                    icon="save"
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    Guardar
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="¿Seguro que deseas cancelar?"
                onConfirm={() => this.cancel(record.key)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  ghost
                  type="danger"
                  icon="close-circle"
                  style={{ marginRight: 8 }}
                >
                  Cancelar
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <>
              {/* <Button
                type="primary"
                icon="edit"
                ghost
                onClick={() => this.edit(record.key)}
                disabled={editingKey !== ""}
              >
                Editar
              </Button>
              <Divider type="vertical" /> */}
              <Popconfirm
                title={` ¿Seguro que deseas ${
                  record.status ? "desactivar" : "activar"
                } este instrumento de evaluación?`}
                onConfirm={() => this.updateStatus(record.key)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  type={record.status ? "danger" : "primary"}
                  icon={record.status ? "close" : "check"}
                  ghost
                  disabled={editingKey !== ""}
                >
                  {record.status ? "Desactivar" : "Activar"}
                </Button>
              </Popconfirm>
              <Divider type="vertical" />
              <Button
                type="primary"
                icon="file-search"
                ghost
                onClick={() => this.showModal(record)}
                disabled={editingKey !== ""}
              />
            </>
          );
        },
      },
    ];
  }

  //********************* MODAL TO UPDATE INSTRUMENT FIELDS *********************** */
  id = 0;
  id2 = 0;
  showModal = (record) => {
    let checkbox_list = [];
    let input_list = [];
    record.fields.forEach((field) => {
      if (field.name.indexOf("chkbx") !== -1) {
        checkbox_list.push({
          name: field.name,
          id: field.id,
          value: field.value,
        });
      }

      if (field.name.indexOf("text") !== -1) {
        input_list.push({ name: field.name, id: field.id, value: field.value });
      }

      this.setState({
        ...this.state,
        checkbox: checkbox_list,
        input: input_list,
      });
    });
    this.setState({ visible: true, current: record });
  };

  handleCancel = () => {
    this.setState({
      ...this.state,
      loading: false,
      visible: false,
      checkbox: [],
      input: [],
    });
    this.id = 0;
    this.id2 = 0;
  };

  addInput = () => {
    let currentInputs = this.state.input;
    currentInputs.push({ name: `text${this.id2++}`, id: 0, value: "" });

    this.setState({ ...this.state, input: currentInputs });
  };

  removeInput = (item) => {
    let updatedInputs = this.state.input.filter((input) => input.name !== item);
    this.setState({ ...this.state, input: updatedInputs });
  };

  addCheckbox = () => {
    let currentCheckboxes = this.state.checkbox;
    currentCheckboxes.push({ name: `chkbx${this.id++}`, id: 0, value: "" });

    this.setState({ ...this.state, checkbox: currentCheckboxes });
  };

  removeCheckbox = (item) => {
    let updatedCheckboxes = this.state.checkbox.filter(
      (checkbox) => checkbox.name !== item
    );
    this.setState({ ...this.state, checkbox: updatedCheckboxes });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({ ...this.state, loading: true });
      let error = await this.props.updateFromModal(values);
      if (!error) {
        form.resetFields();
        this.setState({
          ...this.state,
          loading: false,
          visible: false,
          checkbox: [],
          input: [],
        });
        this.id = 0;
        this.id2 = 0;
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };
  //********************* MODAL TO UPDATE INSTRUMENT FIELDS *********************** */

  isEditing = (record) => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  updateStatus = (key) => {
    const newData = [...this.state.data];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
      });
      this.setState({ editingKey: "" });

      //activate/deactivate register
      this.props.updateStatus(item);
    }
  };

  updateIdCount = (id, id2) => {
    this.id = id;
    this.id2 = id2;
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: "" });

        //update register
        this.props.update(item, row);
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "type" ? "select" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          options: this.props.instrumentOpts,
        }),
      };
    });

    return (
      <>
        <EditableContext.Provider value={this.props.form}>
          <Table
            components={components}
            bordered
            dataSource={this.state.data}
            loading={this.props.loading}
            columns={columns}
            rowClassName="editable-row"
            pagination={{
              onChange: this.cancel,
              showSizeChanger: true,
              locale: { items_per_page: "/ página" },
              pageSizeOptions: ["10", "25", "50", "100"],
            }}
          />
        </EditableContext.Provider>
        <InstrumentCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          loading={this.state.loading}
          instrumentOpts={this.props.instrumentOpts}
          addCheckbox={this.addCheckbox}
          removeCheckbox={this.removeCheckbox}
          checkboxList={this.state.checkbox}
          addInput={this.addInput}
          removeInput={this.removeInput}
          inputList={this.state.input}
          updateIdCount={this.updateIdCount}
          current={this.state.current}
        />
      </>
    );
  }
}

// ************************ COMPONENT TO MAKE EDITABLE CELLS *************************************
class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "select") {
      return (
        <Select style={{ minWidth: "200px" }}>{this.props.options}</Select>
      );
    } else return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {inputType === "text" &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                    whitespace: true,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}

            {inputType === "select" &&
              getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Por favor ingrese ${title}!`,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};
const mapDispatchToProps = {
  showNotification: notifications.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
